import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiBox } from "react-icons/fi";
import { Select } from "../../../-common";
import "./StudentBundleLine.scss";

const BundleItem = observer(({ selectProps, data, innerProps }) => {
  const bundle = data?.value;
  const { value: selectedBundle } = selectProps || {};
  const { id, media, name, productNames, price } = bundle || {};

  const [imageError, setImageError] = useState(false);
  const markImageInvalid = () => setImageError(true);

  const selected = selectedBundle?.id === id;

  const image = imageError ? (
    <div className="preview-image fallback">
      <FiBox color="var(--medium-gray)" size={40} />
    </div>
  ) : (
    <img className="preview-image" src={media} alt="Products in this bundle." onError={markImageInvalid} />
  );

  return (
    <div className={`bundle-item-wrapper${selected ? " selected" : ""}`} {...innerProps}>
      <div className="bundle-item-content">
        {image}
        <div className="info-container">
          <div className="bundle-item-title">{name}</div>
          <div className="bundle-item-info">{productNames}</div>
          <div style={{ flex: 1 }} />
          <div className="bundle-item-price">${(price / 100).toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
});

const makeSelectedBundleLabel = ({ value }) => {
  const { name, price } = value || {};
  return name + " - $" + (price / 100)?.toFixed(2);
};

const StudentBundleLine = ({ student, selectedBundle, bundles, selectBundleForStudent }) => {
  return (
    <div className="student-line-wrapper">
      <div className="student-name-label">{student?.firstName}: </div>
      <Select
        student={student}
        components={{ Option: BundleItem }}
        options={bundles}
        value={selectedBundle}
        onChange={selectBundleForStudent}
        placeholder="Select bundle..."
        formatOptionLabel={makeSelectedBundleLabel}
      />
    </div>
  );
};

export default StudentBundleLine;
