import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Input, Spinner, Checkbox } from "../../../-common";
import { FiDollarSign } from "react-icons/fi";
import "./EditRegistrationDetailsModal.scss";
import isEqual from "lodash.isequal";
import ClassesStore from "../../../../stores/ClassesStore";

function EditRegistrationDetailsModal({ close, currentClass }) {
  const [loading, setLoading] = useState(false);

  const classId = currentClass?.id || currentClass?.classId;

  const {
    capacity: existingCapacity,
    registrationCodeRequired: existingCode,
    registrationFee: existingRegFee,
    tuitionFee: existingTuitionFee
  } = currentClass;

  const existingParams = {
    capacity: String(existingCapacity),
    codeRequired: existingCode,
    registrationFee: existingRegFee ? existingRegFee / 100 : null,
    tuitionFee: existingTuitionFee ? existingTuitionFee / 100 : null
  };

  const [classDetails, setClassDetails] = useState(existingParams);
  const { capacity, registrationFee, tuitionFee, codeRequired } = classDetails || {};

  if (!classId) close();

  const updateClassDetailsField = (fieldName, { raw, checkbox } = {}) => val => {
    if (checkbox) {
      const [value] = val || [];
      setClassDetails(prev => ({ ...prev, [fieldName]: value }));
    } else {
      const value = raw ? val : val?.target?.value;
      setClassDetails(prev => ({ ...prev, [fieldName]: value }));
    }
  };
  const updateCapacity = updateClassDetailsField("capacity");
  const updateRegistrationFee = updateClassDetailsField("registrationFee");
  const updateTuitionFee = updateClassDetailsField("tuitionFee");
  const updateCodeRequired = updateClassDetailsField("codeRequired", { checkbox: true });

  const editRegistrationDetails = async () => {
    setLoading(true);
    const updatedRegistrationParams = {
      capacity: Number(capacity),
      registrationCodeRequired: codeRequired,
      registrationFee: registrationFee ? registrationFee * 100 : null,
      tuitionFee: tuitionFee ? tuitionFee * 100 : null
    };
    await ClassesStore.editClassForTeacher(currentClass?.userId, classId, updatedRegistrationParams);
    setLoading(false);
    close();
  };

  const submitDisabled = isEqual(existingParams, classDetails);
  const sendButtonContent = loading ? <Spinner /> : "Edit";

  return (
    <div className="edit-registration-details-modal">
      <div className="content">
        <div className="title">Edit Registration Details</div>

        <div className="section-container double">
          <div className="small">
            <div className="section-title">Max Students</div>
            <Input type="number" value={capacity} placeholder={6} min="1" onChange={updateCapacity} />
          </div>
          <div>
            <div className="section-title">&nbsp;</div>
            <div className="checkbox-wrapper">
              <Checkbox selected={codeRequired} onChange={updateCodeRequired} value={"Registration Code Required?"} />
            </div>
          </div>
        </div>
        <div className="section-container double">
          <div>
            <div className="section-title">Registration Fee</div>
            <Input
              value={registrationFee}
              onChange={updateRegistrationFee}
              icon={FiDollarSign}
              type={"number"}
              min={0}
              step={1}
            />
          </div>
          <div>
            <div className="section-title">Tuition Fee</div>
            <Input
              value={tuitionFee}
              onChange={updateTuitionFee}
              icon={FiDollarSign}
              type={"number"}
              min={0}
              step={1}
            />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            action={editRegistrationDetails}
            disabled={submitDisabled}
          >
            {sendButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditRegistrationDetailsModal);
