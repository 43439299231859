import React, { useState } from "react";
import { observer } from "mobx-react";
import { getDataURLFromFile } from "../../../../utils";
import BannersStore, { audienceOptions, statusOptions } from "../../../../stores/BannersStore";
import { Spinner, Input, Select, Button, ImageFileSelect } from "../../../-common";
import "./AddBannerModal.scss";

function AddBannerModal({ close }) {
  const [adding, setAdding] = useState(false);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const [url, setURL] = useState("");
  const updateURL = ({ target }) => setURL(target?.value);

  const [status, setStatus] = useState();
  const [audience, setAudience] = useState();

  const addEnabled = !!file && !!url && !!status && !!audience;
  const addBanner = async () => {
    if (addEnabled) {
      setAdding(true);
      const newBanner = await BannersStore.addBanner({ file, url, audience, status });
      setAdding(false);
      if (newBanner?.id) close();
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Add Banner";

  return (
    <div className="add-banner-modal">
      <div className="content">
        <div className="title">Add New Banner</div>
        <div className="main-content-wrapper">
          <div className="col">
            <div className="section-container">
              <div className="section-title">Artwork</div>
              <div className="artwork-select-container">
                <ImageFileSelect {...{ filePreviewURL, updateFile }} />
              </div>
            </div>
          </div>
          <div className="col right">
            <div className="section-container">
              <div className="section-title">URL</div>
              <Input
                placeholder="Where should tapping on this banner take the user?"
                value={url}
                onChange={updateURL}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Audience</div>
              <Select
                placeholder="Who should be able to see this banner?"
                options={audienceOptions}
                value={audience}
                onChange={setAudience}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Status</div>
              <Select
                placeholder="Should we show this banner immediately?"
                options={statusOptions}
                value={status}
                onChange={setStatus}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addBanner} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddBannerModal);
