import React from "react";
import { observer } from "mobx-react";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import MaterialsStore from "../../../../stores/MaterialsStore";
import { Button, Spinner, ProductRow } from "../../../-common";
import "./ProductsTab.scss";

// TODO: Figure out how to get the sortable stuff onto the ProductRow component in common...?

const ProductsTab = ({ curriculum, course, products, setProducts, sorting, openAddCourseProductModal }) => {
  const { id: curriculumId } = curriculum || {};
  const { id: courseId } = course || {};

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setProducts(items => {
        const oldIndex = items.findIndex(i => i?.id === active?.id);
        const newIndex = items.findIndex(i => i?.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const productRows = products?.map(product => (
    <ProductRow {...{ ...product, curriculumId, courseId, sorting, isCourseProduct: true }} key={product?.id} />
  ));

  const content = productRows?.length ? (
    <div className="table-wrapper products-table">
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext items={products} strategy={verticalListSortingStrategy}>
              {productRows}
            </SortableContext>
          </DndContext>
        </tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No products for this course yet!</div>
      <Button action={openAddCourseProductModal} type="primary">
        Add Products
      </Button>
    </div>
  );

  if (MaterialsStore?.loading) {
    return (
      <div className="products-tab products-tab-loading">
        <Spinner alt />
      </div>
    );
  }

  return <div className="products-tab">{content}</div>;
};

export default observer(ProductsTab);
