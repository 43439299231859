import { observer } from "mobx-react";
import AnalyticsStore from "src/stores/AnalyticsStore";
import { Spinner } from "src/components/-common";
import { BarChart, ChartWrapper, Collection, SummaryNumber } from "src/components/-common/-charts";
import sortByCurriculum from "../sortByCurriculum";

function Teachers() {
  const { loadingTeacher: loading, teacher } = AnalyticsStore || {};
  const { data, totalTeachersThisYear, changeSinceLastYear } = teacher || {};

  if (loading) {
    return (
      <Collection title="Teachers">
        <ChartWrapper width={12} height={2}>
          <div className="loading-container">
            <Spinner alt />
          </div>
        </ChartWrapper>
      </Collection>
    );
  }

  return (
    <Collection title="Teachers">
      <ChartWrapper width={8} height={2}>
        <div className="chart-title">Teacher Growth Over Time</div>
        <BarChart
          data={data}
          categorical
          colors={["var(--Sound-Beginnings)", "var(--Lets-Play-Music", "var(--Presto)", "var(--Online)"]}
          sortCells={sortByCurriculum}
        />
      </ChartWrapper>
      <ChartWrapper width={4} height={1}>
        <div className="chart-title">Total Teachers This Year</div>
        <SummaryNumber value={totalTeachersThisYear} />
      </ChartWrapper>
      <ChartWrapper width={4} height={1}>
        <div className="chart-title">Change Since Last Year</div>
        <SummaryNumber value={changeSinceLastYear} />
      </ChartWrapper>
    </Collection>
  );
}

export default observer(Teachers);
