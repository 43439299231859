import React, { useState } from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import { parseISO, set } from "date-fns";
import { weekdays } from "../../../../constants";
import { Button, Spinner, DatePicker, Select } from "../../../-common";
import ClassesStore from "../../../../stores/ClassesStore";
import "./EditScheduleDetailsModal.scss";

function EditScheduleDetailsModal({ close, currentClass }) {
  const [loading, setLoading] = useState(false);

  const classId = currentClass?.id || currentClass?.classId;

  const {
    dayOfWeek: existingDayOfWeek,
    rawSessionTime: existingSessionTime,
    shippingDate: existingShippingDate,
    startDate: existingStartDate,
    endDate: existingEndDate
  } = currentClass;
  const [stHour = 0, stMin = 0, stSec = 0] = existingSessionTime?.split(":") || [];

  const existingParams = {
    dayOfWeek: weekdays[existingDayOfWeek - 1],
    shippingDate: parseISO(existingShippingDate),
    sessionTime: set(new Date(), { hours: stHour, minutes: stMin, seconds: stSec }),
    startDate: parseISO(existingStartDate),
    endDate: parseISO(existingEndDate)
  };

  const [classDetails, setClassDetails] = useState(existingParams);
  const { dayOfWeek, sessionTime, shippingDate, startDate, endDate } = classDetails || {};

  if (!classId) close();

  const updateClassDetailsField = (fieldName, { raw, checkbox } = {}) => {
    return val => {
      if (checkbox) {
        const [value] = val || [];
        setClassDetails(prev => ({ ...prev, [fieldName]: value }));
      } else {
        const value = raw ? val : val?.target?.value;
        setClassDetails(prev => ({ ...prev, [fieldName]: value }));
      }
    };
  };

  const updateDayOfWeek = updateClassDetailsField("dayOfWeek", { raw: true });
  const updateStartDate = updateClassDetailsField("startDate", { raw: true });
  const updateEndDate = updateClassDetailsField("endDate", { raw: true });
  // const updateShippingDate = updateClassDetailsField("shippingDate", { raw: true });
  const updateSessionTime = updateClassDetailsField("sessionTime", { raw: true });

  const editScheduleDetails = async () => {
    setLoading(true);
    const formattedSessionTimeHour = sessionTime ? String(sessionTime?.getHours()).padStart(2, "0") : "00";
    const formattedSessionTimeMinute = sessionTime ? String(sessionTime?.getMinutes()).padStart(2, "0") : "00";

    const updatedScheduleParams = {
      dayOfWeek: Number(weekdays.indexOf(dayOfWeek) + 1),
      startDate: startDate?.toISOString()?.split("T")?.[0],
      endDate: endDate?.toISOString()?.split("T")?.[0],
      shippingDate: shippingDate?.toISOString()?.split("T")?.[0],
      sessionTime: `${formattedSessionTimeHour}:${formattedSessionTimeMinute}:00`
    };
    await ClassesStore.editClassForTeacher(currentClass?.userId, classId, updatedScheduleParams);
    setLoading(false);
    close();
  };

  const submitDisabled = isEqual(existingParams, classDetails);
  const sendButtonContent = loading ? <Spinner /> : "Edit";

  return (
    <div className="edit-schedule-details-modal">
      <div className="content">
        <div className="title">Edit Schedule Details</div>

        <div className="section-container">
          <div>
            <div className="section-title">Start Date</div>
            <DatePicker placeholder="Start Date" value={startDate} onChange={updateStartDate} />
          </div>
        </div>
        <div className="section-container">
          <div>
            <div className="section-title">End Date</div>
            <DatePicker placeholder="End Date" value={endDate} onChange={updateEndDate} />
          </div>
        </div>
        <div className="section-container double">
          <div>
            <div className="section-title">Day of Week</div>
            <Select options={weekdays} value={dayOfWeek} onChange={updateDayOfWeek} />
          </div>
          <div>
            <div className="section-title">Session Time</div>
            <DatePicker
              placeholder="Session Time"
              value={sessionTime}
              onChange={time => updateSessionTime(time)}
              showTimeSelect
              showTimeSelectOnly
              strictParsing
              timeIntervals={5}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </div>
        </div>
        {/* <div className="section-container">
          <div>
            <div className="section-title">Requested Material Shipping Date </div>
            <DatePicker
              placeholder="Requested Material Shipping Date"
              value={shippingDate}
              minDate={new Date()}
              onChange={updateShippingDate}
            />
          </div>
        </div> */}
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            action={editScheduleDetails}
            disabled={submitDisabled}
          >
            {sendButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditScheduleDetailsModal);
