import { makeAutoObservable, autorun } from "mobx";
import { ENV } from "../config";
import { request, getDurationFromAudioFile } from "../utils";
import uploadFile from "../services/FileUploadService";
import AuthStore from "./AuthStore";
import { showErrorToast, showSuccessToast } from "../services/ToastService";

class CurriculumStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) this.init();
      else this.clear();
    });
  }

  loading = false;

  rawCurricula = [];
  get curricula() {
    return this.rawCurricula
      ?.slice()
      ?.sort((a, b) => a?.order - b?.order)
      ?.map(curriculum => {
        const coursesForCurriculum = this.courses
          ?.filter(course => course?.curriculum?.id === curriculum?.id)
          ?.sort((a, b) => a?.order - b?.order);
        const albums = coursesForCurriculum
          ?.map(({ albums }) => albums)
          ?.flat()
          ?.filter(Boolean);
        const courses = this.coursesByCurriculumId[curriculum?.id];
        return { ...curriculum, courses, albums };
      });
  }

  get curriculaById() {
    return this.curricula?.reduce((acc, next) => {
      acc[next?.id] = next;
      return acc;
    }, {});
  }

  get curriculaByCourseId() {
    return this.curricula?.reduce((acc, next) => {
      for (let course of next?.courses) acc[course?.id] = next;
      return acc;
    }, {});
  }

  rawCourses = [];
  get courses() {
    return this.rawCourses.map(course => ({
      ...course,
      albums: course?.albums?.map(albumId => this.albumsById?.[albumId])?.sort((a, b) => a?.order - b?.order)
    }));
  }

  get coursesById() {
    return this.courses.reduce((acc, next) => {
      acc[next?.id] = next;
      return acc;
    }, {});
  }

  get coursesByCurriculumId() {
    const coursesGroupedByCurriculum = this.courses.reduce((acc, next) => {
      if (acc[next?.curriculum?.id]) acc[next.curriculum.id] = acc[next.curriculum.id].concat(next);
      else acc[next.curriculum.id] = [next];
      return acc;
    }, {});
    const curriculumCourseEntriesWithSortedCourses = Object.entries(coursesGroupedByCurriculum)?.map(
      ([curriculumId, courses]) => [curriculumId, courses?.sort((a, b) => a?.order - b?.order)]
    );
    return Object.fromEntries(curriculumCourseEntriesWithSortedCourses);
  }

  rawAlbums = [];
  loadingAlbumDetails = {};
  rawAlbumDetails = {};

  get albumDetails() {
    const betterAlbumDetailsEntries = Object.entries(this.rawAlbumDetails).map(([albumId, album]) => [
      albumId,
      {
        ...album,
        tracks: album?.tracks
          ?.map(t => ({
            ...t,
            url: `https://${ENV}-lpm-albums.b-cdn.net/${album.id}/${t.id}?m=${t.modified}`
          }))
          ?.sort((a, b) => a?.order - b?.order),
        playlists: album?.playlists?.slice()?.sort((a, b) => a?.sortOrder - b?.sortOrder)
      }
    ]);
    return Object.fromEntries(betterAlbumDetailsEntries);
  }

  get albums() {
    return this.rawAlbums?.map(album => {
      const albumDetails = this.albumDetails[album?.id] ? this.albumDetails[album.id] : {};
      const modified = album?.modified > albumDetails?.modified ? album?.modified : albumDetails?.modified;
      return {
        ...album,
        ...albumDetails,
        modified,
        artwork: `https://${ENV}-lpm-assets.b-cdn.net/artwork/${album.id}?m=${modified}`,
        playlists: albumDetails?.playlists
          ?.filter(playlist => playlist?.order != null)
          ?.sort((a, b) => a?.order - b?.order)
          ?.map(playlist => ({
            ...playlist,
            tracks: playlist?.tracks?.map(trackId => albumDetails?.tracks?.find(t => trackId === t?.id))
          }))
      };
    });
  }

  get albumsById() {
    return this.albums.reduce((acc, next) => {
      acc[next?.id] = next;
      return acc;
    }, {});
  }

  inFlightFetchCurriculaRequest;
  async fetchCurricula() {
    try {
      if (!this.inFlightFetchCurriculaRequest) this.inFlightFetchCurriculaRequest = request.get(`/curricula`);
      const curricula = await this.inFlightFetchCurriculaRequest;
      this.inFlightFetchCurriculaRequest = null;
      this.rawCurricula = curricula;
    } catch (err) {
      console.warn(err);
    }
  }

  inFlightFetchCoursesRequest;
  async fetchCourses() {
    try {
      if (!this.inFlightFetchCoursesRequest) this.inFlightFetchCoursesRequest = request.get(`/courses`);
      const courses = await this.inFlightFetchCoursesRequest;
      this.inFlightFetchCoursesRequest = null;
      this.rawCourses = courses;
    } catch (err) {
      console.warn(err);
    }
  }

  inFlightFetchAlbumListRequest;
  async fetchAlbumList() {
    try {
      if (!this.inFlightFetchAlbumListRequest) this.inFlightFetchAlbumListRequest = request.get(`/albums`);
      const albums = await this.inFlightFetchAlbumListRequest;
      this.inFlightFetchAlbumListRequest = null;
      this.rawAlbums = albums;
    } catch (err) {
      console.warn(err);
    }
  }

  async fetchAlbumDetails(albumId) {
    this.loadingAlbumDetails = { ...this.loadingAlbumDetails, [albumId]: true };
    try {
      const album = await request.get(`/albums/${albumId}`);
      this.rawAlbumDetails = { ...this.rawAlbumDetails, [albumId]: album };
      this.loadingAlbumDetails = { ...this.loadingAlbumDetails, [albumId]: false };
      return album;
    } catch (err) {
      console.warn(err);
      this.loadingAlbumDetails = { ...this.loadingAlbumDetails, [albumId]: false };
    }
  }

  async init() {
    this.loading = true;
    try {
      await Promise.all([this.fetchCurricula(), this.fetchCourses(), this.fetchAlbumList()]);
    } catch {}
    this.loading = false;
  }

  async addCurriculum(title) {
    try {
      const newCurriculum = await request.post(`/curricula`, { body: { title, order: this.curricula?.length + 1 } });
      this.rawCurricula = this.rawCurricula?.concat(newCurriculum);
      return newCurriculum;
    } catch (err) {
      console.warn(err);
    }
  }

  async deleteCurriculum(curriculumId) {
    try {
      await request.delete(`/curricula/${curriculumId}`);
      this.rawCurricula = this.rawCurricula?.filter(c => c?.id !== curriculumId);
    } catch (err) {
      console.warn(err);
    }
  }

  async addCourse({ curriculum: fullCurriculum, name }) {
    try {
      const { albums, courses, created, modified, entity, order: _, ...curriculum } = fullCurriculum || {};
      const currentCoursesInCurriculum = this.curriculaById?.[curriculum?.id]?.courses || [];
      const order = currentCoursesInCurriculum.length + 1;
      const newCourse = await request.post(`/courses`, { body: { curriculum, name, order } });
      await this.fetchCurricula();
      this.rawCourses = this.rawCourses?.concat(newCourse);
      return newCourse;
    } catch (err) {
      console.warn(err);
    }
  }

  async deleteCourse({ curriculumId, courseId }) {
    try {
      await request.delete(`/courses/${courseId}?curriculumId=${curriculumId}`);
      this.rawCourses = this.rawCourses?.filter(c => c?.id !== courseId);
      await this.fetchCurricula();
    } catch (err) {
      console.warn(err);
    }
  }

  async addAlbum({ curriculumId, courseId, title, artworkFile }) {
    try {
      const currentAlbumsInCourse = this.coursesById?.[courseId]?.albums || [];
      const order = currentAlbumsInCourse.length + 1;
      const newAlbum = await request.post(`/albums`, { body: { curriculumId, courseId, title, order } });
      this.rawAlbums = this.rawAlbums?.concat(newAlbum);
      await this.fetchAlbumDetails(newAlbum?.id);
      await this.updateAlbumArtwork(newAlbum?.id, artworkFile);
      await this.fetchCurricula();
      await this.fetchCourses();
      return newAlbum;
    } catch (err) {
      console.warn(err);
    }
  }

  async updateAlbumArtwork(albumId, file) {
    if (file) {
      try {
        await uploadFile(file, "artwork", `albumId=${albumId}`);
        const updatedAlbum = await request.put(`/albums/${albumId}`, { body: {} });
        this.rawAlbumDetails = { ...this.rawAlbumDetails, [albumId]: updatedAlbum };
        return true;
      } catch {
        return false;
      }
    }
  }

  async deleteAlbum({ curriculumId, courseId, albumId }) {
    try {
      await request.delete(`/albums/${albumId}?curriculumId=${curriculumId}&courseId=${courseId}`);
      await this.fetchCurricula();
      await this.fetchCourses();
      this.rawAlbums = this.rawAlbums?.filter(c => c?.id !== courseId);
      const { albumId: _, ...updatedRawAlbumDetails } = this.rawAlbumDetails;
      this.rawAlbumDetails = updatedRawAlbumDetails;
    } catch (err) {
      console.warn(err);
    }
  }

  async addTrack(albumId, { title, file, principalPurposes, secondaryPurposes }) {
    try {
      const duration = await getDurationFromAudioFile(file);
      const existingTracks = this.albumDetails?.[albumId]?.tracks;
      const order = Math.max(...[0, ...existingTracks?.map(t => t?.order)]) + 1;
      const trackInfo = { title, duration, order, principalPurposes, secondaryPurposes };
      const newTrack = await request.post(`/albums/${albumId}/tracks`, { body: trackInfo });
      await uploadFile(file, "track", `albumId=${albumId}&trackId=${newTrack?.id}`);
      this.rawAlbumDetails = {
        ...this.rawAlbumDetails,
        [albumId]: {
          ...this.rawAlbumDetails?.[albumId],
          tracks: this.rawAlbumDetails?.[albumId]?.tracks?.concat(newTrack)
        }
      };
      showSuccessToast("Track added successfully.");
      return newTrack;
    } catch (err) {
      console.warn(err);
      showErrorToast("Error adding track.");
    }
  }

  async updateTrack(albumId, trackId, updates) {
    try {
      const updatedTrack = await request.put(`/albums/${albumId}/tracks/${trackId}`, { body: updates });
      this.rawAlbumDetails = {
        ...this.rawAlbumDetails,
        [albumId]: {
          ...this.rawAlbumDetails?.[albumId],
          tracks: this.rawAlbumDetails?.[albumId]?.tracks?.map(track => {
            if (track?.id === trackId) return updatedTrack;
            return track;
          })
        }
      };
      return updatedTrack;
    } catch (err) {
      console.warn(err);
    }
  }

  async updateTrackRecording(albumId, trackId, file) {
    if (albumId && trackId && file) {
      try {
        const duration = await getDurationFromAudioFile(file);
        await uploadFile(file, "track", `albumId=${albumId}&trackId=${trackId}`);
        const updatedTrack = await request.put(`/albums/${albumId}/tracks/${trackId}`, { body: { duration } });
        this.rawAlbumDetails = {
          ...this.rawAlbumDetails,
          [albumId]: {
            ...this.rawAlbumDetails?.[albumId],
            tracks: this.rawAlbumDetails?.[albumId]?.tracks?.map(t => {
              return t?.id === trackId ? updatedTrack : t;
            })
          }
        };
        return true;
      } catch {
        return false;
      }
    }
  }

  async deleteTrack(albumId, trackId) {
    try {
      await request.delete(`/albums/${albumId}/tracks/${trackId}`);
      const orderOfTrackToDelete = this.rawAlbumDetails?.[albumId]?.tracks?.find(t => t?.id === trackId)?.order;
      this.rawAlbumDetails = {
        ...this.rawAlbumDetails,
        [albumId]: this.rawAlbumDetails?.[albumId]
          ? {
              ...this.rawAlbumDetails?.[albumId],
              tracks: this.rawAlbumDetails?.[albumId]?.tracks
                ?.map(t => {
                  if (t?.order > orderOfTrackToDelete) return { ...t, order: t?.order - 1 };
                  return t;
                })
                ?.filter(t => t?.id !== trackId)
            }
          : null
      };
      showSuccessToast("Track deleted successfully.");
    } catch (err) {
      console.warn(err);
      showErrorToast("Error deleting track.");
    }
  }

  clear() {
    this.loading = false;
    this.rawCurricula = [];
    this.rawCourses = [];
    this.rawAlbums = [];
    this.loadingAlbumDetails = {};
    this.rawAlbumDetails = {};
  }
}

export default new CurriculumStore();
