import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiBox } from "react-icons/fi";
import MaterialsStore from "../../../../stores/MaterialsStore";
import { Spinner, Button, MultiSelect } from "../../../-common";
import "./AddCourseProductModal.scss";

const getProductName = ({ value }) => value?.name;
const filterProducts = ({ value }, searchText) => {
  const textToMatch = searchText?.toLowerCase();
  return value?.name?.toLowerCase()?.includes(textToMatch);
};

const ProductItem = observer(({ data, innerProps }) => {
  const { value: product } = data || {};
  const { name, longDescription, price, media } = product || {};

  const [imageError, setImageError] = useState(false);
  const markImageInvalid = () => setImageError(true);

  const image = imageError ? (
    <div className="preview-image fallback">
      <FiBox color="var(--medium-gray)" size={40} />
    </div>
  ) : (
    <img className="preview-image" src={media} alt="What this product looks like." onError={markImageInvalid} />
  );

  return (
    <div className="product-item-wrapper" {...innerProps}>
      <div className="product-item-content">
        {image}
        <div className="info-container">
          <div className="product-item-title">{name}</div>
          <div className="product-item-info">{longDescription}</div>
          <div style={{ flex: 1 }} />
          <div className="product-item-price">${(price / 100).toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
});

function AddCourseProductModal({ courseId, close }) {
  const currentCourseProducts = MaterialsStore?.allProductsByCourseId?.[courseId] || [];
  const currentCourseProductIds = currentCourseProducts?.map(p => p?.id)?.filter(Boolean);
  const products = MaterialsStore?.allProducts?.filter(p => {
    return !currentCourseProductIds?.includes(p?.id);
  });

  const [adding, setAdding] = useState(false);

  const [productList, setProductList] = useState([]);

  const addEnabled = !!productList?.length;
  const addCourseProducts = async () => {
    if (addEnabled) {
      setAdding(true);
      await MaterialsStore.addCourseProducts(courseId, productList);
      setAdding(false);
      close();
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Add Products";

  return (
    <div className="add-course-product-modal">
      <div className="content">
        <div className="title">Add Products to Course</div>
        <div className="main-content-wrapper">
          <div className="section-container">
            <div className="section-title">Products</div>
            <MultiSelect
              placeholder="Select Product"
              options={products}
              value={productList}
              onChange={setProductList}
              components={{ Option: ProductItem }}
              formatOptionLabel={getProductName}
              filterOption={filterProducts}
            />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addCourseProducts} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddCourseProductModal);
