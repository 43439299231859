import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation, navigate } from "@reach/router";
import { ALBUM_ARTWORK_SIZE, ALBUM_ARTWORK_SPACING } from "../../../../constants";
import useQueryParams from "../../../../hooks/useQueryParams";
import useModal from "../../../../hooks/useModal";
import { Select } from "../../../-common";
import DropStudentsModalBody from "../../../-common/DropStudentsModal";
import RegistrationsStore from "../../../../stores/RegistrationsStore";
import "./RegistrationsTab.scss";
import PeopleStore from "../../../../stores/PeopleStore";

const filterOptions = ["Current Enrollments", "Past Enrollments", "Upcoming Enrollments", "All Enrollments"];

const RegistrationRow = observer(({ type, userId, connectionId, class: classItem }) => {
  const { id, classId: fallbackClassId, name, sessionTime, period, course, teacher, stats, capacity } = classItem || {};
  const classId = id || fallbackClassId;
  const { albums } = course || {};
  const { studentsEnrolled, parentsEnrolled } = stats || {};
  const { firstName, lastName } = teacher || {};
  const teacherName = `${firstName} ${lastName}`;

  const studentId = type === "student" ? userId : connectionId;
  const student = PeopleStore?.peopleById?.[studentId];
  useEffect(() => {
    PeopleStore?.fetchPersonById(studentId, true);
  }, [studentId]);

  const parentId = type === "student" ? connectionId : userId;
  const parent = { id: parentId };
  const materials = (PeopleStore?.materialsByParentId?.[parentId] || [])?.filter(m => m?.classId === classId);
  useEffect(() => {
    PeopleStore?.fetchMaterialsForParent(parentId);
  }, [parentId]);

  const students = [student];
  const { Modal: DropStudentsModal, openModal: openDropStudentsModal } = useModal(
    DropStudentsModalBody,
    { teacher, class: classItem, parent, students, materials },
    { portalElementId: "drop-students-modal" }
  );

  const dropEnabled = !PeopleStore?.loadingMaterialsForParents?.[parentId] && !PeopleStore?.loadingPeople?.[studentId];
  const openDropModal = () => dropEnabled && openDropStudentsModal();

  const goToClassDetails = () => navigate(`/people/${teacher?.id}/classes/${classId}`);

  let artwork;
  if (albums?.length > 1) {
    const size = ALBUM_ARTWORK_SIZE - (albums?.length - 1) * ALBUM_ARTWORK_SPACING;
    const albumCoverItems = albums?.map(({ title, artwork }, i) => (
      <img
        className="album-artwork"
        src={artwork}
        style={{ height: size, width: size, top: ALBUM_ARTWORK_SPACING * i, left: ALBUM_ARTWORK_SPACING * i }}
        alt={`Artwork for ${title} album`}
        key={title}
      />
    ));
    artwork = <div className="multi-album-artwork-container">{albumCoverItems}</div>;
  } else if (albums?.[0]) {
    const { title, artwork: albumArtwork } = albums[0];
    artwork = <img src={albumArtwork} className="album-artwork" alt={`Artwork for ${title} album`} />;
  }

  const seatsAvailable = capacity - studentsEnrolled;
  const seatsAvailableDetails = `(${studentsEnrolled} / ${capacity} students, ${parentsEnrolled} parents)`;

  return (
    <tr>
      <td>
        <div className="cell-contents artwork">{artwork}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div>{name}</div>
          <div>with {teacherName}</div>
          <div>({period})</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div>{sessionTime}</div>
          <div className="seats-available">{seatsAvailable} seats available</div>
          <div className="seats-available-details">{seatsAvailableDetails}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={goToClassDetails}>
            View Details
          </div>
          <div className={`action${dropEnabled ? "" : " disabled"}`} onClick={openDropModal}>
            Drop Student
          </div>
        </div>
      </td>
      <DropStudentsModal />
    </tr>
  );
});

const RegistrationsTab = ({ user }) => {
  const location = useLocation();

  const allRegistrations = RegistrationsStore?.registrationsByUserId?.[user?.id] || [];

  const { show } = useQueryParams();
  const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions[0]);
  const updateFilter = filterOption => {
    setSelectedFilterOption(filterOption);
    const tabOnlySearchParamsString = location.search?.split("&")?.[0];
    navigate(location.pathname + tabOnlySearchParamsString + "&show=" + encodeURIComponent(filterOption));
  };
  useEffect(() => {
    if (show && show !== selectedFilterOption) setSelectedFilterOption(show);
  }, [show, selectedFilterOption]);

  const registrations = allRegistrations?.filter(c => {
    if (selectedFilterOption === "Current Enrollments") return c?.current;
    if (selectedFilterOption === "Past Enrollments") return c?.past;
    if (selectedFilterOption === "Upcoming Enrollments") return c?.future;
    if (selectedFilterOption === "All Enrollments") return true;
    return false;
  });

  const registrationRows = registrations?.map((registration, i) => (
    <RegistrationRow {...registration} connections={user?.connections} key={i} />
  ));
  const registrationsTableContent = registrations?.length ? (
    <div className="table-wrapper registrations-table">
      <table>
        <thead>
          <tr>
            <th>Course</th>
            <th>Info</th>
            <th>Details</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{registrationRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no enrollments)</div>
    </div>
  );

  return (
    <div className="registrations-tab">
      <div className="registrations-filter-row">
        <div className="registrations-filter-label">Show:</div>
        <div className="registrations-filter-select">
          <Select options={filterOptions} value={selectedFilterOption} onChange={updateFilter} />
        </div>
      </div>
      {registrationsTableContent}
    </div>
  );
};

export default observer(RegistrationsTab);
