import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiType, FiBox } from "react-icons/fi";
import { getDataURLFromFile } from "../../../../utils";
import MaterialsStore from "../../../../stores/MaterialsStore";
import { Spinner, Input, Button, ImageFileSelect, MultiSelect } from "../../../-common";
import "./AddBundleModal.scss";

const getProductName = ({ value }) => value?.name;

const ProductItem = observer(({ data, innerProps }) => {
  const { value: product } = data || {};
  const { name, longDescription, price, media } = product || {};

  const [imageError, setImageError] = useState(false);
  const markImageInvalid = () => setImageError(true);

  const image = imageError ? (
    <div className="preview-image fallback">
      <FiBox color="var(--medium-gray)" size={40} />
    </div>
  ) : (
    <img className="preview-image" src={media} alt="What this product looks like." onError={markImageInvalid} />
  );

  return (
    <div className="product-item-wrapper" {...innerProps}>
      <div className="product-item-content">
        {image}
        <div className="info-container">
          <div className="product-item-title">{name}</div>
          <div className="product-item-info">{longDescription}</div>
          <div style={{ flex: 1 }} />
          <div className="product-item-price">${(price / 100).toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
});

function AddBundleModal({ courseId, close }) {
  const products = MaterialsStore?.productsByCourseId?.[courseId];

  const [adding, setAdding] = useState(false);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const [name, setName] = useState();
  const updateName = ({ target }) => setName(target?.value);

  const [productList, setProductList] = useState([]);
  const updateProductList = products => setProductList(products?.map(p => p?.id));
  const selectedProducts = productList?.map(pid => products?.find(p => p?.id === pid))?.filter(Boolean);

  const addEnabled = !!name;
  const addBundle = async () => {
    if (addEnabled) {
      setAdding(true);
      await MaterialsStore.addBundle(courseId, { file, name, productList });
      setAdding(false);
      close();
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Add Bundle";

  return (
    <div className="edit-bundle-modal">
      <div className="content">
        <div className="title">Add Bundle</div>
        <div className="main-content-wrapper">
          <div className="col">
            <div className="section-container">
              <div className="section-title">Image</div>
              <div className="artwork-select-container">
                <ImageFileSelect {...{ filePreviewURL, updateFile }} />
              </div>
            </div>
          </div>
          <div className="col right">
            <div className="section-container">
              <div className="section-title">Name</div>
              <Input icon={FiType} placeholder="Bundle Name" value={name} onChange={updateName} />
            </div>
            <div className="section-container">
              <div className="section-title">Products</div>
              <MultiSelect
                placeholder="Select Products"
                options={products}
                value={selectedProducts}
                onChange={updateProductList}
                components={{ Option: ProductItem }}
                formatOptionLabel={getProductName}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addBundle} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddBundleModal);
