import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { FiBox, FiMoreVertical } from "react-icons/fi";
import useModal from "../../../hooks/useModal";
import BannersStore from "../../../stores/BannersStore";
import { Button, Spinner } from "../../-common";
import AddBannerModalBody from "./AddBannerModal";
import EditBannerModalBody from "./EditBannerModal";
import "./BannersTab.scss";

const BannerRow = ({ id, media, url, audience, status, sorting }) => {
  const { Modal: EditBannerModal, openModal: openEditBannerModal } = useModal(
    EditBannerModalBody,
    { banner: { id, media, url, audience, status } },
    { portalElementId: "edit-banner-modal" }
  );

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled: !sorting });
  const sortStyles = { transform: CSS.Transform.toString(transform), transition };

  const [mediaError, setMediaError] = useState(false);
  const markMediaError = () => setMediaError(true);

  const toggleBannerActive = async () => {
    await BannersStore.updateBanner(
      id,
      { status: status === "Active" ? "Inactive" : "Active" },
      { optimisticUI: true }
    );
  };
  const deleteBanner = async () => await BannersStore.deleteBanner(id);

  const mediaComponent = mediaError ? (
    <div className="media placeholder">
      <FiBox size={36} />
    </div>
  ) : (
    <img className="media" src={media} alt="Banner media" onError={markMediaError} />
  );

  const statusActionLabel = status === "Active" ? "Switch to Inactive" : "Switch to Active";

  const sortingContentStyles = sorting ? { opacity: 0.2 } : {};

  const actions = sorting ? (
    <div {...attributes} {...listeners} className="drag-handle">
      <FiMoreVertical size={36} color="var(--orange)" />
    </div>
  ) : (
    <>
      <div className="action" onClick={openEditBannerModal}>
        Edit Details
      </div>
      <div className="action" onClick={toggleBannerActive}>
        {statusActionLabel}
      </div>
      <div className="action" onClick={deleteBanner}>
        Delete Banner
      </div>
    </>
  );

  return (
    <tr className="banner-row" ref={setNodeRef} style={sortStyles}>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {mediaComponent}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          <a className="url" href={url} rel="noreferrer" target="_blank">
            {url}
          </a>
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {audience}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {status}
        </div>
      </td>
      <td>
        <div className="cell-contents actions">{actions}</div>
      </td>
      <EditBannerModal />
    </tr>
  );
};

const BannersTab = () => {
  const { loading, banners: currentBanners, activeBanners, inactiveBanners, totalBanners } = BannersStore || {};

  const [banners, setBanners] = useState(currentBanners || []);
  useEffect(() => {
    setBanners(currentBanners);
  }, [currentBanners]);

  const [sorting, setSorting] = useState(false);

  const sortBanners = () => setSorting(true);
  const cancelBannerSort = () => {
    setBanners(currentBanners);
    setSorting(false);
  };
  const saveBannerSort = async () => {
    const bannerUpdates = banners?.map((b, i) => ({ ...b, order: i }));
    await BannersStore.updateBannerSort(bannerUpdates);
    setSorting(false);
  };

  const { Modal: AddBannerModal, openModal: openAddBannerModal } = useModal(
    AddBannerModalBody,
    {},
    { portalElementId: "add-banner-modal" }
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setBanners(items => {
        const oldIndex = items.findIndex(i => i?.id === active?.id);
        const newIndex = items.findIndex(i => i?.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  if (loading) {
    return (
      <div className="banners-tab banners-loading">
        <Spinner alt />
      </div>
    );
  }

  const actions = sorting ? (
    <>
      <div className="action-row">
        <div className="action" onClick={saveBannerSort}>
          <span>Save Sort Order</span>
        </div>
      </div>
      <div className="action-row">
        <div className="action" onClick={cancelBannerSort}>
          <span>Cancel</span>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="action-row">
        <div className="action" onClick={openAddBannerModal}>
          <span>Add Banner</span>
        </div>
      </div>
      <div className="action-row">
        <div className="action" onClick={sortBanners}>
          <span>Sort Banners</span>
        </div>
      </div>
    </>
  );

  const lastColumnLabel = sorting ? "Drag to Sort" : "Actions";
  const bannerRows = banners?.map(b => <BannerRow {...b} sorting={sorting} key={b?.id} />);
  const bannersTableContent = banners?.length ? (
    <div className="table-wrapper banners-table">
      <table>
        <thead>
          <tr>
            <th>Media</th>
            <th>URL</th>
            <th>Audience</th>
            <th>Status</th>
            <th>{lastColumnLabel}</th>
          </tr>
        </thead>
        <tbody>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext items={banners} strategy={verticalListSortingStrategy}>
              {bannerRows}
            </SortableContext>
          </DndContext>
        </tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No banners yet!</div>
      <Button action={openAddBannerModal} type="primary">
        Add Banner
      </Button>
    </div>
  );

  return (
    <div className="banners-tab">
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Active</div>
            <div className="info-value">{activeBanners}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Inactive</div>
            <div className="info-value">{inactiveBanners}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Total</div>
            <div className="info-value">{totalBanners}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          {actions}
        </div>
      </div>
      <div className="header">Banners</div>
      {bannersTableContent}
      <AddBannerModal />
    </div>
  );
};

export default observer(BannersTab);
