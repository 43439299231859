import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { components as RSComponents } from "react-select";
import { FiUser } from "react-icons/fi";
import PeopleStore from "../../../stores/PeopleStore";
import "./ParentSearchInput.scss";

const styles = {
  container: provided => ({
    ...provided,
    width: "100%"
  }),
  control: provided => ({
    ...provided,
    border: "none",
    boxShadow: "var(--shadow)",
    borderRadius: 0
  }),
  placeholder: provided => ({
    ...provided,
    color: "var(--medium-gray)",
    opacity: 0.5
  }),
  loadingIndicator: provided => ({
    ...provided,
    color: "var(--dark-primary)"
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--dark-primary)"
        : state.isFocused
        ? "var(--super-light-gray)"
        : "var(--white)",
      ...(state.isSelected ? { fontWeight: "700" } : {}),
      cursor: "pointer",
      ":active": {
        ...provided[":active"],
        backgroundColor: state.isSelected ? "var(--dark-primary)" : "var(--dark-primary-transparent)"
      }
    };
  }
};

const getNoOptionsMessage = ({ inputValue }) => {
  if (!inputValue) return "Type something to search";
  else return "No results found... try altering your search a bit.";
};

const getOptionsForSearch = async inputValue => {
  if (!inputValue) return [];
  return await PeopleStore?.searchParents(inputValue);
};

const getOptionValue = o => o;

const getOptionLabel = o => `${o?.firstName} ${o?.lastName}`;

const ResultOption = props => {
  const { firstName, lastName, email: emails } = props?.value || {};
  const email = emails?.[0]?.email || "";

  return (
    <RSComponents.Option {...props}>
      <div className="teacher-search-result-wrapper">
        <div className="teacher-search-result-content">
          <div className="profile-image">
            <FiUser color="var(--medium-gray)" size={28} />
          </div>
          <div className="info-container">
            <div className="search-result-name">
              {firstName} {lastName}
            </div>
            <div className="search-result-email">{email}</div>
          </div>
        </div>
      </div>
    </RSComponents.Option>
  );
};

const ParentSearchInput = props => {
  const [search, setSearch] = useState("");

  return (
    <AsyncSelect
      {...props}
      styles={styles}
      placeholder="Search People"
      inputValue={search}
      value={props?.value}
      onInputChange={setSearch}
      onChange={props?.onChange}
      isMulti={false}
      noOptionsMessage={getNoOptionsMessage}
      loadOptions={getOptionsForSearch}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      components={{ Option: ResultOption }}
    />
  );
};

export default ParentSearchInput;
