import React, { useState } from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import PlaylistsStore from "../../../../stores/PlaylistsStore";
import { Spinner, Button, MultiSelect } from "../../../-common";
import "./EditPlaylistTracksModal.scss";

const getTrackLabel = ({ value }) => value?.order + " - " + value?.title;

function EditPlaylistTracksModal({ album, playlist, close }) {
  const { id: albumId, tracks: availableTracks } = album || {};
  const { id: playlistId, tracks: currentFullTracks, order } = playlist || {};
  const currentTracks = currentFullTracks?.map(t => t?.id);

  const [adding, setAdding] = useState(false);

  const [tracks, setTracks] = useState(currentTracks);
  const updateTracks = tracks => setTracks(tracks?.map(t => t?.id));
  const selectedTracks = tracks?.map(tid => availableTracks?.find(t => t?.id === tid))?.filter(Boolean);

  const fieldsValid = tracks?.length > 0;
  const somethingChanged = !isEqual(tracks, currentTracks);
  const addEnabled = fieldsValid && somethingChanged;
  const addPlaylist = async () => {
    if (addEnabled) {
      setAdding(true);
      await PlaylistsStore.updatePlaylist(albumId, playlistId, { tracks, order });
      setAdding(false);
      close();
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Update Playlist";

  return (
    <div className="edit-playlist-tracks-modal">
      <div className="content">
        <div className="title">Add / Remove Tracks</div>
        <div className="main-content-wrapper">
          <div className="section-container">
            <div className="section-title">Tracks</div>
            <MultiSelect
              placeholder="Select Tracks"
              options={availableTracks}
              value={selectedTracks}
              onChange={updateTracks}
              formatOptionLabel={getTrackLabel}
            />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addPlaylist} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditPlaylistTracksModal);
