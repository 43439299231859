export const ENV = "prod";
export const API_HOST = "https://api.admin.letsplaymusicsite.com/v2";
export const API_NAME = "prod-admin-api";
export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };

export const COGNITO_CONFIG = {
  region: "us-east-1",
  userPoolId: "us-east-1_uu3VkL6Qc",
  userPoolWebClientId: "4mkam7qvulk93sodq8f51evsnf",
  authenticationFlowType: "USER_PASSWORD_AUTH"
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyAxCea_rKZqaGvuw8LhwPWXLKKIdRNtfEU";