import { makeAutoObservable } from "mobx";
import { uniqueArray } from "../utils";

const STORAGE_KEY_PREFIX = "lpm:state:";
const LOCAL_STATE_PREFIX = STORAGE_KEY_PREFIX + "local";

class AppStateStore {
  constructor() {
    makeAutoObservable(this);
    this.initialize();
  }

  localState = {};
  pendingPersonForDetailsScreen = null;

  initialize() {
    try {
      const localStateString = localStorage.getItem(LOCAL_STATE_PREFIX);
      this.localState = JSON.parse(localStateString);
    } catch {
      this.localState = {};
    }
  }

  addCollapsedAnalyticsCollection(collectionTitle) {
    this.localState = {
      ...this.localState,
      collapsedAnalyticsCollections: uniqueArray(
        (this.localState?.collapsedAnalyticsCollections || [])?.concat(collectionTitle)
      )
    };
    localStorage.setItem(LOCAL_STATE_PREFIX, JSON.stringify(this.localState));
  }

  removeCollapsedAnalyticsCollection(collectionTitle) {
    this.localState = {
      ...this.localState,
      collapsedAnalyticsCollections: uniqueArray(
        (this.localState?.collapsedAnalyticsCollections || [])?.filter(ct => ct !== collectionTitle)
      )
    };
    localStorage.setItem(LOCAL_STATE_PREFIX, JSON.stringify(this.localState));
  }

  setPendingPersonForDetailsScreen({ firstName, lastName, userId }) {
    this.pendingPersonForDetailsScreen = { firstName, lastName, userId };
  }

  clearPendingPersonForDetailsScreen() {
    this.pendingPersonForDetailsScreen = null;
  }
}

export default new AppStateStore();
