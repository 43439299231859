import { makeAutoObservable } from "mobx";
import { parsePhoneNumber } from "libphonenumber-js";
import { downloadURLContents, request } from "../utils";
import PeopleStore from "./PeopleStore";
import CurriculumStore from "./CurriculumStore";
import { showErrorToast, showSuccessToast } from "../services/ToastService";

const studentCSVHeaderRow = [
  "Registration Date",
  "Student Name",
  "Student Birthday",
  "Parent Name",
  "Email Address",
  "Phone Number"
];

const sanitizeCSVField = field => field?.replace(/"/, '""')?.replace(/\\n/, " ");

class ClassesStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = false;
  loadingStudentsForClasses = {};
  loadingDroppedStudentsForClasses = {};

  rawStudentsForClasses = {};
  rawDroppedStudentsForClasses = {};

  get studentsForClasses() {
    return { ...this.rawStudentsForClasses };
  }

  get droppedStudentsForClasses() {
    return { ...this.rawDroppedStudentsForClasses };
  }

  get classesById() {
    return PeopleStore?.people
      ?.map(({ classes }) => classes)
      ?.flat()
      ?.reduce((acc, next) => {
        const { classId, courseId } = next || {};
        if (classId) {
          acc[classId] = {
            ...next,
            students: this.studentsForClasses?.[classId],
            course: CurriculumStore?.coursesById?.[courseId]
          };
        }

        return acc;
      }, {});
  }

  get classesByTeacherId() {
    const currentDateString = new Date().toISOString().slice(0, 10);
    const teacherClassEntries = PeopleStore?.people?.map(({ id, classes }) => [
      id,
      classes
        ?.map(c => ({
          ...c,
          course: CurriculumStore?.coursesById?.[c?.courseId],
          current: !!c?.isActive,
          past: !c?.isActive && c?.endDate <= currentDateString,
          future: !c?.isActive && c?.startDate >= currentDateString
        }))
        ?.sort((a, b) => (a?.startDate > b?.startDate ? -1 : 1))
    ]);
    return Object.fromEntries(teacherClassEntries);
  }

  async fetchStudentsForClassById(classId) {
    try {
      if (!this.loadingStudentsForClasses[classId]) {
        this.loadingStudentsForClasses[classId] = true;
        const classStudents = await request.get(`/classes/${classId}/students`);
        this.rawStudentsForClasses = { ...this.rawStudentsForClasses, [classId]: classStudents };
        this.loadingStudentsForClasses[classId] = false;
        return classStudents;
      }
    } catch (err) {
      this.loadingStudentsForClasses[classId] = false;
      console.warn(err);
    }
  }

  async fetchDroppedStudentsForClassById(classId) {
    try {
      if (!this.loadingDroppedStudentsForClasses[classId]) {
        this.loadingDroppedStudentsForClasses[classId] = true;
        const droppedStudents = await request.get(`/classes/${classId}/dropped`);
        this.rawDroppedStudentsForClasses = { ...this.rawDroppedStudentsForClasses, [classId]: droppedStudents };
        console.log("rawDroppedStudentsForClasses", this.rawDroppedStudentsForClasses);
        this.loadingDroppedStudentsForClasses[classId] = false;
        return droppedStudents;
      }
    } catch (err) {
      this.loadingDroppedStudentsForClasses[classId] = false;
      console.warn(err);
    }
  }

  async editClassForTeacher(teacherId, classId, updateParams) {
    try {
      const updatedClass = await request.put(`/classes/${classId}?teacherId=${teacherId}`, { body: updateParams });
      PeopleStore.updateClassForTeacher({ teacherId, classId, updatedClass });
      return updatedClass;
    } catch (err) {
      console.warn(err);
    }
  }

  async cleanUpOrderItemsForClass(teacherId, classId) {
    try {
      const updatedClass = await request.get(`/classes/${classId}/clean?teacherId=${teacherId}`);
      this.rawClasses = this.rawClasses?.map(rc => (rc?.id === classId || rc?.classId === classId ? updatedClass : rc));
      showSuccessToast("Order items for class have been cleaned up.");
      return updatedClass;
    } catch (err) {
      console.warn(err);
      showErrorToast("Error cleaning order items for class.");
    }
  }

  async deleteClassForTeacher(teacherId, classId) {
    const currentClasses = this.classesByTeacherId?.[teacherId].slice();
    try {
      await request.delete(`/classes/${classId}?teacherId=${teacherId}`);
      this.rawClasses = this.rawClasses.filter(c => c?.id !== classId && c?.classId !== classId);
      showSuccessToast("Class deleted successfully.");
      return true;
    } catch (err) {
      this.rawClasses = currentClasses;
      console.warn(err);
      showErrorToast("Error deleting class.");
      return false;
    }
  }

  async downloadRosterForClass(classId) {
    try {
      const className = this.classesById?.[classId]?.name;
      const students = this.rawStudentsForClasses[classId];
      const studentRows = students
        ?.sort((a, b) => {
          const aLastName = a?.lastName || a?.parent?.lastName;
          const bLastName = b?.lastName || b?.parent?.lastName;
          if (aLastName === bLastName) return a?.firstName < b?.firstName ? -1 : 1;
          else return aLastName < bLastName ? -1 : 1;
        })
        ?.map(({ firstName, lastName, birthday, parent, registrationDate }) => {
          let phone;
          if (parent && parent?.phone && parsePhoneNumber(parent?.phone, "US").isValid()) {
            phone = parsePhoneNumber(parent.phone, "US").format("NATIONAL");
          }
          return {
            "Registration Date": registrationDate?.slice(0, 10),
            "Student Name": `${firstName} ${lastName || parent?.lastName}`,
            "Student Birthday": birthday?.slice(0, 10),
            "Parent Name": `${parent?.firstName} ${parent?.lastName}`,
            "Email Address": parent?.email,
            "Phone Number": phone
          };
        });
      const rosterRows = studentRows
        ?.map(row => '"' + studentCSVHeaderRow?.map(header => sanitizeCSVField(row?.[header]))?.join('","') + '"')
        ?.join("\n");
      const rosterCSV = '"' + studentCSVHeaderRow?.join('","') + '"\n' + rosterRows;
      const href = URL.createObjectURL(new Blob([rosterCSV]));
      downloadURLContents(href, className || "roster", ".csv");
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  async downloadFullCurrentRosterForTeacher(teacherLPMId) {
    try {
      const rosterCSV = await request.get(`/classes/roster?teacherId=${teacherLPMId}`, { responseType: "blob" });
      const href = URL.createObjectURL(new Blob([rosterCSV]));
      downloadURLContents(href, "Full Roster", ".csv");
    } catch (err) {
      console.warn(err);
    }
  }

  clear() {
    this.loading = false;
    this.loadingStudentsForClasses = {};
    this.rawStudentsForClasses = [];
    this.rawDroppedStudentsForClasses = [];
  }
}

export default new ClassesStore();
