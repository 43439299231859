import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiX, FiPlus } from "react-icons/fi";
import { Input, Select } from "../../../../../../-common";
import MaterialsStore from "../../../../../../../stores/MaterialsStore";
import "./EditScreen.scss";

const getTempId = materials => {
  const tempNumbers = materials?.filter(m => m?.id?.match(/^temp-/))?.map(m => Number(m?.id?.replace("temp-", "")));
  const maxTempNumber = tempNumbers?.length ? Math.max(...tempNumbers) : 0;
  return `temp-${maxTempNumber + 1}`;
};
const getDefaultMaterial = materials => ({ id: getTempId(materials), quantity: 1 });

const getMaterialProductGroups = materials => {
  const materialsByProductId = materials?.reduce((acc, next) => {
    acc[next?.productId || next?.id] = (acc?.[next?.productId || next?.id] || []).concat(next);
    return acc;
  }, {});
  return Object.entries(materialsByProductId)
    ?.map(([productId, items]) => ({
      isTemp: items?.[0]?.id?.match(/^temp-/),
      product: MaterialsStore?.productsById?.[productId],
      quantity: items?.length,
      items,
      totalShipped: items?.filter(i => i?.status === "processed")?.length,
      totalReturned: items?.filter(i => i?.status === "returned")?.length,
      totalPendingReturn: items?.filter(i => i?.status === "pending return")?.length
    }))
    ?.sort((a, b) => (a?.product?.name < b?.product?.name ? -1 : 1));
};

const getProductGroupLabel = ({ value }) => value?.name;

const MaterialRow = props => {
  const { isTemp, product, quantity, productOptions, totalShipped, i } = props || {};
  const { updateProductName, updateQuantity, removeMaterials } = props || {};
  const [quantityLabel, setQuantityLabel] = useState(quantity);
  const updateQuantityLabel = ({ target }) => setQuantityLabel(target.value);
  const syncQuantity = () => {
    const newQuantity = Number(quantityLabel);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      updateQuantity(i)(newQuantity);
      setQuantityLabel(newQuantity);
    } else {
      setQuantityLabel(quantity);
    }
  };

  const nameLabel = isTemp ? (
    <Select
      placeholder="Select Product"
      value={product}
      options={productOptions}
      onChange={updateProductName(i)}
      formatOptionLabel={getProductGroupLabel}
    />
  ) : (
    product?.name
  );

  return (
    <div className="material-row" key={product?.id || i}>
      <div className="material-name-col">
        <div className="material-name">{nameLabel}</div>
        <div className="material-extra-info">
          {totalShipped || "(none"} shipped so far{totalShipped ? "" : ")"}
        </div>
      </div>
      <div className="material-quantity">
        <Input
          placeholder="#"
          value={quantityLabel}
          onChange={updateQuantityLabel}
          onBlur={syncQuantity}
          className="quantity-input"
        />
      </div>
      <div className="action-button">
        <FiX size={24} color={"var(--medium-gray)"} onClick={removeMaterials(i)} />
      </div>
    </div>
  );
};

const MaterialsList = props => {
  const { materialProductGroups, updateQuantity, updateProductName, removeMaterials, productOptions } = props;
  const materialActionProps = { productOptions, updateProductName, updateQuantity, removeMaterials };
  const materialsList = materialProductGroups?.map((mpg, i) => (
    <MaterialRow {...{ ...mpg, ...materialActionProps, i }} key={mpg?.product?.id || i} />
  ));

  return <div>{materialsList}</div>;
};

const EditScreen = observer(({ materials, setMaterials, courseId }) => {
  const materialProductGroups = getMaterialProductGroups(materials);
  const orderId = materialProductGroups?.[0]?.items?.[0]?.orderId;

  const goToInfusionsoftOrder = () => {
    if (orderId) {
      window.open(`https://playmusic.infusionsoft.com/Job/manageJob.jsp?view=edit&ID=${orderId}`, "_blank");
    }
  };

  const addMaterialToList = () => setMaterials(materials?.concat(getDefaultMaterial(materials)));

  const updateProductName = i => value => {
    const materialIdsToUpdate = materialProductGroups?.[i]?.items?.map(m => m?.id);
    const updatedMaterials = materials?.map(m => {
      if (materialIdsToUpdate?.includes(m?.id)) return { ...m, productId: value.id };
      return m;
    });
    setMaterials(updatedMaterials);
  };

  const updateQuantity = i => newQuantity => {
    const materialsToUpdate = materialProductGroups?.[i]?.items;
    if (newQuantity > materialsToUpdate?.length) {
      const totalToAdd = newQuantity - materialsToUpdate?.length;
      let updatedMaterials = materials;
      for (let i = 0; i < totalToAdd; i++) {
        updatedMaterials = updatedMaterials?.concat({ ...materialsToUpdate?.[0], id: getTempId(updatedMaterials) });
      }
      setMaterials(updatedMaterials);
    }

    if (newQuantity < materialsToUpdate?.length) {
      const totalToSubtract = materialsToUpdate?.length - newQuantity;
      let updatedMaterials = materials;
      for (let i = 0; i < totalToSubtract; i++) {
        updatedMaterials = updatedMaterials?.filter(m => m?.id !== materialsToUpdate?.[i]?.id);
      }
      setMaterials(updatedMaterials);
    }
  };

  const removeMaterials = i => () => {
    const materialIdsToRemove = materialProductGroups?.[i]?.items?.map(m => m?.id);
    const updatedMaterials = materials?.filter(m => !materialIdsToRemove?.includes(m?.id));
    setMaterials(updatedMaterials);
  };

  const infusionsoftOrderLink = orderId ? (
    <div className="go-to-infusionsoft" onClick={goToInfusionsoftOrder}>
      View InfusionSoft Order
    </div>
  ) : null;

  const productsAlreadySelected = [...new Set(materials?.map(m => m?.productId))];
  const productOptions = MaterialsStore?.allProductsByCourseId?.[courseId].filter(
    p => !productsAlreadySelected.includes(p?.id)
  );

  const showAddMaterials = materialProductGroups?.length < MaterialsStore?.allProductsByCourseId[courseId]?.length;
  const addMaterialComponent = showAddMaterials ? (
    <div className="add-material-row">
      <div className="add-material" onClick={addMaterialToList}>
        <FiPlus />
        <span>Add Materials</span>
      </div>
    </div>
  ) : (
    <div className="section-info center">
      <div className="max-message">All materials for course already selected. Please alter quantity instead.</div>
    </div>
  );

  return (
    <div className="content edit-screen">
      <div className="title">
        <span>Edit Materials</span>
        {infusionsoftOrderLink}
      </div>
      <div className="section-container">
        <MaterialsList
          {...{ materialProductGroups, updateQuantity, updateProductName, removeMaterials, productOptions }}
        />
        {addMaterialComponent}
      </div>
    </div>
  );
});

export default EditScreen;
