import React from "react";
import { observer } from "mobx-react";
import "./ChartWrapper.scss";

function ChartWrapper({ width, height, children }) {
  width = Math.min(12, Math.max(3, width));
  height = Math.min(4, Math.max(1, height));

  return <div className={`chart-wrapper x${width} y${height}`}>{children}</div>;
}

export default observer(ChartWrapper);
