import { makeAutoObservable } from "mobx";
import { request } from "../utils";

class FilesStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingFilesForTeacher = {};
  filesByTeacherId = {};
  alreadyFetchedFilesForTeacherByTeacherId = {};

  async fetchFilesForTeacher(teacherLPMId) {
    if (this.alreadyFetchedFilesForTeacherByTeacherId[teacherLPMId]) return this.filesByTeacherId[teacherLPMId];

    try {
      this.loadingFilesForTeacher = { ...this.loadingFilesForTeacher, [teacherLPMId]: true };
      const teacherFiles = await request.get(`/users/${teacherLPMId}/files`);
      this.filesByTeacherId = { ...this.filesByTeacherId, [teacherLPMId]: teacherFiles };
      this.loadingFilesForTeacher = { ...this.loadingFilesForTeacher, [teacherLPMId]: false };
      this.alreadyFetchedFilesForTeacherByTeacherId = {
        ...this.alreadyFetchedFilesForTeacherByTeacherId,
        [teacherLPMId]: true
      };
      return teacherFiles;
    } catch (err) {
      console.warn(err);
      this.loadingFilesForTeacher = { ...this.loadingFilesForTeacher, [teacherLPMId]: false };
    }
  }

  async uploadFileForTeacher({ teacherLPMId, file, type }) {
    if (teacherLPMId && type) {
      try {
        const { newTeacherFile, uploadURL } = await request.post(`/users/${teacherLPMId}/files`, {
          body: { filename: file ? file.name : null, type }
        });

        if (uploadURL) {
          const uploadResponse = await fetch(uploadURL, {
            method: "PUT",
            body: file,
            headers: { "Content-Type": file.type, "x-amz-acl": "public-read" }
          });
          if (!uploadResponse.ok) {
            await request.delete(`/users/me/files/${newTeacherFile?.id}`);
            throw new Error("Upload failed.");
          }
        }

        if (newTeacherFile) {
          this.filesByTeacherId = {
            ...this.filesByTeacherId,
            [teacherLPMId]: (this.filesByTeacherId?.[teacherLPMId] || [])?.concat(newTeacherFile)
          };
        }
        return true;
      } catch (err) {
        console.warn("Error uploading new teacher file:", err);
      }
    }
  }

  async deleteFileForTeacher({ teacherLPMId, teacherFileId }) {
    try {
      await request.delete(`/users/${teacherLPMId}/files/${teacherFileId}`);
      this.filesByTeacherId = {
        ...this.filesByTeacherId,
        [teacherLPMId]: (this.filesByTeacherId?.[teacherLPMId] || [])?.filter(tf => tf?.id !== teacherFileId)
      };
    } catch (err) {
      console.warn("Error deleting teacher file:", err);
    }
  }

  clear() {
    this.loadingFilesForTeacher = {};
    this.filesByTeacherId = {};
  }
}

export default new FilesStore();
