import React, { useState } from "react";
import { observer } from "mobx-react";
import { addMonths, eachMonthOfInterval, format, startOfMonth, subMonths } from "date-fns";
import AnalyticsStore from "src/stores/AnalyticsStore";
import { Button, Select, Spinner } from "src/components/-common";
import "./ReportTab.scss";

const now = new Date();
const monthOptions = eachMonthOfInterval({ start: subMonths(now, 12), end: addMonths(now, 12) });
const formatMonthOption = ({ value }) => format(value, "MMMM yyyy");

const REPORT_OPTIONS = ["Classes Starting", "Upcoming Shipments"];

function ReportTab() {
  const [report, setReport] = useState();
  const [month, setMonth] = useState(startOfMonth(new Date()));
  const [downloading, setDownloading] = useState(false);

  const downloadReport = async () => {
    if (!!report && !!month) {
      setDownloading(true);
      if (report === "Classes Starting") {
        await AnalyticsStore.downloadClassesStartingInMonth(month);
      } else if (report === "Upcoming Shipments") {
        await AnalyticsStore.downloadShipmentsStartingInMonth(month);
      }
      setDownloading(false);
    }
  };

  const downloadEnabled = (!!report && !!month) || downloading;
  const downloadClassesButtonContent = downloading ? <Spinner /> : "DOWNLOAD REPORT";

  return (
    <div className="analytics-report-tab">
      <div className="report-date-select">
        <div className="report-select-title">Pick report to download:</div>
        <div className="report-selector-column">
          <Select
            className="report-selector"
            options={REPORT_OPTIONS}
            value={report}
            onChange={setReport}
            placeholder="Select report"
          />
          <Select
            className="report-selector"
            options={monthOptions}
            value={month}
            onChange={setMonth}
            placeholder="Select month"
            getOptionLabel={formatMonthOption}
          />
          <Button type="primary" disabled={!downloadEnabled} action={downloadReport}>
            {downloadClassesButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(ReportTab);
