import { request } from "../utils";
import { FILE_CHUNK_SIZE } from "../constants";

export default async function uploadFile(file, assetType, queryParams) {
  // TODO: Support multipart uploads
  // const numberOfParts = Math.ceil(file.size / FILE_CHUNK_SIZE);
  const numberOfParts = 1;

  if (numberOfParts > 1) {
    const signedUrlObjects = await request.get(
      `/files/${assetType}/start?numberofparts=${numberOfParts}&${queryParams}`,
      {
        headers: { "file-type": file.type }
      }
    );
    const { uuid: fileName, uploadId } = signedUrlObjects[0] || {};

    const filePartPromises = signedUrlObjects.map(({ uploadURL }, i, all) => {
      const start = i * FILE_CHUNK_SIZE;
      const end = i === all.length ? undefined : (i + 1) * FILE_CHUNK_SIZE;
      const filePart = file.slice(start, end);
      return request.bare.put(uploadURL, filePart, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Expose-Headers": "ETag"
        }
      });
    });
    await Promise.all(filePartPromises);

    const { url } = await request.post(`/files/${assetType}/end`, {
      body: { fileName, uploadId },
      headers: { "file-type": file.type }
    });

    return url;
  } else {
    const { url, uploadURL, fileType } = await request.get(`/files/${assetType}?${queryParams}`, {
      headers: { "file-type": file.type }
    });
    await request.bare.put(uploadURL, file, {
      headers: { "Content-Type": fileType }
    });
    return url;
  }
}
