import React, { useState } from "react";
import { observer } from "mobx-react";
import { getDataURLFromFile } from "../../../../utils";
import CurriculumStore from "../../../../stores/CurriculumStore";
import { Spinner, Button, ImageFileSelect } from "../../../-common";
import "./UpdateArtworkModal.scss";

function UpdateArtworkModal({ album, close }) {
  const { title } = album || {};

  const [uploading, setUploading] = useState(false);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const uploadEnabled = !!file;
  const upload = async () => {
    if (uploadEnabled) {
      setUploading(true);
      const success = await CurriculumStore?.updateAlbumArtwork(album?.id, file);
      setUploading(false);
      if (success) close();
    }
  };

  const cancelButtonStyles = uploading ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = uploadEnabled
    ? uploading
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = uploading ? <Spinner /> : "Update Artwork";

  return (
    <div className="update-artwork-modal">
      <div className="content">
        <div className="title">Update Artwork for {title}</div>
        <div className="section-container columns">
          <div className="col">
            <ImageFileSelect {...{ filePreviewURL, updateFile }} />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={upload} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(UpdateArtworkModal);
