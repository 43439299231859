import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation, navigate } from "@reach/router";
import useQueryParams from "../../../../hooks/useQueryParams";
import { Select } from "../../../-common";
import ClassesStore from "../../../../stores/ClassesStore";
import "./ClassesTab.scss";
import { format, parseISO } from "date-fns";
import sortByCurriculum from "src/components/@Analytics/EnrollmentsTab/collections/sortByCurriculum";

const ALBUM_ARTWORK_SIZE = 80;
const ALBUM_ARTWORK_SPACING = 10;
const DAY_ORDER = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const filterOptions = ["Current Classes", "Past Classes", "Upcoming Classes", "All Classes"];

const sortClassesByCurriculumCourseDayAndTime = (a, b) => {
  const {
    curriculum: { title: aCurriculum } = {},
    order: aCourseOrder,
    sessionTime: aSessionTime,
    rawSessionTime: aRawSessionTime
  } = a?.course || {};
  const {
    curriculum: { title: bCurriculum } = {},
    order: bCourseOrder,
    sessionTime: bSessionTime,
    rawSessionTime: bRawSessionTime
  } = b?.course || {};
  const aDaySort = DAY_ORDER?.indexOf(aSessionTime?.slice(0, 3));
  const bDaySort = DAY_ORDER?.indexOf(bSessionTime?.slice(0, 3));

  if (aCurriculum !== bCurriculum) return sortByCurriculum(aCurriculum, bCurriculum);
  if (aCourseOrder !== bCourseOrder) return aCourseOrder > bCourseOrder ? 1 : -1;
  if (aDaySort !== bDaySort) return aDaySort > bDaySort ? 1 : -1;
  return aRawSessionTime > bRawSessionTime ? 1 : -1;
};

const ClassRow = ({ lpmId, id, classId, course, name, sessionTime, capacity, stats, period, shippingDate }) => {
  const { albums } = course || {};
  const { studentsEnrolled, parentsEnrolled } = stats || {};
  const seatsAvailable = capacity - studentsEnrolled;
  const seatsAvailableDetails = `(${studentsEnrolled} / ${capacity} students, ${parentsEnrolled} parents)`;

  const goToClassDetails = () => navigate(`/people/${lpmId}/classes/${id || classId}`);

  let artwork;
  if (albums?.length > 1) {
    const size = ALBUM_ARTWORK_SIZE - (albums?.length - 1) * ALBUM_ARTWORK_SPACING;
    const albumCoverItems = albums?.map(({ id, title, artwork }, i) => (
      <img
        className="album-artwork"
        src={artwork}
        style={{ height: size, width: size, top: ALBUM_ARTWORK_SPACING * i, left: ALBUM_ARTWORK_SPACING * i }}
        alt={`Artwork for ${title} album`}
        key={id}
      />
    ));
    artwork = <div className="multi-album-artwork-container">{albumCoverItems}</div>;
  } else if (albums?.[0]) {
    const { title, artwork: albumArtwork } = albums[0];
    artwork = <img src={albumArtwork} className="album-artwork" alt={`Artwork for ${title} album`} />;
  }

  const shipDateLabel =
    shippingDate && shippingDate !== "0000-00-00" ? format(parseISO(shippingDate), "MMM do yyyy") : "-";

  return (
    <tr>
      <td>
        <div className="cell-contents artwork">{artwork}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div className={!name ? "aux" : null}>{name || "(no class name found)"}</div>
          <div>({period})</div>
          <div className="aux">Ship date: {shipDateLabel}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div>{sessionTime}</div>
          <div className="seats-available">{seatsAvailable} seats available</div>
          <div className="seats-available-details">{seatsAvailableDetails}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={goToClassDetails}>
            View Details
          </div>
        </div>
      </td>
    </tr>
  );
};

const ClassesTab = ({ user }) => {
  const location = useLocation();

  const allClasses = ClassesStore?.classesByTeacherId?.[user?.id] || [];

  const { show } = useQueryParams();
  const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions[0]);
  const updateFilter = filterOption => {
    setSelectedFilterOption(filterOption);
    const tabOnlySearchParamsString = location.search?.split("&")?.[0];
    navigate(location.pathname + tabOnlySearchParamsString + "&show=" + encodeURIComponent(filterOption));
  };
  useEffect(() => {
    if (show && show !== selectedFilterOption) setSelectedFilterOption(show);
  }, [show, selectedFilterOption]);

  const classes = allClasses
    ?.filter(c => {
      if (selectedFilterOption === "Current Classes") return c?.current;
      if (selectedFilterOption === "Past Classes") return c?.past;
      if (selectedFilterOption === "Upcoming Classes") return c?.future;
      if (selectedFilterOption === "All Classes") return true;
      return false;
    })
    ?.sort(sortClassesByCurriculumCourseDayAndTime);

  const classRows = classes?.map(classItem => (
    <ClassRow lpmId={user?.id} {...classItem} key={classItem?.id || classItem?.classId} />
  ));
  const classesTableContent = classes?.length ? (
    <div className="table-wrapper classes-table">
      <table>
        <thead>
          <tr>
            <th>Course</th>
            <th>Info</th>
            <th>Details</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{classRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no classes)</div>
    </div>
  );

  return (
    <div className="classes-tab">
      <div className="classes-filter-row">
        <div className="classes-filter-label">Show:</div>
        <div className="classes-filter-select">
          <Select options={filterOptions} value={selectedFilterOption} onChange={updateFilter} />
        </div>
      </div>
      {classesTableContent}
    </div>
  );
};

export default observer(ClassesTab);
