import { useState } from "react";
import { observer } from "mobx-react";
import { FiHash } from "react-icons/fi";
import { Input, Spinner, Button } from "../../../../components/-common";
import AuthStore from "../../../../stores/AuthStore";
import "./VerifyAccount.scss";

const VerifyAccount = ({ setAccountVerificationSuccessful, setNewLPMId }) => {
  const [code, setCode] = useState("");
  const updateCode = ({ target }) => setCode(target.value);

  const [errorMessage, setErrorMessage] = useState();

  const resendCode = () => AuthStore?.resendVerificationCode();

  const verificationEnabled = code?.length === 6 && Number(code);
  const attemptVerification = async () => {
    setErrorMessage("");
    if (verificationEnabled) {
      const { success, error } = await AuthStore.verifyAccount(code);
      if (success) {
        setAccountVerificationSuccessful(true);
      } else {
        setErrorMessage(error);
      }
    }
  };

  const errorComponent = errorMessage ? (
    <div className="error-container">
      <div className="error-text">{errorMessage}</div>
    </div>
  ) : null;

  const buttonContents = AuthStore.loading ? <Spinner /> : "Verify Account";
  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};

  return (
    <div className="verify-content">
      <div className="verify-square">
        <div className="verify-inputs">
          <Input icon={FiHash} type="text" placeholder="Verification Code" value={code} onChange={updateCode} />
          <Button
            type="cta"
            className="log-in-button"
            style={buttonStyles}
            action={attemptVerification}
            disabled={!verificationEnabled}
          >
            {buttonContents}
          </Button>
        </div>
        {errorComponent}
        <div className="no-account">
          <span className="row-title">User didn't get an email?</span>
          <Button type="cta" className="sign-up-button" style={buttonStyles} action={resendCode}>
            Resend Code
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(VerifyAccount);
