import React, { useState } from "react";
import { observer } from "mobx-react";
import MaterialsStore from "../../../../stores/MaterialsStore";
import { Spinner, Button, PieTimer } from "../../";
import "./ConfirmDeleteProductModal.scss";

function ConfirmDeleteProductModal({ product, close }) {
  const { id: productId, name } = product || {};

  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const enableDelete = () => setDeleteEnabled(true);

  const [deleting, setDeleting] = useState(false);

  const deleteProduct = async () => {
    setDeleting(true);
    await MaterialsStore.deleteProduct(productId);
    close();
  };

  const cancelButtonStyles = deleting ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = deleting ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = deleting ? <Spinner /> : "Remove Product";
  const actionButton = deleteEnabled ? (
    <Button type="negative" action={deleteProduct} style={actionButtonStyles}>
      {actionButtonContent}
    </Button>
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--red)" width={24} height={24} duration={3000} onComplete={enableDelete} />
    </div>
  );

  return (
    <div className="confirm-delete-product-modal">
      <div className="content">
        <div className="title">Are you sure you want to remove {name}?</div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">{actionButton}</div>
      </div>
    </div>
  );
}

export default observer(ConfirmDeleteProductModal);
