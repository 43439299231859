import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { FiHash } from "react-icons/fi";
import { Spinner, Button, Input } from "../../../-common";
import PeopleStore from "../../../../stores/PeopleStore";
import "./SyncMissingLPMIDModal.scss";

function SyncMissingLPMIDModal({ close }) {
  const [infusionsoftId, setInfusionsoftId] = useState("");
  const updateInfusionsoftId = ({ target }) => setInfusionsoftId(target.value);

  const [loading, setLoading] = useState(false);

  const syncMissingLPMId = async () => {
    setLoading(true);
    const { id } = await PeopleStore.syncUserByInfusionsoftId(infusionsoftId);
    setLoading(false);
    close();
    navigate(`/people/${id}`);
  };

  const cancelButtonStyles = loading ? { opacity: 0.1, pointerEvents: "none" } : {};
  const actionButtonStyles = loading ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = loading ? <Spinner /> : "Sync";

  return (
    <div className="sync-missing-lpm-id-modal">
      <div className="content">
        <div className="title">Fix Missing Person</div>
        <div className="information-text">
          Enter the InfusionSoft ID for someone who seems to be missing from search and this will attempt to fix it.
        </div>
        <Input
          className="infusionsoft-id-input"
          icon={FiHash}
          type="text"
          placeholder="Enter InfusionSoft ID"
          value={infusionsoftId}
          onChange={updateInfusionsoftId}
        />
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="cta" action={syncMissingLPMId} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(SyncMissingLPMIDModal);
