import React, { useState } from "react";
import {
  ComposedChart,
  Bar,
  Line,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { TOTAL_COLORS } from "../constants";
import {
  formatDateTickAtDay,
  formatDateTickAtMonth,
  formatTickAsCurrency,
  abbreviateTick,
  tooltipFormatter
} from "../formatters";
import "./BarAndLineChart.scss";

function BarAndLineChart({
  data,
  lineSeries = [],
  barsAreCurrency,
  linesAreCurrency,
  categorical,
  legendPosition = "bottom",
  width = "100%",
  height = "100%",
  dateGrain = "month",
  onDrill = () => null
}) {
  const dateFormatter = dateGrain === "day" ? formatDateTickAtDay : formatDateTickAtMonth;
  const dataSeries = Array.from(new Set(data?.map(({ name, ...series }) => Object.keys(series))?.flat()));

  const [focusedBarIndex, setFocusedBarIndex] = useState(null);
  const updateFocusedBarIndex = state => {
    if (state.isTooltipActive) {
      setFocusedBarIndex(state.activeTooltipIndex);
    } else {
      setFocusedBarIndex(null);
    }
  };
  const unsetFocusedBarIndex = () => setFocusedBarIndex(null);

  const [hoveredLegendEntry, setHoveredLegendEntry] = useState(null);
  const updateHoveredLegendEntry = ({ dataKey }) => setHoveredLegendEntry(dataKey);
  const unsetHoveredLegendEntry = () => setHoveredLegendEntry(null);

  const onLineDotClick = (_, { payload }) => onDrill(payload);
  const onMarkerClick = ({ payload }) => onDrill(payload);

  const legendProps =
    legendPosition === "right"
      ? { layout: "vertical", verticalAlign: "middle", align: "right", wrapperStyle: { paddingLeft: 16 } }
      : {};

  if (!dataSeries?.length) return <div className="bar-and-line-chart no-data">(no data)</div>;

  const renderLine = (dataKey, i) => {
    const colorIndex = (i % TOTAL_COLORS) + 1;
    const color = `var(--chart-${colorIndex})`;

    const legendEntryHovered = hoveredLegendEntry === dataKey;
    const anyLegendEntryHovered = hoveredLegendEntry != null;

    const anyFocused = focusedBarIndex != null;

    const markerProps = {
      strokeWidth: 2.5,
      dot: { fill: "var(--white)", strokeWidth: 2 },
      activeDot: { onClick: onLineDotClick, style: { cursor: "pointer" } },
      ...(anyFocused ? { opacity: 0.3 } : {})
    };

    const pointerAndOpacityStyles = {
      cursor: "pointer",
      ...(anyLegendEntryHovered && !legendEntryHovered ? { opacity: 0.1 } : {})
    };

    const dataForCells = data?.map(datum => datum[dataKey]);
    const cells = dataForCells?.map((_, j) => {
      const isFocused = focusedBarIndex === j;
      const focusProps = !isFocused && anyFocused ? { opacity: 0.3 } : {};
      return <Cell key={`cell-${i}-${j}`} {...focusProps} style={pointerAndOpacityStyles} />;
    });

    return (
      <Line
        style={pointerAndOpacityStyles}
        yAxisId="line"
        onClick={onMarkerClick}
        onMouseLeave={unsetFocusedBarIndex}
        {...{ dataKey, stroke: color, fill: color }}
        {...markerProps}
        key={dataKey}
      >
        {cells}
      </Line>
    );
  };
  const lineItems = dataSeries?.filter(dataKey => lineSeries.includes(dataKey))?.map(renderLine);

  const renderBar = (dataKey, i) => {
    const colorIndex = ((i + (lineItems?.length || 0)) % TOTAL_COLORS) + 1;
    const color = `var(--chart-${colorIndex})`;

    const legendEntryHovered = hoveredLegendEntry === dataKey;
    const anyLegendEntryHovered = hoveredLegendEntry != null;

    const anyFocused = focusedBarIndex != null;

    const pointerAndOpacityStyles = {
      cursor: "pointer",
      ...(anyLegendEntryHovered && !legendEntryHovered ? { opacity: 0.1 } : {})
    };

    const dataForCells = data?.map(datum => datum[dataKey]);
    const cells = dataForCells?.map((_, j) => {
      const isFocused = focusedBarIndex === j;
      const focusProps = !isFocused && anyFocused ? { opacity: 0.3 } : {};
      return <Cell key={`cell-${i}-${j}`} {...focusProps} style={pointerAndOpacityStyles} />;
    });

    return (
      <Bar
        style={pointerAndOpacityStyles}
        yAxisId="bar"
        onClick={onMarkerClick}
        onMouseLeave={unsetFocusedBarIndex}
        {...{ dataKey, stroke: color, fill: color }}
        key={dataKey}
      >
        {cells}
      </Bar>
    );
  };
  const barItems = dataSeries?.filter(dataKey => !lineSeries.includes(dataKey))?.map(renderBar);

  return (
    <ResponsiveContainer className="bar-and-line-chart" {...{ width, height }} debounce={1}>
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        onMouseMove={updateFocusedBarIndex}
        onMouseLeave={unsetFocusedBarIndex}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="var(--light-gray)" />
        <XAxis
          dataKey="name"
          tickLine={{ stroke: "var(--light-gray)" }}
          axisLine={{ stroke: "var(--light-gray)" }}
          tickFormatter={categorical ? null : dateFormatter}
        />
        <YAxis
          orientation="left"
          yAxisId="bar"
          tickLine={{ stroke: "var(--light-gray)" }}
          axisLine={{ stroke: "var(--light-gray)" }}
          tickFormatter={barsAreCurrency ? formatTickAsCurrency : abbreviateTick}
        />
        <YAxis
          orientation="right"
          yAxisId="line"
          tickLine={{ stroke: "var(--light-gray)" }}
          axisLine={{ stroke: "var(--light-gray)" }}
          tickFormatter={linesAreCurrency ? formatTickAsCurrency : abbreviateTick}
        />
        <Tooltip
          labelFormatter={categorical ? null : dateFormatter}
          formatter={tooltipFormatter({ categorical, dateGrain, lines: lineSeries, linesAreCurrency, barsAreCurrency })}
          isAnimationActive={false}
          labelStyle={{
            marginBottom: 4,
            color: "var(--gray)",
            fontSize: 14,
            fontWeight: "500",
            textTransform: "uppercase"
          }}
          itemStyle={{ marginLeft: 6, fontWeight: "500" }}
          contentStyle={{ border: "none", boxShadow: "var(--shadow)" }}
        />
        <Legend {...legendProps} onMouseEnter={updateHoveredLegendEntry} onMouseLeave={unsetHoveredLegendEntry} />
        {barItems}
        {lineItems}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default BarAndLineChart;
