import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiLock } from "react-icons/fi";
import PeopleStore from "../../../../../stores/PeopleStore";
import { Button, Input, Spinner } from "../../../../-common";
import "./CreateAccountModal.scss";

function CreateAccountModal({ user, close }) {
  const { firstName, email } = user || {};
  const [newPassword, setNewPassword] = useState("");
  const updateNewPassword = ({ target }) => setNewPassword(target.value);

  const [loading, setLoading] = useState(false);

  const changePasswordEnabled = newPassword?.length >= 6;
  const changePassword = async () => {
    if (changePasswordEnabled) {
      setLoading(true);
      await PeopleStore?.createCognitoAccount(user, newPassword);
      setLoading(false);
      close();
    }
  };

  const actionButtonStyles = loading ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = loading ? <Spinner /> : "Submit";

  return (
    <div className="create-account-modal">
      <div className="content">
        <div className="title">Create account for {firstName}</div>
        <div className="message">
          A new login account will be created for this user to access LPM properties (not including Info House).
        </div>
        <div className="message">The new password needs to have six or more characters.</div>

        <div className="message">{`Email: ${email[0].email}`}</div>

        <div className="input-wrapper">
          <Input
            icon={FiLock}
            type="text"
            placeholder="New Password"
            value={newPassword}
            onChange={updateNewPassword}
            pattern="[0-9]*"
            inputmode="numeric"
          />
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className="send-request-button"
            action={changePassword}
            disabled={!changePasswordEnabled}
            style={actionButtonStyles}
          >
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(CreateAccountModal);
