import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiMinusCircle, FiPlus } from "react-icons/fi";
import { getDataURLFromFile } from "../../../../utils";
import CurriculumStore from "../../../../stores/CurriculumStore";
import { AudioFileSelect, Button, Input, Spinner } from "../../../-common";
import "./AddTrackModal.scss";

const LearningPurposeRow = ({ text, update, remove }) => {
  return (
    <div className="learning-purpose-row">
      <Input value={text} onChange={update} />
      <FiMinusCircle size={36} stroke="var(--red)" onClick={remove} />
    </div>
  );
};

function AddTrackModal({ album, close }) {
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const updateTitle = ({ target }) => setTitle(target.value);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const [principalPurposes, setPrincipalPurposes] = useState([]);
  const addPrincipalPurpose = () => setPrincipalPurposes(principalPurposes?.concat(""));
  const updatePrincipalPurpose =
    i =>
    ({ target }) => {
      setPrincipalPurposes(principalPurposes?.map((originalText, j) => (i === j ? target.value : originalText)));
    };
  const removePrincipalPurpose = i => () => {
    setPrincipalPurposes(principalPurposes?.slice(0, i)?.concat(principalPurposes?.slice(i + 1)));
  };

  const [secondaryPurposes, setSecondaryPurposes] = useState([]);
  const addSecondaryPurpose = () => setSecondaryPurposes(secondaryPurposes?.concat(""));
  const updateSecondaryPurpose =
    i =>
    ({ target }) => {
      setSecondaryPurposes(secondaryPurposes?.map((originalText, j) => (i === j ? target.value : originalText)));
    };
  const removeSecondaryPurpose = i => () => {
    setSecondaryPurposes(secondaryPurposes?.slice(0, i)?.concat(secondaryPurposes?.slice(i + 1)));
  };

  const hasTitle = !!title;
  const noEmptyPurposes = principalPurposes?.every(Boolean) && secondaryPurposes?.every(Boolean);
  const addTrackEnabled = hasTitle && noEmptyPurposes;
  const addTrack = async () => {
    if (addTrackEnabled) {
      setLoading(true);
      const success = await CurriculumStore?.addTrack(album?.id, { title, file, principalPurposes, secondaryPurposes });
      setLoading(false);
      if (success) close();
    }
  };

  const principalPurposeRows = principalPurposes?.map((text, i) => (
    <LearningPurposeRow text={text} update={updatePrincipalPurpose(i)} remove={removePrincipalPurpose(i)} />
  ));
  const secondaryPurposeRows = secondaryPurposes?.map((text, i) => (
    <LearningPurposeRow text={text} update={updateSecondaryPurpose(i)} remove={removeSecondaryPurpose(i)} />
  ));

  const cancelButtonStyles = loading ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addTrackEnabled
    ? loading
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = loading ? <Spinner /> : "Add Track";

  return (
    <div className="add-track-modal">
      <div className="content">
        <div className="title">Add Track</div>
        <div className="section-container columns">
          <div className="col">
            <div className="section-container">
              <div className="section-title">Title</div>
              <Input placeholder="Track title" value={title} onChange={updateTitle} />
            </div>
            <div className="section-container">
              <div className="section-title">Recording</div>
              <div className="upload-recording-wrapper">
                <AudioFileSelect {...{ size: 264, filePreviewURL, updateFile }} />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="section-container">
              <div className="section-title">Principal Learning Purposes</div>
              {principalPurposeRows}
              <div className="add-learning-purpose" onClick={addPrincipalPurpose}>
                <FiPlus size={24} />
                <span>Add Primary Learning Purpose</span>
              </div>
            </div>
            <div className="section-container">
              <div className="section-title">Secondary Learning Purposes</div>
              {secondaryPurposeRows}
              <div className="add-learning-purpose" onClick={addSecondaryPurpose}>
                <FiPlus size={24} />
                <span>Add Secondary Learning Purpose</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addTrack} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddTrackModal);
