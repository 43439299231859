import React, { useState } from "react";
import { observer } from "mobx-react";
import PlaylistsStore from "../../../stores/PlaylistsStore";
import { Spinner, Button, PieTimer } from "..";
import "./ConfirmDeletePlaylistModal.scss";

function ConfirmDeletePlaylistModal({ albumId, playlist, close }) {
  const { id, name } = playlist || {};

  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const enableDelete = () => setDeleteEnabled(true);

  const [deleting, setDeleting] = useState(false);

  const deletePlaylist = async () => {
    setDeleting(true);
    await PlaylistsStore.deletePlaylist(albumId, id);
    close();
  };

  const cancelButtonStyles = deleting ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = deleting ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = deleting ? <Spinner /> : "Delete Playlist";
  const actionButton = deleteEnabled ? (
    <Button type="negative" action={deletePlaylist} style={actionButtonStyles}>
      {actionButtonContent}
    </Button>
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--red)" width={24} height={24} duration={3000} onComplete={enableDelete} />
    </div>
  );

  return (
    <div className="confirm-delete-playlist-modal">
      <div className="content">
        <div className="title">Are you sure you want to delete {name}?</div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">{actionButton}</div>
      </div>
    </div>
  );
}

export default observer(ConfirmDeletePlaylistModal);
