import React, { useState } from "react";
import { FiBox, FiMinus, FiPlus } from "react-icons/fi";
import { ENV } from "../../../../config";
import "./ProductItem.scss";

const ProductItem = ({ product, quantity = 0, updateQuantity }) => {
  const { id, name, longDescription: description, price } = product || {};

  const [imageError, setImageError] = useState(false);
  const markImageInvalid = () => setImageError(true);

  const removeQuantity = () => updateQuantity(Math.max(0, quantity - 1));
  const addQuantity = () => updateQuantity(quantity + 1);

  const image = imageError ? (
    <div className="preview-image fallback">
      <FiBox color="var(--medium-gray)" size={40} />
    </div>
  ) : (
    <img
      className="preview-image"
      src={`https://${ENV}-lpm-assets.b-cdn.net/products/${id}`}
      alt="What this product looks like."
      onError={markImageInvalid}
    />
  );

  return (
    <div className="product-item-wrapper">
      <div className="product-item-content">
        {image}
        <div className="info-container">
          <div className="product-item-title">{name}</div>
          <div className="product-item-info">{description}</div>
          <div style={{ flex: 1 }} />
          <div className="product-item-price">${(price / 100).toFixed(2)}</div>
        </div>
        <div className="quantity-container">
          <FiMinus
            className={`quantity-icon${!quantity ? " disabled" : ""}`}
            strokeWidth={4}
            onClick={removeQuantity}
          />
          <div className="quantity">{quantity || 0}</div>
          <FiPlus className="quantity-icon" strokeWidth={4} onClick={addQuantity} />
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
