import React from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { parseISO, format } from "date-fns";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { FiMoreVertical } from "react-icons/fi";
import { formatMilliseconds } from "../../../../utils";
import "./TracksTab.scss";

const TrackRow = ({ order, title, duration, modified, id: trackId, curriculumId, courseId, albumId, sorting }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: trackId,
    disabled: !sorting
  });
  const sortStyles = { transform: CSS.Transform.toString(transform), transition };

  const goToTrackDetails = () => {
    navigate(`/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}/tracks/${trackId}`);
  };

  let lastUpdated;
  if (modified) lastUpdated = format(parseISO(modified), "MMM do, yyyy") + " at " + format(parseISO(modified), "p");

  const sortingContentStyles = sorting ? { opacity: 0.2 } : {};

  const actions = sorting ? (
    <div {...attributes} {...listeners} className="drag-handle">
      <FiMoreVertical size={36} color="var(--orange)" />
    </div>
  ) : (
    <>
      <div className="action" onClick={goToTrackDetails}>
        View Details
      </div>
    </>
  );

  return (
    <tr ref={setNodeRef} style={sortStyles}>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {order}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {title}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {formatMilliseconds(duration)}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {lastUpdated}
        </div>
      </td>
      <td>
        <div className="cell-contents">{actions}</div>
      </td>
    </tr>
  );
};

const TracksTab = ({ curriculum, course, album, tracks, setTracks, sorting }) => {
  const { id: curriculumId } = curriculum || {};
  const { id: courseId } = course || {};
  const { id: albumId } = album || {};

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setTracks(items => {
        const oldIndex = items.findIndex(i => i?.id === active?.id);
        const newIndex = items.findIndex(i => i?.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const trackRows = tracks?.map(track => (
    <TrackRow {...{ ...track, curriculumId, courseId, albumId, sorting }} key={track?.id} />
  ));
  const tracksTableContent = tracks?.length ? (
    <div className="table-wrapper tracks-table">
      <table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Title</th>
            <th>Duration</th>
            <th>Last Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext items={tracks} strategy={verticalListSortingStrategy}>
              {trackRows}
            </SortableContext>
          </DndContext>
        </tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No tracks yet - add one to get started!</div>
    </div>
  );

  return <div className="tracks-tab">{tracksTableContent}</div>;
};

export default observer(TracksTab);
