import { useState, useEffect } from "react";
import useQueryParams from "./useQueryParams";

export default function useTabs(sections) {
  const { tab } = useQueryParams();
  const tabKeys = sections?.map(({ title }) => title?.toLowerCase());

  const initialTabIndex = Math.max(tabKeys?.indexOf(tab), 0);
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);
  useEffect(() => {
    setActiveTabIndex(initialTabIndex);
  }, [initialTabIndex]);
  useEffect(() => {
    if (activeTabIndex > sections?.length - 1) setActiveTabIndex(initialTabIndex);
  }, [activeTabIndex, sections, initialTabIndex]);

  return { activeTabIndex, setActiveTabIndex, tabKeys };
}
