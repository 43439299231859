import React from "react";
import { observer } from "mobx-react";
import { useJsApiLoader, GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import geohash from "ngeohash";
import { GOOGLE_MAPS_API_KEY } from "../../../../../config";
import { colors } from "../../../../../styles/theme";
import mapOptions from "./mapOptions";
import "./ViewStudioLocationOnMapModal.scss";

const containerStyle = {
  height: "100%",
  width: "100%",
  boxShadow: "var(--shadow)"
};

function ViewStudioLocationOnMapModal({ user, location }) {
  const { firstName } = user || {};
  const { latitude: lat, longitude: lng } = location || {};
  const center = { lat, lng };

  const { isLoaded } = useJsApiLoader({ id: "google-map-script", googleMapsApiKey: GOOGLE_MAPS_API_KEY });
  if (!isLoaded) return <div />;

  const options = { ...mapOptions, center };

  const bbox = geohash.decode_bbox(location?.geohash);
  const [minLat, minLng, maxLat, maxLng] = bbox || [];
  const path = [
    { lat: minLat, lng: minLng },
    { lat: minLat, lng: maxLng },
    { lat: maxLat, lng: maxLng },
    { lat: maxLat, lng: minLng },
    { lat: minLat, lng: minLng }
  ];

  const bboxOptions = {
    strokeColor: colors.darkPrimary,
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: colors.darkPrimary,
    fillOpacity: 0.25,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    paths: path,
    zIndex: 1
  };

  return (
    <div className="view-studio-location-on-map-modal">
      <div className="content">
        <div className="title">
          {firstName}'s{location?.name ? ' "' + location.name + '"' : "Studio"} Location
        </div>
        <div className="map-container">
          <GoogleMap mapContainerStyle={containerStyle} options={options}>
            <Polygon path={path} options={bboxOptions} />
            <Marker position={center} />
          </GoogleMap>
        </div>
      </div>
    </div>
  );
}

export default observer(ViewStudioLocationOnMapModal);
