import React, { useState } from "react";
import numeral from "numeral";
import { FiBox, FiMoreVertical } from "react-icons/fi";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import useModal from "../../../hooks/useModal";
import EditProductModalBody from "./EditProductModal";
import ConfirmDeleteProductModalBody from "./ConfirmDeleteProductModal";
import ConfirmDeleteCourseProductModalBody from "./ConfirmDeleteCourseProductModal";
import "./ProductRow.scss";

const ProductRow = ({ id, courseId, name, price, media, isCourseProduct, sorting }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled: !sorting
  });
  const sortStyles = { transform: CSS.Transform.toString(transform), transition };

  const [mediaError, setMediaError] = useState(false);
  const markMediaError = () => setMediaError(true);

  const { Modal: EditProductModal, openModal: openEditProductModal } = useModal(
    EditProductModalBody,
    { product: { id, courseId, name, price, media } },
    { portalElementId: "edit-product-modal" }
  );

  const { Modal: ConfirmDeleteProductModal, openModal: openConfirmDeleteProductModal } = useModal(
    ConfirmDeleteProductModalBody,
    { product: { id, courseId, name, price, media } },
    { portalElementId: "confirm-delete-product-modal" }
  );

  const { Modal: ConfirmDeleteCourseProductModal, openModal: openConfirmDeleteCourseProductModal } = useModal(
    ConfirmDeleteCourseProductModalBody,
    { product: { id, courseId, name, price, media } },
    { portalElementId: "confirm-delete-course-product-modal" }
  );

  const showConfirmDelete = isCourseProduct ? openConfirmDeleteCourseProductModal : openConfirmDeleteProductModal;

  const productArtwork = mediaError ? (
    <div className="product-artwork placeholder">
      <FiBox size={36} />
    </div>
  ) : (
    <img className="product-artwork" src={media} alt={`Artwork for ${name}`} onError={markMediaError} />
  );

  const sortingContentStyles = sorting ? { opacity: 0.2 } : {};

  const priceLabel = numeral(price / 100).format("$0,00.00");

  const actions = sorting ? (
    <div {...attributes} {...listeners} className="drag-handle">
      <FiMoreVertical size={36} color="var(--orange)" />
    </div>
  ) : (
    <>
      <div className="action" onClick={openEditProductModal}>
        Edit Details
      </div>
      <div className="action" onClick={showConfirmDelete}>
        Remove
      </div>
    </>
  );

  return (
    <tr ref={setNodeRef} style={sortStyles} className="product-row">
      <td>
        <div className="cell-contents artwork" style={sortingContentStyles}>
          <div>{productArtwork}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {name}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          <div>{priceLabel}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents actions">{actions}</div>
      </td>
      <EditProductModal />
      <ConfirmDeleteProductModal />
      <ConfirmDeleteCourseProductModal />
    </tr>
  );
};

export default ProductRow;
