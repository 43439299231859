import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import CurriculumStore from "../stores/CurriculumStore";

export default function useAlbum(albumId, { curriculumId, courseId }) {
  const { albumsById, albumDetails } = CurriculumStore;
  const [album, setAlbum] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const matchingAlbumDetails = albumDetails[albumId];
    const matchingAlbum = albumsById?.[albumId];
    if (matchingAlbumDetails && matchingAlbum) {
      setAlbum(toJS(matchingAlbum));
      setLoading(false);
    } else {
      if (albumId) {
        (async () => {
          let fetchedAlbumBasics = matchingAlbum;
          let fetchedAlbumDetails = matchingAlbumDetails;

          if (!fetchedAlbumBasics) {
            await CurriculumStore?.fetchAlbumList();
            fetchedAlbumBasics = CurriculumStore?.albumsById?.[albumId];
          }

          if (!fetchedAlbumDetails) {
            fetchedAlbumDetails = await CurriculumStore.fetchAlbumDetails(albumId);
          }

          if (!fetchedAlbumBasics || !fetchedAlbumDetails) {
            navigate(`/curriculum/${curriculumId}/courses/${courseId}`);
          }

          setLoading(false);
        })();
      }
    }
  }, [albumsById, albumDetails, albumId, curriculumId, courseId]);
  return { album, loading };
}
