import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "@reach/router";
import { FiChevronRight, FiBox } from "react-icons/fi";
import ShippingStore from "../../../stores/ShippingStore";
import { Spinner } from "../../-common";
import "./UpcomingShipmentSchedule.scss";
import { format } from "date-fns";
import groupBy from "lodash.groupby";

const periodOptions = ["Next Two Weeks", "Next Month", "Next 90 Days", "Next Six Months", "Next Year"];

const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;
const ONE_MONTH = 1000 * 60 * 60 * 24 * 30;
const THREE_MONTHS = 1000 * 60 * 60 * 24 * 90;
const SIX_MONTHS = 1000 * 60 * 60 * 24 * 180;
const ONE_YEAR = 1000 * 60 * 60 * 24 * 365;

const countProductsForPeriod = (productDayItems, period) => {
  const today = format(new Date(), "yyyy-MM-dd");
  return productDayItems
    ?.filter(item => {
      if (item?.date < today) return false;
      if (period === "Next Two Weeks") return item?.date <= format(new Date() + TWO_WEEKS, "yyyy-MM-dd");
      else if (period === "Next Month") return item?.date <= format(new Date() + ONE_MONTH, "yyyy-MM-dd");
      else if (period === "Next 90 Days") return item?.date <= format(new Date() + THREE_MONTHS, "yyyy-MM-dd");
      else if (period === "Next Six Months") return item?.date <= format(new Date() + SIX_MONTHS, "yyyy-MM-dd");
      else if (period === "Next Year") return item?.date <= format(new Date() + ONE_YEAR, "yyyy-MM-dd");
      return false;
    })
    ?.reduce((acc, next) => acc + next?.quantity, 0);
};

const ZeroState = () => (
  <div className="class-overview">
    <div className="title-row">
      <div className="title">
        <Link to="/shipping">Shipping</Link>
      </div>
    </div>
    <div className="loading-container">
      <Spinner alt />
    </div>
  </div>
);

const ProductRow = ({ product, quantity }) => {
  const { name, productPicture } = product || {};

  const [productPictureError, setProductPictureError] = useState(false);
  const markProductPictureError = () => setProductPictureError(true);

  const productPictureComponent = productPictureError ? (
    <div className="product-picture placeholder">
      <FiBox size={36} />
    </div>
  ) : (
    <img className="product-picture" src={productPicture} alt="This product" onError={markProductPictureError} />
  );

  return (
    <tr>
      <td>
        <div className="cell-contents product">
          {productPictureComponent}
          <div>{name}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="quantity-container">{quantity}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action">Mark All as Processed</div>
          <div className="action">Mark All as Backordered</div>
        </div>
      </td>
    </tr>
  );
};

const ProductsTable = ({ schedule, setTotalProducts }) => {
  const [period] = useState(periodOptions?.[0]);

  const productDayItemGroups = Object.values(groupBy(schedule, "product.id"));
  const productRowItems = productDayItemGroups?.map(pdig => ({
    product: pdig?.[0]?.product,
    quantity: countProductsForPeriod(pdig, period)
  }));
  const totalProducts = productRowItems?.reduce((acc, next) => acc + next?.quantity || 0, 0);
  useEffect(() => {
    setTotalProducts(totalProducts);
  }, [setTotalProducts, totalProducts]);

  const productRows = productRowItems?.map(({ product, quantity }) => (
    <ProductRow {...{ product, quantity }} key={product?.id} />
  ));

  return productRowItems?.length ? (
    <div className="table-wrapper order-items-table">
      <table>
        <thead>
          <tr>
            <th>Product</th>
            <th className="center">Total Needed</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{productRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no upcoming shipments)</div>
    </div>
  );
};

function UpcomingShipmentSchedule() {
  const [totalProducts, setTotalProducts] = useState(0); // eslint-disable-line

  const { loadingUpcomingShipmentsSchedule: loading, upcomingShipmentsSchedule } = ShippingStore || {};
  if (loading) return <ZeroState />;

  return (
    <div className="upcoming-shipment-schedule">
      <div className="title-row">
        <div className="title">
          <Link to="/shipping">Shipping</Link> <FiChevronRight className="arrow-icon" />
          Upcoming Schedule
        </div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Total Products for Period</div>
            <div className="info-value">Stat Value</div>
          </div>
          <div className="info-row">
            <div className="info-title">Stat Name</div>
            <div className="info-value">Stat Value</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action">
              <span>Some Action</span>
            </div>
          </div>
        </div>
      </div>
      <div className="header table-header"></div>
      <ProductsTable {...{ schedule: upcomingShipmentsSchedule, setTotalProducts }} />
    </div>
  );
}

export default observer(UpcomingShipmentSchedule);
