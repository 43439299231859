import React from "react";
import RadioOption from "./RadioOption";
import "./RadioGroup.scss";

const RadioGroup = ({ options, value, onSelect }) => {
  const close = options.every(o => !o.match(/\s/g));
  const select = option => () => onSelect(option);
  const optionLines = options.map((o, i) => (
    <RadioOption close={close} value={o} selected={o === value} select={select(o)} key={i} />
  ));
  return <div className="lpm-radio-group">{optionLines}</div>;
};

export default RadioGroup;
