import React, { useState } from "react";
import { observer } from "mobx-react";
import { Router, Redirect, Link } from "@reach/router";
import { Input, Spinner } from "../../components/-common";
import AuthStore from "../../stores/AuthStore";
import useOnEnterPressed from "../../hooks/useOnEnterPressed";
import VerifyAccount from "./VerifyAccount";
import ResetPassword from "./ResetPassword";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import "./Auth.scss";

const Auth = observer(() => {
  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const attemptAuth = async () => {
    setEmailError(null);
    setPasswordError(null);
    const { success, error } = await AuthStore.signIn(email, password);
    if (!success) {
      if (error === "Incorrect username or password.") {
        setEmailError(" ");
        setPasswordError(error);
      } else if (error === "User does not exist.") {
        setEmailError(error);
      } else {
        setEmailError(" ");
        setPasswordError("Sign in error - double check your entries.");
      }
    }
  };
  useOnEnterPressed(attemptAuth);

  const buttonContents = AuthStore.loading ? <Spinner /> : "SIGN IN";
  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};

  return (
    <div className="auth-wrapper">
      {/* <BackgroundBlobs /> */}
      <div className="auth-content">
        <h3 className="auth-title">LPM Admin Console</h3>
        <div className="auth-square">
          <div className="auth-inputs">
            <Input type="text" placeholder="Email Address" value={email} onChange={updateEmail} error={emailError} />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={updatePassword}
              error={passwordError}
            />
            <button style={buttonStyles} className="auth-button" onClick={attemptAuth}>
              {buttonContents}
            </button>
          </div>
          <div className="forgot-password">
            <Link to="/resetpassword">Forgot your password?</Link>
          </div>
        </div>
      </div>
    </div>
  );
});

const AuthRouter = () => {
  return (
    <Router primary={false}>
      <Auth path="/auth" />
      <VerifyAccount path="/verify" />
      <ResetPassword path="/resetpassword" />
      <ResetPasswordSuccess path="/resetpassword/success" />
      <Redirect from="*" to="/auth" noThrow />
    </Router>
  );
};

export default AuthRouter;
