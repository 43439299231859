import React from "react";
import { observer } from "mobx-react";
import { Link } from "@reach/router";
import { FiChevronRight } from "react-icons/fi";
import useCurriculum from "../../../hooks/useCurriculum";
import useModal from "../../../hooks/useModal";
import { Spinner, Button } from "../../-common";
import CourseRow from "./CourseRow";
import AddModal from "./AddCourseModal";
import ConfirmDeleteCurriculumModal from "./ConfirmDeleteCurriculumModal";
import "./CurriculumDetails.scss";

const ZeroState = () => (
  <div className="curriculum-details">
    <div className="title-row">
      <div className="title">
        <Link to="/curriculum">Curriculum</Link>
      </div>
    </div>
    <div className="loading-container">
      <Spinner alt />
    </div>
  </div>
);

function CurriculumDetails({ curriculumId }) {
  const { loading, curriculum } = useCurriculum(curriculumId);
  const { courses, title, stats } = curriculum || {};
  const { courses: totalCourses = 0, albums: totalAlbums = 0, tracks: totalTracks = 0 } = stats || {};

  const { Modal: AddCourseModal, openModal: openAddCourseModal } = useModal(
    AddModal,
    { curriculum },
    { portalElementId: "add-course-modal" }
  );

  const { Modal: ConfirmDeleteModal, openModal: openDeleteModal } = useModal(
    ConfirmDeleteCurriculumModal,
    { curriculum },
    { portalElementId: "confirm-delete-curriculum-modal" }
  );

  if (loading) return <ZeroState />;

  const courseRows = courses?.map(course => <CourseRow {...{ ...course, curriculumId }} key={course?.id} />);

  const content = courses?.length ? (
    <div className="table-wrapper courses-table">
      <table>
        <thead>
          <tr>
            <th>Albums</th>
            <th>Title</th>
            <th>Stats</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{courseRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No courses for this curriculum yet!</div>
      <Button action={openAddCourseModal} type="primary">
        Add Course
      </Button>
    </div>
  );

  return (
    <div className="curriculum-details">
      <div className="title-row">
        <div className="title">
          <Link to="/curriculum">Curriculum</Link> <FiChevronRight className="arrow-icon" /> <span>{title}</span>
        </div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Courses</div>
            <div className="info-value">{totalCourses}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Albums</div>
            <div className="info-value">{totalAlbums}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Tracks</div>
            <div className="info-value">{totalTracks}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action" onClick={openAddCourseModal}>
              <span>Add Course</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={openDeleteModal}>
              <span>Delete {title}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="header">Courses</div>
      {content}
      <AddCourseModal />
      <ConfirmDeleteModal />
    </div>
  );
}

export default observer(CurriculumDetails);
