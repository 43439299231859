import React from "react";
import CheckIcon from "../../icons/CheckIcon";
import "./RadioOption.scss";

const RadioOption = ({ close, value, selected, select }) => {
  const selectedIcon = selected ? <CheckIcon /> : null;

  return (
    <div className="lpm-radio-option" onClick={select}>
      <div className={`lpm-radio-option-box${close ? " close" : ""}${selected ? " selected" : ""}`}>{selectedIcon}</div>
      <div className="lpm-radio-option-label">{value}</div>
    </div>
  );
};

export default RadioOption;
