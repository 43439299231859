import React from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { parseISO, format } from "date-fns";
import { formatMilliseconds } from "../../../../utils";
import useModal from "../../../../hooks/useModal";
import { Button } from "../../../-common";
import ConfirmDeleteTrackModalBody from "./ConfirmDeleteTrackModal";
import "./TracksTab.scss";

const TrackRow = ({ order, title, duration, modified, id: trackId, curriculumId, courseId, albumId, deleteTrack }) => {
  const goToTrackDetails = () => {
    navigate(`/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}/tracks/${trackId}`);
  };

  const openDelete = () => deleteTrack({ track: { id: trackId, title } });

  let lastUpdated;
  if (modified) lastUpdated = format(parseISO(modified), "MMM do, yyyy") + " at " + format(parseISO(modified), "p");

  return (
    <tr>
      <td>
        <div className="cell-contents">{order}</div>
      </td>
      <td>
        <div className="cell-contents">{title}</div>
      </td>
      <td>
        <div className="cell-contents">{formatMilliseconds(duration)}</div>
      </td>
      <td>
        <div className="cell-contents">{lastUpdated}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={goToTrackDetails}>
            View Details
          </div>
          <div className="action" onClick={openDelete}>
            Delete
          </div>
        </div>
      </td>
    </tr>
  );
};

const TracksTab = ({ curriculum, course, album, tracks, addTrack }) => {
  const { id: curriculumId } = curriculum || {};
  const { id: courseId } = course || {};
  const { id: albumId } = album || {};

  const { Modal: ConfirmDeleteModal, openModal: openConfirmDeleteModal } = useModal(ConfirmDeleteTrackModalBody, {
    album
  });

  const trackRows = tracks?.map(track => (
    <TrackRow {...{ ...track, curriculumId, courseId, albumId, deleteTrack: openConfirmDeleteModal }} key={track?.id} />
  ));
  const tracksTableContent = tracks?.length ? (
    <div className="table-wrapper tracks-table">
      <table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Title</th>
            <th>Duration</th>
            <th>Last Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{trackRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No tracks yet!</div>
      <Button action={addTrack} type="primary">
        Add Track
      </Button>
    </div>
  );

  return (
    <div className="tracks-tab">
      {tracksTableContent}
      <ConfirmDeleteModal />
    </div>
  );
};

export default observer(TracksTab);
