import React, { useState } from "react";
import {
  BarChart as RBarChart,
  Bar,
  Cell,
  LabelList,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import numeral from "numeral";
import { TOTAL_COLORS } from "../constants";
import {
  formatDateTickAtDay,
  formatDateTickAtMonth,
  formatTickAsCurrency,
  abbreviateTick,
  tooltipFormatter
} from "../formatters";
import "./BarChart.scss";

const defaultSort = (a, b) => (a > b ? 1 : -1);

function BarChart({
  data,
  currency,
  categorical,
  stack,
  legendPosition = "bottom",
  width = "100%",
  height = "100%",
  dateGrain = "month",
  onDrill = () => null,
  sortCells = defaultSort,
  colors
}) {
  const dateFormatter = dateGrain === "day" ? formatDateTickAtDay : formatDateTickAtMonth;
  const dataSeries = Array.from(new Set(data?.map(({ name, ...series }) => Object.keys(series))?.flat()));

  const [focusedBarIndex, setFocusedBarIndex] = useState(null);
  const updateFocusedBarIndex = state => {
    if (state.isTooltipActive) {
      setFocusedBarIndex(state.activeTooltipIndex);
    } else {
      setFocusedBarIndex(null);
    }
  };
  const unsetFocusedBarIndex = () => setFocusedBarIndex(null);

  const [hoveredLegendEntry, setHoveredLegendEntry] = useState(null);
  const updateHoveredLegendEntry = ({ dataKey }) => setHoveredLegendEntry(dataKey);
  const unsetHoveredLegendEntry = () => setHoveredLegendEntry(null);

  const onBarClick = ({ payload }) => onDrill(payload);

  if (!dataSeries?.length) return <div className="bar-chart no-data">(no data)</div>;

  let legendProps = {};
  if (legendPosition === "right") {
    legendProps = { layout: "vertical", verticalAlign: "middle", align: "right", wrapperStyle: { paddingLeft: 16 } };
  } else if (legendPosition === "left") {
    legendProps = { layout: "vertical", verticalAlign: "middle", align: "left", wrapperStyle: { paddingRight: 16 } };
  }
  const legend =
    dataSeries?.length > 1 ? (
      <Legend {...legendProps} onMouseEnter={updateHoveredLegendEntry} onMouseLeave={unsetHoveredLegendEntry} />
    ) : null;

  const formatTooltipTitle = (d, values) => {
    const total = values?.reduce((acc, next) => acc + (next?.value || 0), 0);
    const totalLabel = numeral(total)?.format(currency ? "($0,0.00)" : "(0,0)");
    return dateFormatter(d) + " : " + totalLabel + " IN TOTAL";
  };

  const bars = dataSeries?.sort(sortCells)?.map((dataKey, i) => {
    const colorIndex = colors ? i % colors?.length : (i % TOTAL_COLORS) + 1;
    const color = colors ? colors?.[colorIndex] : `var(--chart-${colorIndex})`;

    const legendEntryHovered = hoveredLegendEntry === dataKey;
    const anyLegendEntryHovered = hoveredLegendEntry != null;

    const pointerAndOpacityStyles = {
      cursor: "pointer",
      ...(anyLegendEntryHovered && !legendEntryHovered ? { opacity: 0.1 } : {})
    };

    const dataForCells = data?.map(datum => datum[dataKey]);
    const cells = dataForCells?.map((_, j) => {
      const isFocused = focusedBarIndex === j;
      const anyFocused = focusedBarIndex != null;
      const opacity = !isFocused && anyFocused ? 0.3 : 1;
      return <Cell key={`cell-${i}-${j}`} opacity={opacity} style={pointerAndOpacityStyles} />;
    });
    const labels = legendEntryHovered ? (
      <LabelList
        fill="var(--chart-gray)"
        dataKey={dataKey}
        position="top"
        formatter={d => numeral(d)?.format(currency ? "($0,0.00)" : "(0,0)")}
      />
    ) : null;

    return (
      <Bar
        onMouseLeave={unsetFocusedBarIndex}
        {...{ dataKey, stroke: color, fill: color }}
        style={pointerAndOpacityStyles}
        onClick={onBarClick}
        stackId={stack ? "stack" : null}
        key={dataKey}
      >
        {cells}
        {labels}
      </Bar>
    );
  });

  return (
    <ResponsiveContainer className="bar-chart" {...{ width, height }} debounce={1}>
      <RBarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
        onMouseMove={updateFocusedBarIndex}
        onMouseLeave={unsetFocusedBarIndex}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="var(--light-gray)" />
        <XAxis
          dataKey="name"
          tickLine={{ stroke: "var(--light-gray)" }}
          axisLine={{ stroke: "var(--light-gray)" }}
          tickFormatter={categorical ? null : dateFormatter}
        />
        <YAxis
          tickLine={{ stroke: "var(--light-gray)" }}
          axisLine={{ stroke: "var(--light-gray)" }}
          tickFormatter={currency ? formatTickAsCurrency : abbreviateTick}
        />
        <Tooltip
          cursor={{ fill: "none" }}
          labelFormatter={categorical ? null : formatTooltipTitle}
          formatter={tooltipFormatter({ categorical, currency })}
          isAnimationActive={false}
          labelStyle={{
            marginBottom: 4,
            color: "var(--gray)",
            fontSize: 14,
            fontWeight: "500",
            textTransform: "uppercase"
          }}
          itemStyle={{ marginLeft: 6, fontWeight: "500" }}
          contentStyle={{ border: "none", boxShadow: "var(--shadow)" }}
        />
        {legend}
        {bars}
      </RBarChart>
    </ResponsiveContainer>
  );
}

export default BarChart;
