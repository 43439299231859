import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import ClassesStore from "../../../../stores/ClassesStore";
import { Spinner, Button, PieTimer } from "../../../-common";
import "./ConfirmDeleteClassModal.scss";

function ConfirmDeleteClassModal({ class: classObject, close }) {
  const { userId: teacherId, id: classId, name } = classObject || {};

  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const enableDelete = () => setDeleteEnabled(true);

  const [deleting, setDeleting] = useState(false);

  const deleteClass = async () => {
    setDeleting(true);
    const success = await ClassesStore.deleteClassForTeacher(teacherId, classId);
    if (success) navigate(`/people/${teacherId}/classes`);
    setDeleting(false);
    close();
  };

  const cancelButtonStyles = deleting ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = deleting ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = deleting ? <Spinner /> : "Delete Class";
  const actionButton = deleteEnabled ? (
    <Button type="negative" action={deleteClass} style={actionButtonStyles}>
      {actionButtonContent}
    </Button>
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--red)" width={24} height={24} duration={3000} onComplete={enableDelete} />
    </div>
  );

  return (
    <div className="confirm-delete-class-modal">
      <div className="content">
        <div className="title">Are you sure you want to delete this {name} class?</div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">{actionButton}</div>
      </div>
    </div>
  );
}

export default observer(ConfirmDeleteClassModal);
