import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { format, parseISO } from "date-fns";
import { FiSearch } from "react-icons/fi";
import { downloadURLContents } from "../../../../utils";
import useModal from "../../../../hooks/useModal";
import FilesStore from "../../../../stores/FilesStore";
import { Select, Input, Spinner } from "../../../-common";
import UploadFileModalBody from "./UploadFileModal";
import "./FilesTab.scss";

const sortOptions = ["Newest", "Oldest", "A to Z", "Z to A"];

const FileRow = ({ lpmId, id, filename, assetURL, type, created }) => {
  const [deleting, setDeleting] = useState(false);

  const downloadFile = () => downloadURLContents(assetURL);
  const deleteFile = async () => {
    setDeleting(true);
    await FilesStore?.deleteFileForTeacher({ teacherLPMId: lpmId, teacherFileId: id });
    setDeleting(false);
  };

  const uploadDateLabel = format(parseISO(created), "MMM do, ''yy");

  const deletingStyles = deleting ? { opacity: 0.3, pointerEvents: "none" } : null;
  const actionsContent = deleting ? (
    <Spinner action />
  ) : (
    <>
      <div className="action" onClick={downloadFile}>
        Download
      </div>
      <div className="action" onClick={deleteFile}>
        Delete
      </div>
    </>
  );

  return (
    <tr style={deletingStyles}>
      <td>
        <div className="cell-contents">{filename}</div>
      </td>
      <td>
        <div className="cell-contents">{type || "-"}</div>
      </td>
      <td>
        <div className="cell-contents">{uploadDateLabel}</div>
      </td>
      <td>
        <div className="cell-contents">{actionsContent}</div>
      </td>
    </tr>
  );
};

const FilesTab = ({ user }) => {
  const { filesByTeacherId } = FilesStore || {};
  useEffect(() => {
    FilesStore.fetchFilesForTeacher(user?.id);
  }, []); //eslint-disable-line

  const { Modal: UploadFileModal, openModal: openUploadFileModal } = useModal(
    UploadFileModalBody,
    { user },
    { big: true }
  );

  // const loading = loadingFilesByTeacherId?.[user?.id] || false;
  const allFiles = filesByTeacherId?.[user?.id]?.slice() || [];

  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
  const updateSort = sortOption => setSelectedSortOption(sortOption);

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target.value);

  const files = allFiles
    ?.filter(file => {
      if (!searchText) return true;
      return file?.filename?.toLowerCase()?.includes(searchText?.toLowerCase());
    })
    ?.sort((a, b) => {
      if (selectedSortOption === "Newest") return a?.created > b?.created ? -1 : 1;
      if (selectedSortOption === "Oldest") return a?.created < b?.created ? -1 : 1;
      if (selectedSortOption === "A to Z") return a?.filename > b?.filename ? -1 : 1;
      if (selectedSortOption === "Z to A") return a?.filename < b?.filename ? -1 : 1;
      return 0;
    });

  const fileRows = files?.map(file => <FileRow lpmId={user?.id} {...file} key={file?.id || file?.fileId} />);
  const filesTableContent = files?.length ? (
    <div className="table-wrapper files-table">
      <table>
        <thead>
          <tr>
            <th>File</th>
            <th>Type</th>
            <th>Upload Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{fileRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no files)</div>
    </div>
  );

  return (
    <div className="files-tab">
      <div className="upper">
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action">
              <span onClick={openUploadFileModal}>Upload or Verify File</span>
            </div>
          </div>
        </div>
      </div>
      <div className="files-sort-search-row">
        <div className="files-sort">
          <div className="files-sort-label">Sort By:</div>
          <div className="files-sort-select">
            <Select options={sortOptions} value={selectedSortOption} onChange={updateSort} />
          </div>
        </div>
        <div className="files-search">
          <Input icon={FiSearch} placeholder="Search files..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      {filesTableContent}
      <UploadFileModal />
    </div>
  );
};

export default observer(FilesTab);
