import { observer } from "mobx-react";
import numeral from "numeral";
import AnalyticsStore from "src/stores/AnalyticsStore";
import { Spinner } from "src/components/-common";
import { BarChart, ChartWrapper, Collection, SummaryNumber } from "src/components/-common/-charts";
import EnrollmentTable from "../../EnrollmentTable";

function Online() {
  const { loadingCurriculum: loading, curriculum, currentAndUpcoming } = AnalyticsStore || {};
  const { Online: data, capacity: rawCapacity } = curriculum || {};
  const { Online: capacity } = rawCapacity || {};
  const { Online: tableData } = currentAndUpcoming || {};

  if (loading) {
    return (
      <Collection title="Online">
        <ChartWrapper width={12} height={2}>
          <div className="loading-container">
            <Spinner alt />
          </div>
        </ChartWrapper>
      </Collection>
    );
  }

  const totalEnrollments = data?.map(d => d?.Online || 0)?.reduce((acc, next) => acc + next, 0);
  const enrolledCapacity = numeral(totalEnrollments / capacity).format("0.0%");

  return (
    <Collection title="Online" rows={2}>
      <ChartWrapper width={4} height={1}>
        <div className="chart-title">Total Enrollments This Year</div>
        <SummaryNumber value={totalEnrollments} />
      </ChartWrapper>
      <ChartWrapper width={8} height={2}>
        <div className="chart-title">Enrollments for This Year by Registration Date</div>
        <BarChart data={data} colors={["var(--Online)"]} />
      </ChartWrapper>
      <ChartWrapper width={4} height={1}>
        <div className="chart-title">Enrolled Capacity</div>
        <SummaryNumber value={enrolledCapacity} text />
      </ChartWrapper>
      <ChartWrapper width={12} height={2}>
        <div className="chart-title">Current & Upcoming Enrollments</div>
        <EnrollmentTable data={tableData} color={["var(--Online)"]} />
      </ChartWrapper>
    </Collection>
  );
}

export default observer(Online);
