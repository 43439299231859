import { useState } from "react";
import { createPortal } from "react-dom";
import { FullScreenModal } from "../components/-common";
import usePortal from "./usePortal";

const camelToKebab = string => string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();

export default function useModal(ModalComponent, props = {}, { portalElementId, preventBackgroundClose, big } = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openFnProps, setOpenFnProps] = useState({});
  const openModal = (props = {}) => {
    const propsIsObject = props?.constructor?.name === "Object";
    if (propsIsObject) setOpenFnProps(props);
    else setOpenFnProps({});
    setIsOpen(true);
  };
  const closeModal = () => {
    setOpenFnProps({});
    setIsOpen(false);
  };

  const Modal = () => (
    <FullScreenModal big={big} isOpen={true} close={closeModal} preventBackgroundClose={preventBackgroundClose}>
      <ModalComponent {...{ close: closeModal, ...props, ...openFnProps }} />
    </FullScreenModal>
  );

  const modalComponentName = ModalComponent?.type?.displayName || "";
  const portalTarget = usePortal(portalElementId || camelToKebab(modalComponentName) || "modal-container");
  const contents = isOpen ? <Modal {...props} /> : null;
  const ModalContainer = () => createPortal(contents, portalTarget);

  return { isOpen, openModal, closeModal, Modal: ModalContainer };
}
