import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Spinner } from "../../../../../-common";
import EditScreen from "./EditScreen";
import ReviewScreen from "./ReviewScreen";
import "./EditMaterialsModal.scss";
import OrdersStore from "../../../../../../stores/OrdersStore";

const countMaterialsByProductId = (acc, next) => {
  acc[next?.product?.id] = (acc[next?.product?.id] || 0) + 1;
  return acc;
};

const checkForChangesToMaterials = (current, updated) => {
  const currentMaterialsSummary = current?.reduce(countMaterialsByProductId, {});
  const updatedMaterialsSummary = updated?.reduce(countMaterialsByProductId, {});
  return Object.entries(currentMaterialsSummary)?.every(
    ([productId, quantity]) => quantity !== updatedMaterialsSummary?.[productId]
  );
};

function EditMaterialsModal({ teacher, class: classItem, parent, materials: currentMaterials, courseId, close }) {
  const [loading, setLoading] = useState(false);
  const [inReview, setInReview] = useState(false);
  const toggleInReview = () => setInReview(!inReview);

  const [materials, setMaterials] = useState(currentMaterials);

  const materialsChanged = checkForChangesToMaterials(currentMaterials, materials);
  const everyMaterialHasAProduct = materials?.every(m => !!m?.productId && !m?.productId?.match(/^temp-/));
  const submitEnabled = materialsChanged && everyMaterialHasAProduct;

  const saveChanges = async () => {
    if (submitEnabled) {
      setLoading(true);
      const currentMaterialIds = currentMaterials?.map(m => m?.id);
      const updatedMaterialIds = materials?.map(m => m?.id);
      const orderItemAndOrderIdsToRemove = currentMaterials
        ?.filter(m => !updatedMaterialIds?.includes(m?.id))
        ?.map(({ orderId, id }) => ({ orderId, id }));
      const productAndOrderIdsToAdd = materials
        ?.filter(m => !currentMaterialIds?.includes(m?.id))
        ?.map(({ orderId, productId }) => ({ orderId, productId }));
      const params = { userId: parent?.id, teacherId: teacher?.id, classId: classItem?.id };

      await OrdersStore?.updateMaterials({ orderItemAndOrderIdsToRemove, productAndOrderIdsToAdd, params });
      setLoading(false);
      close();
    }
  };

  const content = inReview ? (
    <ReviewScreen {...{ currentMaterials, materials }} />
  ) : (
    <EditScreen {...{ materials, setMaterials, courseId }} />
  );

  const cancel = inReview ? toggleInReview : close;
  const cancelButtonContent = inReview ? "Back" : "Cancel";

  const action = inReview ? saveChanges : toggleInReview;
  const actionButtonContent = loading ? <Spinner /> : inReview ? "Save Changes" : "Review Changes";

  return (
    <div className="edit-materials-modal">
      {content}
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={cancel}>
            {cancelButtonContent}
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            action={action}
            disabled={!submitEnabled}
          >
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditMaterialsModal);
