import { makeAutoObservable, autorun } from "mobx";
import groupBy from "lodash.groupby";
import { format } from "date-fns";
import { ENV } from "../config";
import { downloadURLContents, request } from "../utils";
import { showErrorToast, showSuccessToast } from "../services/ToastService";
import AuthStore from "./AuthStore";

const filterOutShippingProducts = p => !p?.name?.toLowerCase()?.includes("shipping");
const filterOutLateFeeProducts = p => !p?.name?.toLowerCase()?.includes("fee - processing fee");
const filterOutTaxProducts = p => !p?.name?.toLowerCase()?.includes("tax");
const filterOutAlbumProducts = p => !p?.name?.toLowerCase()?.includes("album");

const collapseItemsToShipmentStatusParams = [
  (acc, next) => {
    if (acc === "late" || next) return "late";
    return next;
  },
  "reserved"
];
const addStatusLabelToShipments = shipment => {
  const todayLabel = format(new Date(), "yyyy-MM-dd");

  let statusLabel = "-";
  if (shipment?.requestedShippingDate === todayLabel) {
    statusLabel = "For Today";
  } else if (shipment?.status !== "late" && shipment?.requestedShippingDate < todayLabel) {
    statusLabel = "Past Due";
  } else if (shipment?.status === "late") {
    statusLabel = "Late Enrollment";
  }

  return { ...shipment, statusLabel };
};

class ShippingStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) {
        this.fetchShipmentsForToday();
        this.fetchUpcomingShipmentSchedule();
      } else this.clear();
    });
  }

  loading = false;
  shipmentDetailsLoading = {};

  rawShipments = [];
  totalReservedShipments = 0;
  totalReservedShipmentsForToday = 0;
  totalReservedShipmentsInThePast = 0;
  totalLateShipments = 0;
  totalBackorderedShipments = 0;

  rawShipmentSchedule = [];

  rawShipmentDetails = {};

  rawShipmentNotes = {};

  get shipments() {
    return this.rawShipments
      ?.map(({ teacher, ...shipment }) => ({
        ...shipment,
        teacher: {
          ...teacher,
          profilePicture: `https://${ENV}-lpm-assets.b-cdn.net/profiles/${teacher?.id}?m=${teacher?.modified}`
        }
      }))
      ?.map(addStatusLabelToShipments)
      ?.sort((a, b) => {
        return a?.requestedShippingDate > b?.requestedShippingDate ? 1 : -1;
      });
  }

  get shipmentsById() {
    return Object.fromValues(this.shipments?.map(s => [s?.id, s]));
  }

  get shipmentDetails() {
    const shipmentIdFormattedShipmentEntries = Object.entries(this.rawShipmentDetails)?.map(
      ([shipmentId, orderItems]) => {
        const notes = this.rawShipmentNotes?.[shipmentId];
        let { class: classObject, teacher, requestedShippingDate } = orderItems[0] || {};

        const orderItemProductGroups = Object.values(groupBy(orderItems, "product.id"));
        const items = orderItemProductGroups
          .map(oipg => {
            const product = oipg?.[0]?.product || {};
            const processedQuantity = oipg
              ?.filter(oi => oi?.status === "processed")
              ?.map(oi => Number(oi?.quantity) || "1")
              ?.reduce((acc, next) => acc + next, 0);
            const quantity = oipg?.map(oi => Number(oi?.quantity || "1"))?.reduce((acc, next) => acc + next, 0);
            const earliestRegistrationDate = oipg
              ?.map(item => item?.created)
              ?.sort((a, b) => (a < b ? -1 : 1))
              ?.slice(0)?.[0];
            const status = oipg?.map(item => item?.status)?.reduce(...collapseItemsToShipmentStatusParams);
            return {
              ...product,
              productPicture: `https://${ENV}-lpm-assets.b-cdn.net/products/${product?.id}?m=${product?.modified}`,
              items: oipg,
              quantity,
              processedQuantity,
              earliestRegistrationDate,
              status
            };
          })
          ?.filter(filterOutShippingProducts)
          ?.filter(filterOutLateFeeProducts)
          ?.filter(filterOutTaxProducts)
          ?.filter(filterOutAlbumProducts);

        teacher = {
          ...teacher,
          profilePicture: `https://${ENV}-lpm-assets.b-cdn.net/profiles/${teacher.id}?m=${teacher.modified}`
        };

        const earliestRegistrationDateForShipment = items
          ?.map(item => item?.earliestRegistrationDate)
          ?.sort((a, b) => (a < b ? -1 : 1))
          ?.slice(0)?.[0];

        const status = items?.map(item => item?.status)?.reduce(...collapseItemsToShipmentStatusParams);

        const formattedShipment = {
          id: shipmentId,
          requestedShippingDate,
          class: classObject,
          teacher,
          items,
          notes,
          earliestRegistrationDate: earliestRegistrationDateForShipment,
          status
        };

        return [shipmentId, formattedShipment];
      }
    );

    return Object.fromEntries(shipmentIdFormattedShipmentEntries);
  }

  loadingUpcomingShipmentsSchedule = false;

  rawUpcomingShipmentsSchedule = [];

  get upcomingShipmentsSchedule() {
    return this.rawUpcomingShipmentsSchedule?.map(scheduleItem => {
      const { product } = scheduleItem || {};
      return {
        ...scheduleItem,
        product: {
          ...(product || {}),
          productPicture: `https://${ENV}-lpm-assets.b-cdn.net/products/${product?.id}?m=${product?.modified}`
        }
      };
    });
  }

  async fetchShipmentsForToday() {
    try {
      this.loading = true;
      const info = await request.get(`/shipments`);
      const { shipments, reservedItemsForToday, reservedItemsPastDue, lateItems, backorderedItems } = info || {};
      this.totalReservedShipments = reservedItemsForToday + reservedItemsPastDue;
      this.totalReservedShipmentsForToday = reservedItemsForToday;
      this.totalReservedShipmentsInThePast = reservedItemsPastDue;
      this.totalLateShipments = lateItems;
      this.totalBackorderedShipments = backorderedItems;
      this.rawShipments = shipments;
      this.loading = false;
      return shipments;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async fetchUpcomingShipmentSchedule() {
    try {
      // this.loadingUpcomingShipmentsSchedule = true;
      // const schedule = await request.get(`/shipments/upcoming`);
      // this.rawUpcomingShipmentsSchedule = schedule;
      // this.loadingUpcomingShipmentsSchedule = false;
      // return schedule;
    } catch (err) {
      // this.loadingUpcomingShipmentsSchedule = false;
      console.warn(err);
    }
  }

  async fetchShipmentDetails(shipmentId) {
    try {
      this.shipmentDetailsLoading = { ...this.shipmentDetailsLoading, [shipmentId]: true };
      const { items: shipmentDetails, notes } = await request.get(`/shipments/${shipmentId}/details`);
      this.rawShipmentDetails = { ...this.rawShipmentDetails, [shipmentId]: shipmentDetails };
      this.rawShipmentNotes = { ...this.rawShipmentNotes, [shipmentId]: notes };
      this.shipmentDetailsLoading = { ...this.shipmentDetailsLoading, [shipmentId]: false };
      return shipmentDetails;
    } catch (err) {
      console.warn(err);
      this.shipmentDetailsLoading = { ...this.shipmentDetailsLoading, [shipmentId]: false };
    }
  }

  async markOrderItemsProcessed(orderItems) {
    const preRawShipments = this.rawShipments.slice();
    try {
      const processedOrderItemIds = orderItems?.map(oi => oi?.id);
      this.rawShipments = this.rawShipments?.map(s => {
        if (processedOrderItemIds?.includes(s?.id)) return { ...s, status: "processed" };
        return s;
      });
      const orderItemIdSets = orderItems?.map(({ id, user, orderId }) => ({ id, userId: user?.id, orderId }));
      await request.put(`/shipments/items`, {
        body: { orderItems: orderItemIdSets, params: { status: "processed" } }
      });
    } catch (err) {
      this.rawShipments = preRawShipments;
      console.warn(err);
    }
  }

  async markOrderItemsBackordered(orderItems) {
    const preRawShipments = this.rawShipments.slice();
    try {
      const backorderedOrderItemIds = orderItems?.map(oi => oi?.id);
      this.rawShipments = this.rawShipments?.map(s => {
        if (backorderedOrderItemIds?.includes(s?.id)) return { ...s, status: "backordered" };
        return s;
      });
      const orderItemIdSets = orderItems?.map(({ id, user, orderId }) => ({ id, userId: user?.id, orderId }));
      await request.put(`/shipments/items`, {
        body: { orderItems: orderItemIdSets, params: { status: "backordered" } }
      });
    } catch (err) {
      this.rawShipments = preRawShipments;
      console.warn(err);
    }
  }

  async downloadPackingSlipForShipment(shipmentId) {
    try {
      const { downloadURL } = await request.get(`/shipments/${shipmentId}/packingslip`);
      downloadURLContents(downloadURL);
    } catch (err) {
      console.warn(err);
      showErrorToast("Error downloading packing slip.");
    }
  }

  async downloadPackingSlipForShipments(shipmentIds) {
    try {
      const { downloadURL } = await request.post(`/shipments/packingslip`, { body: { shipmentIds } });
      downloadURLContents(downloadURL);
    } catch (err) {
      console.warn(err);
      showErrorToast("Error downloading packing slip.");
    }
  }

  async changeShipDateForShipmentId(shipmentId, newShipByDate) {
    try {
      await request.put(`/shipments/${shipmentId}/updateshipdate`, { body: { newShipByDate } });
      await Promise.all([this.fetchShipmentsForToday(), this.fetchUpcomingShipmentSchedule()]);
      showSuccessToast("Ship date updated for shipmentId");
      return true;
    } catch (err) {
      console.warn(err);
      showErrorToast("Error updating shippingId ship date");
      return false;
    }
  }

  async changeShipDateForClass(teacherId, classId, newShipByDate) {
    try {
      await request.put(`/shipments/${classId}`, { body: { teacherId, newShipByDate } });
      await Promise.all([this.fetchShipmentsForToday(), this.fetchUpcomingShipmentSchedule()]);
      showSuccessToast("Ship date updated for class");
      return true;
    } catch (err) {
      console.warn(err);
      showErrorToast("Error updating class ship date");
      return false;
    }
  }

  async updateShipmentNotes({ shipmentId, notes }) {
    try {
      const { notes: updatedNotes } = await request.put(`/shipments/${shipmentId}/notes`, { body: { notes } });
      this.rawShipmentNotes = { ...this.rawShipmentNotes, [shipmentId]: updatedNotes };
      showSuccessToast("Notes updated for shipment");
      return true;
    } catch (err) {
      console.warn(err);
      showErrorToast("Error updating notes for shipment");
      return false;
    }
  }

  clear() {
    this.rawShipments = [];
    this.loading = false;
  }
}

export default new ShippingStore();
