import React from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { parseISO, format } from "date-fns";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { FiMoreVertical } from "react-icons/fi";
import useModal from "../../../../hooks/useModal";
import { Button, ConfirmDeletePlaylistModal as ConfirmDeletePlaylistModalBody } from "../../../-common";
import "./PlaylistsTab.scss";

const PlaylistRow = ({ id: playlistId, order, name, tracks, modified, curriculumId, courseId, albumId, sorting }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: playlistId,
    disabled: !sorting
  });
  const sortStyles = { transform: CSS.Transform.toString(transform), transition };

  const { Modal: ConfirmDeletePlaylistModal, openModal: openConfirmDeletePlaylistModal } = useModal(
    ConfirmDeletePlaylistModalBody,
    { playlist: { id: playlistId, name }, albumId },
    { portalElementId: "confirm-delete-playlist-modal" }
  );

  const goToPlaylistDetails = () => {
    navigate(`/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}/playlists/${playlistId}`);
  };

  let lastUpdated;
  if (modified) lastUpdated = format(parseISO(modified), "MMM do, yyyy") + " at " + format(parseISO(modified), "p");

  const sortingContentStyles = sorting ? { opacity: 0.2 } : {};

  const actions = sorting ? (
    <div {...attributes} {...listeners} className="drag-handle">
      <FiMoreVertical size={36} color="var(--orange)" />
    </div>
  ) : (
    <>
      <div className="action" onClick={goToPlaylistDetails}>
        View Details
      </div>
      <div className="action" onClick={openConfirmDeletePlaylistModal}>
        Remove
      </div>
    </>
  );

  return (
    <tr ref={setNodeRef} style={sortStyles}>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {order + 1}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {name}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {tracks?.length}
        </div>
      </td>
      <td>
        <div className="cell-contents" style={sortingContentStyles}>
          {lastUpdated}
        </div>
      </td>
      <td>
        <div className="cell-contents actions">{actions}</div>
      </td>
      <ConfirmDeletePlaylistModal />
    </tr>
  );
};

const PlaylistsTab = ({ curriculum, course, album, playlists, setPlaylists, sorting, addPlaylist }) => {
  const { id: curriculumId } = curriculum || {};
  const { id: courseId } = course || {};
  const { id: albumId } = album || {};

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPlaylists(items => {
        const oldIndex = items.findIndex(i => i?.id === active?.id);
        const newIndex = items.findIndex(i => i?.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const playlistRows = playlists?.map(playlist => (
    <PlaylistRow {...{ ...playlist, curriculumId, courseId, albumId, sorting }} key={playlist?.id} />
  ));
  const playlistsTableContent = playlists?.length ? (
    <div className="table-wrapper playlists-table">
      <table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Title</th>
            <th>Total Tracks</th>
            <th>Last Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext items={playlists} strategy={verticalListSortingStrategy}>
              {playlistRows}
            </SortableContext>
          </DndContext>
        </tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No playlists yet!</div>
      <Button action={addPlaylist} type="primary">
        Add Playlist
      </Button>
    </div>
  );

  return <div className="playlists-tab">{playlistsTableContent}</div>;
};

export default observer(PlaylistsTab);
