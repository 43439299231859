import { useState } from "react";
import { observer } from "mobx-react";
import CreateAccount from "./CreateAccount";
import VerifyAccount from "./VerifyAccount";
import "./AddAccount.scss";
import { navigate } from "@reach/router";

const AddAccount = () => {
  const [accountCreationSuccessful, setAccountCreationSuccessful] = useState(false);
  const [accountVerificationSuccessful, setAccountVerificationSuccessful] = useState(false);
  const [newLPMId, setNewLPMId] = useState(false);

  if (accountVerificationSuccessful) {
    if (newLPMId) navigate(`/people/${newLPMId}`);
    else navigate(`/people`);
  }

  return (
    <div className="add-enrollment">
      <div className="title-row">
        <div className="title">Add Account</div>
      </div>
      <div className="add-enrollment-content-container">
        <div className={`left-col${accountCreationSuccessful ? " blocked" : ""}`}>
          <div className="header"> Step 1 - Create the account</div>
          <CreateAccount setAccountCreationSuccessful={setAccountCreationSuccessful} setNewLPMId={setNewLPMId} />
        </div>

        <div className={`right-col${accountCreationSuccessful ? "" : " blocked"}`}>
          <div className="header"> Step 2 - Verify the account</div>
          <VerifyAccount setAccountVerificationSuccessful={setAccountVerificationSuccessful} />
        </div>
      </div>
    </div>
  );
};

export default observer(AddAccount);
