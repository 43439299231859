import React from "react";
import { observer } from "mobx-react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { CHART_ROW_HEIGHT } from "../constants";
import AppStateStore from "../../../../stores/AppStateStore";
import "./Collection.scss";

function Collection({ collapsible = true, showTitle = true, title, rows = 1, filters, children }) {
  const collapsed = !!AppStateStore.localState?.collapsedAnalyticsCollections?.includes(title);
  const toggleCollapsed = () => {
    if (collapsed) AppStateStore.removeCollapsedAnalyticsCollection(title);
    else AppStateStore.addCollapsedAnalyticsCollection(title);
  };

  const Icon = collapsed ? BiChevronRight : BiChevronDown;

  const commonCollapsedStyles = {
    opacity: collapsed ? 0 : 1,
    display: collapsed ? "none" : null,
    visibility: collapsed ? "none" : null,
    pointerEvents: collapsed ? "none" : null
  };

  const titleComponent = showTitle ? (
    <div className="collection-title" onClick={collapsible ? toggleCollapsed : null}>
      <span>{title}</span>
      <Icon size={32} />
    </div>
  ) : null;

  const filtersContainerStyles = collapsed ? commonCollapsedStyles : {};
  const filtersContainer = filters ? (
    <div className="filters-container" style={filtersContainerStyles}>
      {filters}
    </div>
  ) : null;

  const chartsContainerStyles = {
    gridTemplateRows: `repeat(${rows}, 1fr)`,
    height: collapsed ? 0 : rows * CHART_ROW_HEIGHT,
    ...commonCollapsedStyles
  };

  return (
    <div className="collection">
      {titleComponent}
      {filtersContainer}
      <div className="collection-charts-container" style={chartsContainerStyles}>
        {children}
      </div>
    </div>
  );
}

export default observer(Collection);
