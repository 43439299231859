import React, { useState } from "react";
import { observer } from "mobx-react";
import ShippingStore from "../../../../stores/ShippingStore";
import { Spinner, Button, TextArea } from "../../../-common";
import "./AddShipmentNotesModal.scss";

const MAX_NOTES_LENGTH = 400;

function AddShipmentNotesModal({ shipment, close }) {
  const { id: shipmentId, notes: currentNotes } = shipment || {};

  const [saving, setSaving] = useState(false);

  const [notes, setNotes] = useState(currentNotes);
  const updateNotes = ({ target }) => setNotes(target?.value?.slice(0, MAX_NOTES_LENGTH));

  const save = async () => {
    setSaving(true);
    await ShippingStore.updateShipmentNotes({ shipmentId, notes });
    setSaving(false);
    close();
  };

  const cancelButtonStyles = saving ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = saving ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = saving ? <Spinner /> : "Save";

  return (
    <div className="add-shipment-notes-modal">
      <div className="content">
        <div className="title">Add/Edit Shipment Notes</div>
        <div className="main-content-wrapper">
          <div className="section-container">
            <div className="section-title">Notes</div>
            <TextArea placeholder="Add notes here..." value={notes} onChange={updateNotes} />
            <div className="notes-length">
              {notes?.length || 0} / {MAX_NOTES_LENGTH}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={save} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddShipmentNotesModal);
