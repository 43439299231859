import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Link, navigate } from "@reach/router";
import { FiChevronRight } from "react-icons/fi";
import useCurriculum from "../../../hooks/useCurriculum";
import useCourse from "../../../hooks/useCourse";
import useModal from "../../../hooks/useModal";
import useTabs from "../../../hooks/useTabs";
import { Spinner } from "../../-common";
import AlbumsTab from "./AlbumsTab";
import ProductsTab from "./ProductsTab";
import BundlesTab from "./BundlesTab";
import ActivityTab from "./ActivityTab";
import AddModal from "./AddAlbumModal";
import AddCourseProductModalBody from "./AddCourseProductModal";
import AddBundleModalBody from "./AddBundleModal";
import DeleteModal from "./ConfirmDeleteCourseModal";
import "./CourseDetails.scss";
import MaterialsStore from "src/stores/MaterialsStore";

const sections = [
  { title: "Albums", component: AlbumsTab },
  { title: "Products", component: ProductsTab },
  { title: "Bundles", component: BundlesTab },
  { title: "Activity", component: ActivityTab }
];

const ZeroState = () => (
  <div className="course-details">
    <div className="title-row">
      <div className="title">
        <Link to="/curriculum">Curriculum</Link>
      </div>
    </div>
    <div className="loading-container">
      <Spinner alt />
    </div>
  </div>
);

const Tab = ({ index, title, select, activeTabIndex }) => (
  <div className={`tab${index === activeTabIndex ? " active" : ""}`} onClick={select}>
    {title}
  </div>
);

function CourseDetails({ curriculumId, courseId }) {
  const { loading: curriculumLoading, curriculum } = useCurriculum(curriculumId);
  const { loading: courseLoading, course } = useCourse(courseId, { curriculumId });
  const { title: curriculumTitle } = curriculum || {};
  const { albums, name } = course || {};

  const { activeTabIndex, tabKeys } = useTabs(sections);
  const selectTab = index => () => navigate(`/curriculum/${curriculumId}/courses/${courseId}?tab=${tabKeys[index]}`);

  const { Modal: AddAlbumModal, openModal: openAddAlbumModal } = useModal(
    AddModal,
    { curriculum, course },
    { portalElementId: "add-course-modal" }
  );

  const { Modal: AddBundleModal, openModal: openAddBundleModal } = useModal(
    AddBundleModalBody,
    { courseId },
    { portalElementId: "add-bundle-modal" }
  );

  const { Modal: AddCourseProductModal, openModal: openAddCourseProductModal } = useModal(
    AddCourseProductModalBody,
    { courseId },
    { portalElementId: "add-course-product-modal" }
  );

  const { Modal: ConfirmDeleteModal, openModal: openConfirmDeleteModal } = useModal(DeleteModal, {
    curriculum,
    course
  });

  const { allProductsByCourseId } = MaterialsStore || {};

  const [products, setProducts] = useState([]);
  useEffect(() => {
    const currentProducts = allProductsByCourseId?.[courseId] || [];
    setProducts(currentProducts || []);
  }, [allProductsByCourseId, courseId]);

  const [sortingProducts, setSortingProducts] = useState(false);
  const sortProducts = () => setSortingProducts(true);
  const saveProductSort = async () => {
    const productUpdates = products?.map((b, i) => ({ ...b, order: i }));
    await MaterialsStore.updateCourseProductsSort({ courseId, products: productUpdates });
    setSortingProducts(false);
  };
  const cancelProductSort = () => {
    setProducts(allProductsByCourseId?.[courseId] || []);
    setSortingProducts(false);
  };

  if (curriculumLoading || courseLoading) return <ZeroState />;

  const totalAlbums = albums?.length || 0;
  const totalTracks = albums?.reduce((acc, next) => acc + (next?.stats?.totalTracks || 0), 0) || 0;

  const tabBarStyles = { gridTemplateColumns: `repeat(${sections.length}, 1fr)` };
  const tabs = sections?.map(({ title }, index) => (
    <Tab {...{ index, title, select: selectTab(index), activeTabIndex }} key={title} />
  ));
  const activeSection = sections?.[activeTabIndex];
  const ActiveSection = activeSection?.component || (() => null);

  let tabActions;
  if (activeSection?.title === "Albums") {
    tabActions = (
      <>
        <div className="action-row">
          <div className="action" onClick={openAddAlbumModal}>
            <span>Add Album</span>
          </div>
        </div>
        <div className="action-row">
          <div className="action">
            <span>Reorder Albums</span>
          </div>
        </div>
        <div className="action-row">
          <div className="action" onClick={openConfirmDeleteModal}>
            <span>
              Delete {name} (from {curriculumTitle})
            </span>
          </div>
        </div>
      </>
    );
  } else if (activeSection?.title === "Products") {
    tabActions = sortingProducts ? (
      <>
        <div className="action-row">
          <div className="action" onClick={saveProductSort}>
            <span>Save Sort Order</span>
          </div>
          <div className="action" onClick={cancelProductSort}>
            <span>Cancel</span>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="action-row">
          <div className="action" onClick={openAddCourseProductModal}>
            <span>Add Products</span>
          </div>
        </div>
        <div className="action-row">
          <div className="action" onClick={sortProducts}>
            <span>Reorder Products</span>
          </div>
        </div>
      </>
    );
  } else if (activeSection?.title === "Bundles") {
    tabActions = (
      <>
        <div className="action-row">
          <div className="action" onClick={openAddBundleModal}>
            <span>Add Bundle</span>
          </div>
        </div>
        <div className="action-row">
          <div className="action">
            <span>Reorder Bundles</span>
          </div>
        </div>
      </>
    );
  }

  const addProps = { openAddAlbumModal, openAddCourseProductModal };
  const productsTabProps = { products, setProducts, sorting: sortingProducts };
  const activeSectionProps = { curriculum, course, albums, products, ...addProps, ...productsTabProps };

  return (
    <div className="course-details">
      <div className="title-row">
        <div className="title">
          <Link to="/curriculum">Curriculum</Link> <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}`}>{curriculumTitle}</Link> <FiChevronRight className="arrow-icon" />
          <span>{name}</span>
        </div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Albums</div>
            <div className="info-value">{totalAlbums}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Tracks</div>
            <div className="info-value">{totalTracks}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          {tabActions}
        </div>
      </div>
      <div className="tabs-container">
        <div className="tab-bar" style={tabBarStyles}>
          {tabs}
        </div>
        <div className="active-tab-section">
          <ActiveSection {...activeSectionProps} />
        </div>
      </div>
      <AddAlbumModal />
      <AddBundleModal />
      <ConfirmDeleteModal />
      <AddCourseProductModal />
    </div>
  );
}

export default observer(CourseDetails);
