import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import MaterialsStore from "../../../../../../stores/MaterialsStore";
import CurriculumStore from "../../../../../../stores/CurriculumStore";
import RegistrationsStore from "../../../../../../stores/RegistrationsStore";
import { Button, Spinner, TeacherSearchInput, Select, MultiSelect, ClassOption } from "../../../../../-common";
import "./ReassignStudentsModal.scss";

const sortClassOptions = (a, b) => {
  const aCurriculumOrder = CurriculumStore?.curriculaByCourseId?.[a?.courseId]?.order;
  const bCurriculumOrder = CurriculumStore?.curriculaByCourseId?.[b?.courseId]?.order;
  if (aCurriculumOrder !== bCurriculumOrder) {
    return aCurriculumOrder > bCurriculumOrder ? 1 : -1;
  }

  const aCourseOrder = CurriculumStore?.coursesById?.[a?.courseId]?.order;
  const bCourseOrder = CurriculumStore?.coursesById?.[b?.courseId]?.order;
  if (aCourseOrder !== bCourseOrder) {
    return aCourseOrder > bCourseOrder ? 1 : -1;
  }

  if (a?.dayOfWeek !== b?.dayOfWeek) {
    return a?.dayOfWeek > b?.dayOfWeek ? 1 : -1;
  }

  return a?.name > b?.name ? 1 : -1;
};

const getClassOptionLabel = ({ value }) => `${value.name}`;
const getStudentOptionLabel = ({ value }) => {
  if (value?.id === "Select All") return "Select All";
  return `${value?.firstName} ${value?.lastName || ""}`;
};
const getMaterialOptionLabel = ({ value }) => {
  if (value?.productId === "Select All") return "Select All";
  const product = MaterialsStore?.productsById?.[value?.productId];
  return product?.name;
};

function ReassignStudentsModal({ teacher, class: currentClass, parent, students, materials, close }) {
  const [loading, setLoading] = useState(false);

  const [selectedTeacher, setSelectedTeacher] = useState();

  const [selectedClass, setSelectedClass] = useState();
  useEffect(() => {
    setSelectedClass();
  }, [selectedTeacher]);

  const [studentsToReassign, setStudentsToReassign] = useState([]);
  const updateStudentsToReassign = updatedStudents => {
    if (updatedStudents?.find(us => us?.id === "Select All")) {
      setStudentsToReassign(students);
    } else {
      setStudentsToReassign(updatedStudents);
    }
  };
  useEffect(() => {
    if (students?.length === 1) setStudentsToReassign(students);
  }, [students]);

  const [itemsToTransfer, setItemsToTransfer] = useState([]);
  const updateItemsToTransfer = updatedItems => {
    if (updatedItems?.find(um => um?.productId === "Select All")) {
      setItemsToTransfer(materials);
    } else {
      setItemsToTransfer(updatedItems);
    }
  };

  const relevantClasses = (selectedTeacher?.classes || [])
    ?.filter(cu => cu.id !== currentClass?.id)
    ?.sort(sortClassOptions)
    ?.map((c, i) => ({ ...c, isFirst: i === 0 }));

  const processReassignment = async () => {
    setLoading(true);
    const isCourseChange = currentClass?.courseId !== selectedClass?.courseId;
    const listOfItemsToTransfer = isCourseChange ? materials : itemsToTransfer;
    const preppedItemsToTransfer = listOfItemsToTransfer?.map(({ userId, orderId, id }) => ({ userId, orderId, id }));
    await RegistrationsStore.reassignStudents({
      oldTeacherId: teacher?.id,
      oldClassId: currentClass?.id,
      newTeacherId: selectedTeacher?.id,
      newClassId: selectedClass?.id,
      parentId: parent?.id,
      studentIds: studentsToReassign?.map(s => s?.id),
      isCourseChange,
      itemsToTransfer: preppedItemsToTransfer
    });
    close();
    setLoading(false);
  };

  let studentOptions = [];
  if (students?.length) studentOptions = students;
  if (studentsToReassign?.length !== students?.length) {
    studentOptions = [{ id: "Select All" }]?.concat(studentOptions);
  }

  let materialsOptions = [];
  if (materials?.length) materialsOptions = materials;
  if (itemsToTransfer?.length !== materials?.length) {
    materialsOptions = [{ productId: "Select All" }]?.concat(materialsOptions);
  }

  const studentsSection =
    students?.length !== 1 ? (
      <MultiSelect
        placeholder="Select Students to Reassign"
        options={studentOptions}
        value={studentsToReassign}
        onChange={updateStudentsToReassign}
        formatOptionLabel={getStudentOptionLabel}
        isDisabled={students?.length === 1}
      />
    ) : (
      <div className="student-name">
        {students?.[0]?.firstName} {students?.[0]?.lastName}
      </div>
    );

  const materialsSection = materials?.length ? (
    <div className="section materials-to-transfer">
      <div className="section-header">MATERIALS TO TRANSFER</div>
      <MultiSelect
        placeholder="Select Materials to Transfer"
        options={materialsOptions}
        value={itemsToTransfer}
        onChange={updateItemsToTransfer}
        formatOptionLabel={getMaterialOptionLabel}
        maxMenuHeight={160}
      />
    </div>
  ) : null;

  const submitDisabled = false;
  const sendButtonStyles = loading ? { opacity: 0.3, pointerEvents: "none" } : {};
  const sendButtonContent = loading ? <Spinner /> : "Reassign";

  return (
    <div className="reassign-student-modal">
      <div className="content">
        <div className="title">Reassign Student</div>
        <div className="columns">
          <div className="col left">
            <div className="section">
              <div className="section-header">Teacher to Reassign to</div>
              <TeacherSearchInput value={selectedTeacher} onChange={setSelectedTeacher} />
            </div>
            <div className="section">
              <div className="section-header">New Class</div>
              <Select
                options={relevantClasses}
                value={selectedClass}
                onChange={setSelectedClass}
                components={{ Option: ClassOption }}
                formatOptionLabel={getClassOptionLabel}
                maxMenuHeight={220}
              />
            </div>
          </div>
          <div className="col right">
            <div className="section students-to-drop">
              <div className="section-header">Student{students?.length !== 1 ? "s" : ""} to Reassign</div>
              {studentsSection}
            </div>
            {materialsSection}
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            style={sendButtonStyles}
            action={processReassignment}
            disabled={submitDisabled}
          >
            {sendButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(ReassignStudentsModal);
