import { observer } from "mobx-react";
import numeral from "numeral";
import AnalyticsStore from "src/stores/AnalyticsStore";
import { Spinner } from "src/components/-common";
import { BarChart, ChartWrapper, Collection, SummaryNumber } from "src/components/-common/-charts";
import sortByCurriculum from "../sortByCurriculum";

function Overall() {
  const { loadingOverall: loading, overall } = AnalyticsStore || {};
  const { data, totalStudents, totalTeachers } = overall || {};

  if (loading) {
    return (
      <Collection title="Overall">
        <ChartWrapper width={12} height={2}>
          <div className="loading-container">
            <Spinner alt />
          </div>
        </ChartWrapper>
      </Collection>
    );
  }

  return (
    <Collection title="Overall">
      <ChartWrapper width={4} height={1}>
        <div className="chart-title">Total Students This Year</div>
        <SummaryNumber value={numeral(totalStudents).format("0,0")} />
      </ChartWrapper>
      <ChartWrapper width={8} height={2}>
        <div className="chart-title">Enrollment Trend</div>
        <BarChart
          data={data}
          categorical
          colors={["var(--Sound-Beginnings)", "var(--Lets-Play-Music)", "var(--Presto)", "var(--Online)"]}
          sortCells={sortByCurriculum}
        />
      </ChartWrapper>
      <ChartWrapper width={4} height={1}>
        <div className="chart-title">Total Teachers This Year</div>
        <SummaryNumber value={numeral(totalTeachers).format("0,0")} />
      </ChartWrapper>
    </Collection>
  );
}

export default observer(Overall);
