import React, { useRef } from "react";
import AudioPlayer from "react-audio-player";
import "./AudioFileSelect.scss";

const FileSelect = ({ size = 400, filePreviewURL, updateFile }) => {
  const fileInputRef = useRef();

  const showFileSelector = () => fileInputRef?.current?.click();

  if (filePreviewURL) {
    return (
      <div className="audio-preview-wrapper">
        <AudioPlayer src={filePreviewURL} controls />
      </div>
    );
  }

  return (
    <>
      <div className="file-upload-zone" style={{ height: size, width: size }} onClick={showFileSelector}>
        <div className="file-upload-zone-inner">
          <div className="file-upload-zone-label">Click to upload</div>
        </div>
      </div>
      <input ref={fileInputRef} type="file" accept="audio/mpeg" className="hidden-file-input" onChange={updateFile} />
    </>
  );
};

export default FileSelect;
