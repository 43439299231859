import React from "react";
import { observer } from "mobx-react";
import { Link } from "@reach/router";
import useModal from "../../../../hooks/useModal";
import { Button } from "../../../-common";
import AddChildModalBody from "./AddChildModal";
import "./ConnectionsTab.scss";

const relationshipSectionSortOrder = {
  parent: 1,
  child: 2,
  sibling: 3
};

const relationshipSectionNames = {
  parent: "Parents",
  teacher: "Teachers",
  child: "Children",
  sibling: "Siblings",
  student: "Students"
};

const relationshipColors = {
  parent: "var(--purple)",
  teacher: "var(--blue)",
  child: "var(--orange)",
  sibling: "var(--orange)",
  student: "var(--yellow)"
};

const RelationshipItem = ({ relationship, firstName, lastName, infusionsoftId, id: lpmId, birthdayText }) => {
  const color = relationshipColors?.[relationship] || "var(--dark-primary)";
  const birthdayValue = birthdayText ? (
    <div className="relationship-item-detail-value" style={{ color }}>
      {birthdayText}
    </div>
  ) : (
    <div className="relationship-item-detail-value" style={{ color: "var(--medium-gray)" }}>
      (none listed)
    </div>
  );
  return (
    <Link to={`/people/${lpmId}`}>
      <div className="relationship-item">
        <div className="relationship-item-name" style={{ color }}>
          {firstName} {lastName}
        </div>
        <div className="relationship-item-detail">
          <div className="relationship-item-detail-title">InfusionSoft ID</div>
          <div className="relationship-item-detail-value" style={{ color }}>
            {infusionsoftId}
          </div>
        </div>
        <div className="relationship-item-detail">
          <div className="relationship-item-detail-title">LPM ID</div>
          <div className="relationship-item-detail-value" style={{ color }}>
            {lpmId}
          </div>
        </div>
        <div className="relationship-item-detail">
          <div className="relationship-item-detail-title">Birthday</div>
          {birthdayValue}
        </div>
      </div>
    </Link>
  );
};

const RelationshipSection = ({ title, items, openAddChildModal }) => {
  const relationshipItems = items?.map(relationship => (
    <RelationshipItem {...relationship} key={relationship.firstName} />
  ));

  const addChildButton =
    title === "Children" ? (
      <div className="add-child-button-wrapper">
        <Button type="cta" action={openAddChildModal}>
          Add Child
        </Button>
      </div>
    ) : null;

  return (
    <>
      <div className="relationship-section-header">{title}</div>
      <div className="relationship-items">
        {relationshipItems}
        {addChildButton}
      </div>
    </>
  );
};

const ConnectionsTab = ({ user }) => {
  const { connectionsByRelationship } = user || {};

  const { Modal: AddChildModal, openModal: openAddChildModal } = useModal(
    AddChildModalBody,
    { parent: user },
    { portalElementId: "add-student-modal" }
  );

  const relationshipSections = Object.entries(connectionsByRelationship)
    ?.sort((a, b) => relationshipSectionSortOrder[a?.[0]] - relationshipSectionSortOrder[b?.[0]])
    ?.map(([relationshipType, items]) => [relationshipSectionNames[relationshipType], items])
    ?.map(([title, items]) => <RelationshipSection {...{ title, items, openAddChildModal }} key={title} />);

  return (
    <div className="connections-tab">
      {relationshipSections}
      <AddChildModal />
    </div>
  );
};

export default observer(ConnectionsTab);
