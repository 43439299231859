import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import { FiMinusCircle, FiPlus } from "react-icons/fi";
import usePortal from "../../../../hooks/usePortal";
import CurriculumStore from "../../../../stores/CurriculumStore";
import { FullScreenModal, Button, Input, Spinner } from "../../../-common";
import "./UpdateDetailsModal.scss";

const LearningPurposeRow = ({ text, update, remove }) => {
  return (
    <div className="learning-purpose-row">
      <Input value={text} onChange={update} />
      <FiMinusCircle size={36} stroke="var(--red)" onClick={remove} />
    </div>
  );
};

function UpdateDetailsModal({ album, track, close }) {
  const {
    title: currentTitle,
    principalPurposes: currentPrincipalPurposes,
    secondaryPurposes: currentSecondaryPurposes
  } = track || {};

  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState(currentTitle);
  const updateTitle = ({ target }) => setTitle(target.value);

  const [principalPurposes, setPrincipalPurposes] = useState(currentPrincipalPurposes);
  const addPrincipalPurpose = () => setPrincipalPurposes(principalPurposes?.concat(""));
  const updatePrincipalPurpose = i => ({ target }) => {
    setPrincipalPurposes(principalPurposes?.map((originalText, j) => (i === j ? target.value : originalText)));
  };
  const removePrincipalPurpose = i => () => {
    setPrincipalPurposes(principalPurposes?.slice(0, i)?.concat(principalPurposes?.slice(i + 1)));
  };

  const [secondaryPurposes, setSecondaryPurposes] = useState(currentSecondaryPurposes);
  const addSecondaryPurpose = () => setSecondaryPurposes(secondaryPurposes?.concat(""));
  const updateSecondaryPurpose = i => ({ target }) => {
    setSecondaryPurposes(secondaryPurposes?.map((originalText, j) => (i === j ? target.value : originalText)));
  };
  const removeSecondaryPurpose = i => () => {
    setSecondaryPurposes(secondaryPurposes?.slice(0, i)?.concat(secondaryPurposes?.slice(i + 1)));
  };

  const hasTitle = !!title;
  const titleChanged = title !== currentTitle;
  const principalPurposesChanged = !isEqual(currentPrincipalPurposes, principalPurposes);
  const secondaryPurposesChanged = !isEqual(currentSecondaryPurposes, secondaryPurposes);
  const changed = titleChanged || principalPurposesChanged || secondaryPurposesChanged;
  const noEmptyPurposes = principalPurposes?.every(Boolean) && secondaryPurposes?.every(Boolean);
  const updateTrackEnabled = hasTitle && changed && noEmptyPurposes;
  const updateTrack = async () => {
    if (updateTrackEnabled) {
      setLoading(true);
      const updates = { title, principalPurposes, secondaryPurposes };
      const success = await CurriculumStore?.updateTrack(album?.id, track?.id, updates);
      setLoading(false);
      if (success) close();
    }
  };

  const principalPurposeRows = principalPurposes?.map((text, i) => (
    <LearningPurposeRow text={text} update={updatePrincipalPurpose(i)} remove={removePrincipalPurpose(i)} />
  ));
  const secondaryPurposeRows = secondaryPurposes?.map((text, i) => (
    <LearningPurposeRow text={text} update={updateSecondaryPurpose(i)} remove={removeSecondaryPurpose(i)} />
  ));

  const cancelButtonStyles = loading ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = updateTrackEnabled
    ? loading
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = loading ? <Spinner /> : "Update Details";

  return (
    <FullScreenModal big={true} isOpen={true} close={close}>
      <div className="update-details-modal">
        <div className="content">
          <div className="title">Update Track Details</div>
          <div className="section-container columns">
            <div className="col">
              <div className="section-container">
                <div className="section-title">Title</div>
                <Input value={title} onChange={updateTitle} />
              </div>
            </div>
            <div className="col">
              <div className="section-container">
                <div className="section-title">Principal Learning Purposes</div>
                {principalPurposeRows}
                <div className="add-learning-purpose" onClick={addPrincipalPurpose}>
                  <FiPlus size={24} />
                  <span>Add Primary Learning Purpose</span>
                </div>
              </div>
              <div className="section-container">
                <div className="section-title">Secondary Learning Purposes</div>
                {secondaryPurposeRows}
                <div className="add-learning-purpose" onClick={addSecondaryPurpose}>
                  <FiPlus size={24} />
                  <span>Add Secondary Learning Purpose</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button action={close} style={cancelButtonStyles}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" action={updateTrack} style={actionButtonStyles}>
              {actionButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const ObservingModal = observer(UpdateDetailsModal);

const UpdateDetailsModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("update-details-modal-container");
  const contents = isOpen ? <ObservingModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default UpdateDetailsModalContainer;
