import React, { useState } from "react";
import { observer } from "mobx-react";
import MaterialsStore from "../../../../../stores/MaterialsStore";
import { Spinner, Button, PieTimer } from "../../../../-common";
import "./ConfirmDeleteBundleModal.scss";

function ConfirmDeleteBundleModal({ bundle, close }) {
  const { id: bundleId, courseId, name } = bundle || {};

  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const enableDelete = () => setDeleteEnabled(true);

  const [deleting, setDeleting] = useState(false);

  const deleteBundle = async () => {
    setDeleting(true);
    await MaterialsStore.deleteBundle(courseId, bundleId);
    close();
  };

  const cancelButtonStyles = deleting ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = deleting ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = deleting ? <Spinner /> : "Remove Bundle";
  const actionButton = deleteEnabled ? (
    <Button type="negative" action={deleteBundle} style={actionButtonStyles}>
      {actionButtonContent}
    </Button>
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--red)" width={24} height={24} duration={3000} onComplete={enableDelete} />
    </div>
  );

  return (
    <div className="confirm-delete-bundle-modal">
      <div className="content">
        <div className="title">Are you sure you want to remove {name}?</div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">{actionButton}</div>
      </div>
    </div>
  );
}

export default observer(ConfirmDeleteBundleModal);
