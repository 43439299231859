import React from "react";
import { observer } from "mobx-react";
import "./InfoTab.scss";

const InfoTab = ({ album }) => {
  const { artwork, title, credits } = album || {};

  return (
    <div className="playlist-info-tab">
      <div className="info-col">
        <div className="info-section-header">Artwork</div>
        <img className="artwork" src={artwork} alt={`Artwork for ${title} album`} />
      </div>
      <div className="info-col">
        <div className="info-section-header">Details</div>
        <div className="info-row">
          <div className="info-title">Title</div>
          <div className="info-value">{title}</div>
        </div>
        <div className="info-row">
          <div className="info-title">Credits</div>
          <div className={`info-value${credits ? "" : " empty"}`}>{credits || "(none listed)"}</div>
        </div>
      </div>
    </div>
  );
};

export default observer(InfoTab);
