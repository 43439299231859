import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import PeopleStore from "../stores/PeopleStore";

export default function usePerson(lpmId) {
  const { people } = PeopleStore;
  const [loading, setLoading] = useState(true);
  const [person, setPerson] = useState();
  useEffect(() => {
    const matchingPerson = people.find(p => p?.id === lpmId);
    if (matchingPerson) {
      setPerson(toJS(matchingPerson));
      setLoading(false);
    } else {
      if (lpmId) {
        setPerson();
        (async () => {
          setLoading(true);
          const person = await PeopleStore.fetchPersonById(lpmId);
          if (!person) navigate("/people");
          setLoading(false);
        })();
      }
    }
  }, [people, lpmId]);
  return { person: person || {}, loading };
}
