import React, { useState } from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { FiDollarSign, FiType } from "react-icons/fi";
import { getDataURLFromFile } from "../../../utils";
import MaterialsStore from "../../../stores/MaterialsStore";
import { Spinner, Input, Button, ImageFileSelect } from "../../-common";
import "./AddProductModal.scss";

function AddProductModal({ close }) {
  const [adding, setAdding] = useState(false);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const [name, setName] = useState();
  const updateName = ({ target }) => setName(target?.value);

  const [price, setPrice] = useState();
  const updatePrice = ({ target }) => setPrice(target?.value);
  const removeCommasFromPrice = () => setPrice(price?.replace(/,/g, ""));
  const formatPrice = () => {
    const sanitizedPrice = price
      ?.replace(/[^0-9.]/g, "")
      ?.split(".")
      ?.slice(0, 2)
      ?.filter(Boolean)
      ?.map((v, i) => (i === 1 ? v?.slice(0, 2) : v))
      ?.join(".");
    const formattedPrice = price ? numeral(sanitizedPrice).format("0.00") : price;
    setPrice(formattedPrice);
  };

  const fieldsFilledOut = !!name && price != null && price !== "";
  const priceIsValid = price?.match(/[0-9]+.?/) && !isNaN(price);
  const updateEnabled = fieldsFilledOut && priceIsValid;
  const updateProduct = async () => {
    if (updateEnabled) {
      setAdding(true);
      const priceInCents = Math.round(Number(price) * 100);
      await MaterialsStore.addProduct({ file, name, price: priceInCents });
      setAdding(false);
      close();
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = updateEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Add Product";

  return (
    <div className="add-product-modal">
      <div className="content">
        <div className="title">Add Product</div>
        <div className="main-content-wrapper">
          <div className="col">
            <div className="section-container">
              <div className="section-title">Image</div>
              <div className="artwork-select-container">
                <ImageFileSelect {...{ filePreviewURL, updateFile }} />
              </div>
            </div>
          </div>
          <div className="col right">
            <div className="section-container">
              <div className="section-title">Name</div>
              <Input icon={FiType} placeholder="Product Name" value={name} onChange={updateName} />
            </div>
            <div className="section-container">
              <div className="section-title">Price</div>
              <Input
                icon={FiDollarSign}
                placeholder="Product Price"
                value={price}
                onChange={updatePrice}
                onFocus={removeCommasFromPrice}
                onBlur={formatPrice}
                pattern="[0-9.]*"
                inputMode="decimal"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={updateProduct} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddProductModal);
