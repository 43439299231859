import { makeAutoObservable, autorun } from "mobx";
import { request } from "../utils";
import AuthStore from "./AuthStore";

class UserStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) {
        this.fetchLoggedInUser();
      } else this.clear();
    });
  }

  loading = false;

  rawUser = {};

  get user() {
    if (!this?.rawUser) return null;

    return {
      ...this.rawUser,
      profileDetails: {
        ...(this?.rawUser?.profileDetails || {}),
        project: {
          ...(this?.rawUser?.profileDetails?.project || {}),
          startDate: new Date(this?.rawUser?.profileDetails?.project?.startDate)
        }
      }
    };
  }

  async fetchLoggedInUser() {
    this.loading = true;
    try {
      const user = await request.get(`/users/me`);
      this.rawUser = user;
      this.loading = false;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async updateUser(updates) {
    if (!this?.user?.id) return;

    this.loading = true;
    try {
      const user = await request.put(`/users/${this.user.id}`, { body: updates });
      this.rawUser = user;
      this.loading = false;
      return user;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async updateProfilePicture(profilePicture) {
    if (this?.user?.id) {
      try {
        const fileData = await request.get(`/files/profile?userId=${this.user.id}`, {
          headers: { "file-type": profilePicture.type }
        });

        const { url } = fileData || {};
        const uploadResponse = await fetch(url, {
          method: "PUT",
          body: profilePicture,
          headers: { "Content-Type": profilePicture.type, "x-amz-acl": "public-read" }
        });
        if (!uploadResponse.ok) throw new Error("Upload failed.");

        await this.updateUser({ profilePictureUpdated: true });
      } catch (err) {
        console.warn("Error updating profile picture:", err);
        throw err;
      }
    }
  }

  clear() {
    this.loading = false;
    this.rawUser = null;
  }
}

export default new UserStore();
