import React, { useState } from "react";
import { observer } from "mobx-react";
import { getDataURLFromFile } from "../../../../utils";
import BannersStore, { audienceOptions, statusOptions } from "../../../../stores/BannersStore";
import { Spinner, Input, Select, Button, ImageFileSelect } from "../../../-common";
import "./EditBannerModal.scss";

function EditBannerModal({ banner, close }) {
  const {
    id: bannerId,
    media: currentMedia,
    url: currentURL,
    audience: currentAudience,
    status: currentStatus,
    order
  } = banner || {};
  const [updating, setUpdating] = useState(false);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState(currentMedia);
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const [url, setURL] = useState(currentURL);
  const updateURL = ({ target }) => setURL(target?.value);

  const [status, setStatus] = useState(currentStatus);
  const [audience, setAudience] = useState(currentAudience);

  const updateEnabled = !!file || url !== currentURL || status !== currentStatus || audience !== currentAudience;
  const updateBanner = async () => {
    if (updateEnabled) {
      setUpdating(true);
      const newBanner = await BannersStore.updateBanner(bannerId, { file, url, audience, status, order });
      setUpdating(false);
      if (newBanner?.id) close();
    }
  };

  const cancelButtonStyles = updating ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = updateEnabled
    ? updating
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = updating ? <Spinner /> : "Update Banner";

  return (
    <div className="edit-banner-modal">
      <div className="content">
        <div className="title">Update Banner</div>
        <div className="main-content-wrapper">
          <div className="col">
            <div className="section-container">
              <div className="section-title">Artwork</div>
              <div className="artwork-select-container">
                <ImageFileSelect {...{ filePreviewURL, updateFile }} />
              </div>
            </div>
          </div>
          <div className="col right">
            <div className="section-container">
              <div className="section-title">URL</div>
              <Input
                placeholder="Where should tapping on this banner take the user?"
                value={url}
                onChange={updateURL}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Audience</div>
              <Select
                placeholder="Who should be able to see this banner?"
                options={audienceOptions}
                value={audience}
                onChange={setAudience}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Status</div>
              <Select
                placeholder="Should we show this banner immediately?"
                options={statusOptions}
                value={status}
                onChange={setStatus}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={updateBanner} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditBannerModal);
