import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import useModal from "../../../../hooks/useModal";
import PeopleStore from "../../../../stores/PeopleStore";
import ClassesStore from "../../../../stores/ClassesStore";
import { Spinner, Checkbox } from "../../../-common";
import CreateAccountModalBody from "./CreateAccountModal";
import EditShippingDatesModalBody from "./EditShippingDatesModal";
import ChangePasswordModalBody from "./ChangePasswordModal";
import ChangeEmailModalBody from "./ChangeEmailModal";
import ChangeInfusionsoftIdModalBody from "./ChangeInfusionsoftIdModal";
import MergeAccountModalBody from "./MergeAccountModal";
import UpdateStudioLocationModalBody from "./UpdateStudioLocationModal";
import ViewStudioLocationOnMapModalBody from "./ViewStudioLocationOnMapModal";
import "./AccountTab.scss";

const AccountTab = ({ user }) => {
  const { id, addresses, infusionsoftId, cognito, teacher, teacherProfile } = user || {};
  const {
    cognitoEnabled,
    enabled,
    email_verified,
    emailVerified,
    cognitoUserStatus,
    userStatus,
    email,
    preferred_username
  } = cognito || {};
  const { locations } = teacherProfile || {};

  const [loading, setLoading] = useState(false);

  const { Modal: CreateAccountModal, openModal: openCreateAccountModal } = useModal(
    CreateAccountModalBody,
    { user },
    { portalElementId: "create-account-modal" }
  );

  const { Modal: EditShippingDatesModal, openModal: openEditShippingDatesModal } = useModal(
    EditShippingDatesModalBody,
    { user },
    { portalElementId: "edit-shipping-dates-modal" }
  );

  const { Modal: ChangePasswordModal, openModal: openChangePasswordModal } = useModal(
    ChangePasswordModalBody,
    { user },
    { portalElementId: "change-password-modal" }
  );

  const { Modal: ChangeEmailModal, openModal: openChangeEmailModal } = useModal(
    ChangeEmailModalBody,
    { user },
    { portalElementId: "change-email-modal" }
  );

  const { Modal: ChangeInfusionsoftIdModal, openModal: openChangeInfusionsoftIdModal } = useModal(
    ChangeInfusionsoftIdModalBody,
    { user },
    { portalElementId: "change-infusionsoft-id-modal" }
  );

  const { Modal: MergeAccountModal, openModal: openMergeAccountModal } = useModal(
    MergeAccountModalBody,
    { user },
    { portalElementId: "merge-account-modal" }
  );

  const { Modal: UpdateStudioLocationModal, openModal: openUpdateStudioLocationModal } = useModal(
    UpdateStudioLocationModalBody,
    { user }
  );

  const { Modal: ViewStudioLocationOnMapModal, openModal: openViewStudioLocationOnMapModal } = useModal(
    ViewStudioLocationOnMapModalBody,
    { user }
  );

  const seeInInfusionsoft = () => {
    window.open(
      `https://playmusic.infusionsoft.com/Contact/manageContact.jsp?view=edit&ID=${infusionsoftId}`,
      "_blank"
    );
  };

  const sendEmail = () => window.open(`mailto:${email}`, "_blank");

  const syncWithInfusionsoft = async () => {
    setLoading(true);
    await PeopleStore?.syncUserWithInfusionsoft(user);
    setLoading(false);
  };

  const toggleStorefront = async () => {
    setLoading(true);
    await PeopleStore?.toggleUserStorefront(id);
    setLoading(false);
  };

  const toggleRestricted = async () => {
    setLoading(true);
    await PeopleStore?.toggleRestricted(id);
    setLoading(false);
  };

  const [downloading, setDownloading] = useState(false);
  const downloadFullRoster = async () => {
    setDownloading(true);
    await ClassesStore.downloadFullCurrentRosterForTeacher(id);
    setDownloading(false);
  };

  const loginEnabled =
    (cognitoEnabled || enabled) &&
    (email_verified || emailVerified) &&
    (cognitoUserStatus === "CONFIRMED" || userStatus === "CONFIRMED");

  const canLogInIcon = loginEnabled ? (
    <FiCheckCircle size={24} stroke="var(--green)" />
  ) : (
    <FiXCircle size={24} stroke="var(--red)" />
  );

  if (loading) {
    return (
      <div className="account-tab account-tab-loading">
        <Spinner alt />
      </div>
    );
  }

  const infusionsoftActions = infusionsoftId ? (
    <>
      <div className="info-row">
        <div className="info-action" onClick={seeInInfusionsoft}>
          <span>View in InfusionSoft</span>
        </div>
      </div>
      <div className="info-row">
        <div className="info-action" onClick={syncWithInfusionsoft}>
          <span>Sync with InfusionSoft</span>
        </div>
      </div>
    </>
  ) : null;

  const cognitoOnlyActions = loginEnabled ? (
    <>
      <div className="info-row">
        <div className="info-action" onClick={openChangePasswordModal}>
          <span>Change Password</span>
        </div>
      </div>
      <div className="info-row">
        <div className="info-action" onClick={openChangeEmailModal}>
          <span>Change Email Address</span>
        </div>
      </div>
      <div className="info-row">
        <div className="info-action" onClick={openChangeInfusionsoftIdModal}>
          <span>Change InfusionSoft ID</span>
        </div>
      </div>
    </>
  ) : null;

  const teacherActions = teacher ? (
    <div className="info-row">
      <div className="info-action" onClick={openEditShippingDatesModal}>
        <span>Edit Teacher Shipping Program</span>
      </div>
    </div>
  ) : null;

  const createAccountAction = !loginEnabled ? (
    <div className="info-row">
      <div className="info-action" onClick={openCreateAccountModal}>
        <span>Create Login Account</span>
      </div>
    </div>
  ) : null;

  const mergeAction = id ? (
    <div className="info-row">
      <div className="info-action" onClick={openMergeAccountModal}>
        <span>Merge Account</span>
      </div>
    </div>
  ) : null;

  const downloadButtonContent = downloading ? (
    <div className="action-loading-container">
      <Spinner action />
    </div>
  ) : (
    <span>Download Full Roster</span>
  );

  const downloadFullRosterAction = (
    <div className="info-row">
      <div className="info-action" onClick={downloadFullRoster}>
        {downloadButtonContent}
      </div>
    </div>
  );

  const studioLocationLines = locations
    ?.map((location, i, allLocations) => {
      const {
        name,
        line1,
        line2,
        locality: city,
        region: state,
        zipCode,
        latitude,
        longitude,
        geohash
      } = location || {};
      const isLast = i === allLocations?.length - 1;
      const updateLocation = () => openUpdateStudioLocationModal({ location });
      const viewLocation = () => openViewStudioLocationOnMapModal({ location });

      const addressLine2 = line2 ? (
        <div className="info-row cont" key={`${name}-line2`}>
          <div className="info-title" />
          <div className="info-value">{line2}</div>
        </div>
      ) : null;
      return [
        <div className="info-row" key={`${name}-title`}>
          <div className="info-title">{i === 0 ? "Studio Locations" : null}</div>
          <div className="info-value with-action">
            {name}
            <span className="actions">
              <span className="action" onClick={updateLocation}>
                Edit
              </span>
              <span className="action" onClick={viewLocation}>
                View on Map
              </span>
            </span>
          </div>
        </div>,
        <div className="info-row cont" key={`${name}-line1`}>
          <div className="info-title" />
          <div className="info-value">{line1}</div>
        </div>,
        addressLine2,
        <div className="info-row cont" key={`${name}-citystatezip`}>
          <div className="info-title" />
          <div className="info-value">
            {city}, {state} {zipCode}
          </div>
        </div>,
        <div className="info-row cont" key={`${name}-latlong`}>
          <div className="info-title" />
          <div className="info-value">
            {latitude}, {longitude} ({geohash})
          </div>
        </div>,
        isLast ? null : <div className="info-row separator" key={`${name}-separator`} />
      ]?.filter(Boolean);
    })
    .flat();

  const teacherInfoSection = teacher ? (
    <div className="info-section">
      <div className="info-section-header">Teacher Information</div>
      <div className="info-content">
        <div className="info-row">
          <div className="info-title">Hide Storefront</div>
          <div className="info-value">
            <Checkbox selected={user?.teacherProfile?.disabled} onChange={toggleStorefront} />
          </div>
        </div>
        <div className="info-row">
          <div className="info-title">Mark Restricted</div>
          <div className="info-value">
            <Checkbox selected={user?.restricted} onChange={toggleRestricted} />
          </div>
        </div>
        {studioLocationLines}
      </div>
    </div>
  ) : null;

  const addressLines = addresses
    ?.map((address, i, allLocations) => {
      const { field: name, line1, line2, locality: city, region: state, zipCode } = address || {};
      if (!line1) return null;
      const isLast = i === allLocations?.length - 1;
      const addressLine2 = line2 ? (
        <div className="info-row cont" key={`${name}-line2`}>
          <div className="info-title" />
          <div className="info-value">{line2}</div>
        </div>
      ) : null;
      return [
        <div className="info-row" key={`${name}-title`}>
          <div className="info-title">{name}</div>
          <div className="info-value">{line1}</div>
        </div>,
        addressLine2,
        <div className="info-row cont" key={`${name}-citystatezip`}>
          <div className="info-title" />
          <div className="info-value">
            {city}, {state} {zipCode}
          </div>
        </div>,

        isLast ? null : <div className="info-row separator" key={`${name}-separator`} />
      ]?.filter(Boolean);
    })
    .flat();

  const addressesSection = addresses?.length ? (
    <div className="info-section">
      <div className="info-section-header">Infusionsoft Addresses</div>
      <div className="info-content">{addressLines}</div>
    </div>
  ) : null;

  return (
    <div className="account-tab">
      <div className="info-col">
        <div className="info-section">
          <div className="info-section-header">Basics</div>
          <div className="info-content">
            <div className="info-row">
              <div className="info-title">InfusionSoft ID</div>
              <div className="info-value">{infusionsoftId}</div>
            </div>
            <div className="info-row">
              <div className="info-title">LPM ID</div>
              <div className="info-value">{id}</div>
            </div>
          </div>
        </div>
        <div className="info-section">
          <div className="info-section-header">Login Information</div>
          <div className="info-content">
            <div className="info-row">
              <div className="info-title">User Can Log In</div>
              <div className="info-value">{canLogInIcon}</div>
            </div>
            <div className="info-row">
              <div className="info-title">Email Address</div>
              <div className="info-value">{email}</div>
            </div>
            <div className="info-row">
              <div className="info-title">Username</div>
              <div className="info-value">{preferred_username}</div>
            </div>
          </div>
        </div>
        {teacherInfoSection}
        {addressesSection}
      </div>
      <div className="info-col">
        <div className="info-section">
          <div className="info-section-header">Actions</div>
          <div className="info-content">
            <div className="info-row">
              <div className="info-action" onClick={sendEmail}>
                <span>Send Email</span>
              </div>
            </div>
            {infusionsoftActions}
            {createAccountAction}
            {cognitoOnlyActions}
            {teacherActions}
            {mergeAction}
            {downloadFullRosterAction}
          </div>
        </div>
      </div>
      <CreateAccountModal />
      <ChangePasswordModal />
      <ChangeEmailModal />
      <ChangeInfusionsoftIdModal />
      <UpdateStudioLocationModal />
      <ViewStudioLocationOnMapModal />
      <EditShippingDatesModal />
      <MergeAccountModal />
    </div>
  );
};

export default observer(AccountTab);
