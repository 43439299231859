import React, { useState } from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { FiDollarSign, FiType, FiPercent } from "react-icons/fi";
import PromoCodesStore, { promoCodeTypeOptions, statusOptions } from "src/stores/PromoCodesStore";
import { Spinner, Input, Button, TextArea, Select } from "../../-common";
import "./AddPromoCodesModal.scss";

const MAX_DESCRIPTION_LENGTH = 400;

function AddPromoCodesModal({ close }) {
  const [adding, setAdding] = useState(false);

  const [code, setCode] = useState();
  const updateCode = ({ target }) => setCode(target?.value);

  const [description, setDescription] = useState("");
  const updateDescription = ({ target }) => setDescription(target?.value?.slice(0, MAX_DESCRIPTION_LENGTH));

  const [promoCodeType, setPromoCodetype] = useState();
  const [status, setStatus] = useState();

  const [value, setValue] = useState();
  const updateValue = ({ target }) => setValue(target?.value);
  const removeCommasFromPrice = () => setValue(value?.replace(/,/g, ""));
  const formatDollarValue = () => {
    const sanitizedValue = value
      ?.replace(/[^0-9.]/g, "")
      ?.split(".")
      ?.slice(0, 2)
      ?.filter(Boolean)
      ?.map((v, i) => (i === 1 ? v?.slice(0, 2) : v))
      ?.join(".");
    const formattedValue = value ? numeral(sanitizedValue).format("0.00") : value;

    setValue(formattedValue);
  };

  const fieldsFilledOut = !!code && !!status && !!promoCodeType && value != null && value !== "";
  const valueIsValid = value?.match(/[0-9]+.?/) && !isNaN(value);
  const isLegitPercentage = promoCodeType === "Percent Off" ? 0 < value && value <= 100 : true;
  const addEnabled = fieldsFilledOut && valueIsValid && isLegitPercentage;
  const addPromoCode = async () => {
    if (addEnabled) {
      setAdding(true);

      let finalValue;
      if (promoCodeType === "Percent Off") {
        if (0 <= value && value <= 1) finalValue = Number(value);
        if (1 < value && value <= 100) finalValue = Number(value / 100);
      } else if (promoCodeType === "Dollar Off") {
        finalValue = Math.round(Number(value) * 100);
      }

      await PromoCodesStore.addPromoCode({
        code,
        type: promoCodeType,
        value: finalValue,
        description,
        status
      });

      setAdding(false);
      close();
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Add Promo Code";

  const valueInput =
    promoCodeType === "Percent Off" ? (
      <Input icon={FiPercent} value={value} onChange={updateValue} placeholder="enter as .25 or 25" />
    ) : (
      <Input
        icon={FiDollarSign}
        placeholder="enter promo value"
        value={value}
        onChange={updateValue}
        onFocus={removeCommasFromPrice}
        onBlur={formatDollarValue}
        pattern="[0-9.]*"
        inputMode="decimal"
      />
    );

  return (
    <div className="add-promo-codes-modal">
      <div className="content">
        <div className="title">Add Promo Code</div>
        <div className="main-content-wrapper">
          <div className="col">
            <div className="section-container">
              <div className="section-title">Code</div>
              <Input icon={FiType} placeholder="Enter promo code" value={code} onChange={updateCode} />
              <div className="information-text">(Promo code will be treated with case insensitivity)</div>
            </div>
            <div className="section-container">
              <div className="section-title">Description</div>
              <TextArea placeholder="Add description here..." value={description} onChange={updateDescription} />
            </div>
          </div>
          <div className="col right">
            <div className="section-container">
              <div className="section-title">Type</div>
              <Select
                placeholder="Which type of promo code is this?"
                options={promoCodeTypeOptions}
                value={promoCodeType}
                onChange={setPromoCodetype}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Status</div>
              <Select
                placeholder="Is this promo code status?"
                options={statusOptions}
                value={status}
                onChange={setStatus}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Price</div>
              {valueInput}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addPromoCode} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddPromoCodesModal);
