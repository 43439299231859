import { useState, useEffect } from "react";
import AnalyticsStore from "src/stores/AnalyticsStore";
import CurriculumStore from "src/stores/CurriculumStore";

const LPMCourseIds = ["01FHKHHY91HF16BN79CYX2E236", "01FHKHHY918W6RQ75D7CDXRDVR", "01FHKHHY91EJV47PT40EVXJTAW"];
const PrestoCourseIds = ["01FHKHHY91PVVR87YBANB35H83", "01FHKHHY918EBX64K2W5TE4H3P"];
const SoundBeginningsCourseIds = [
  "01FHKHHY91ZAGKJ0AVN922NWPN",
  "01FHKHHY91F2VZ47T4E7QCYCHC",
  "01FHKHHY91VVXM9RGSSFHSMYAS",
  "01FHKHHY91RP5M3BX0N475KE7P",
  "01FHKHHY91HYE106DGKHVQ1N30",
  "01FHKHHY91XA26BD3FX6EAASJ8",
  "01FHKHHY918JHDXGK4GE81KE8E",
  "01FWPV8712TRE852M2S9MC2WRQ",
  "01GTMRJVGTYCG1S1HWFZ0E9971"
];

const curriculumCaseStatement = `CASE
    WHEN r.courseid IN ('${SoundBeginningsCourseIds?.join("','")}')
    THEN 'SB'
    WHEN r.courseid IN ('${LPMCourseIds?.join("','")}')
    THEN 'LPM'
    WHEN r.courseid IN ('${PrestoCourseIds?.join("','")}')
    THEN 'Presto'
END`;

const query = `
  SELECT
    SUM(total) AS total
    ,teacherid
    ,firstname
    ,lastname
    ,curriculum
    ,courseid
  FROM (
    SELECT
        r.courseid
        ,r.classid
        ,${curriculumCaseStatement} AS curriculum
        ,r.teacherid
        ,p.firstname
        ,p.lastname
        ,COUNT(DISTINCT r.userid) AS total
    FROM
        registration r
        LEFT JOIN class c ON r.classid = c.id
        LEFT JOIN people p ON r.teacherid = p.id
    WHERE
        r.courseid IN ('${SoundBeginningsCourseIds?.concat(LPMCourseIds)?.concat(PrestoCourseIds)?.join("','")}')
        AND r.dropped IS NULL
        AND DATE(SUBSTR(c.startdate, 1, 10)) <= NOW()
        AND NOW() <= DATE(SUBSTR(c.enddate, 1, 10))
        AND
          CASE
            WHEN ${curriculumCaseStatement} = 'SB' THEN r.type = 'parent'
            WHEN ${curriculumCaseStatement} IN ('LPM', 'Presto') THEN r.type = 'student'
            ELSE False
          END
    GROUP BY
        r.teacherid
        ,r.courseid
        ,r.classid
        ,p.firstname
        ,p.lastname
  ) base
  GROUP BY
    teacherid
    ,firstname
    ,lastname
    ,curriculum
    ,courseid
  ORDER BY
    teacherid
`;

function useData() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const rawData = await AnalyticsStore?.queryData(query);
      const formattedData = Object.values(
        rawData?.reduce((acc, next) => {
          const courseName = CurriculumStore?.coursesById?.[next?.courseid]?.name || next?.courseid;
          acc[next?.teacherid] = {
            ...(acc[next?.teacherid] || {}),
            teacherId: next?.teacherid,
            name: `${next?.firstname} ${next?.lastname}`,
            [next?.curriculum]: {
              ...(acc[next?.teacherid]?.[next?.curriculum] || {}),
              [courseName]: next?.total
            }
          };
          return acc;
        }, {})
      );
      setData(formattedData);

      setLoading(false);
    })();
  }, []);
  return { loading, data };
}

export default useData;
