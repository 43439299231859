import { makeAutoObservable, autorun } from "mobx";
import groupBy from "lodash.groupby";
import { request } from "../utils";
import { showErrorToast, showSuccessToast } from "../services/ToastService";
import AuthStore from "./AuthStore";
import CurriculumStore from "./CurriculumStore";

class PlaylistsStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (AuthStore?.authenticated) this.fetchPlaylists();
      else this.clear();
    });
  }

  loading = false;
  rawPlaylists = [];

  get playlists() {
    return this.rawPlaylists?.map(p => {
      const albumTracks = CurriculumStore?.albumsById?.[p?.albumId]?.tracks;
      return {
        ...p,
        trackIds: p?.tracks,
        tracks: p?.tracks?.map(trackId => albumTracks?.find(at => at?.id === trackId))?.filter(Boolean)
      };
    });
  }

  get playlistsByAlbumId() {
    const albumGroups = groupBy(this.playlists, "albumId");
    const sortedAlbumGroupEntries = Object.entries(albumGroups)?.map(([albumId, playlists]) => [
      albumId,
      playlists?.sort((a, b) => a?.order - b?.order)
    ]);
    return Object.fromEntries(sortedAlbumGroupEntries);
  }

  async fetchPlaylists() {
    this.loading = true;
    try {
      const playlists = await request.get(`/playlists`);
      this.rawPlaylists = playlists;
      this.loading = false;
      return playlists;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async addPlaylist({ albumId, name, tracks }) {
    try {
      const order = this.playlistsByAlbumId?.[albumId]?.length || 0;
      const newPlaylist = await request.post("/playlists", { body: { albumId, name, tracks, order } });
      this.rawPlaylists = this.rawPlaylists.concat(newPlaylist);
      showSuccessToast("Playlist added successfully.");
      return newPlaylist;
    } catch (err) {
      console.warn("Error creating playlist:", err);
      showErrorToast("Error adding playlist.");
    }
  }

  async updatePlaylist(albumId, playlistId, { name, tracks, order }) {
    const currentPlaylists = this.rawPlaylists.slice();

    try {
      const updatedPlaylist = await request.put(`/playlists/${playlistId}?albumId=${albumId}`, {
        body: { updates: { name, tracks, order } }
      });
      this.rawPlaylists = this.rawPlaylists?.map(p => {
        if (p?.id === updatedPlaylist?.id) return updatedPlaylist;
        return p;
      });

      showSuccessToast("Playlist updated successfully.");
      return updatedPlaylist;
    } catch (err) {
      this.rawPlaylists = currentPlaylists;
      console.warn("Error updating playlist:", err);
      showErrorToast("Error updating playlist.");
    }
  }

  async deletePlaylist(albumId, playlistId) {
    try {
      await request.delete(`/playlists/${playlistId}?albumId=${albumId}`);
      this.rawPlaylists = this.rawPlaylists.filter(b => b?.id !== playlistId);
      showSuccessToast("Playlist deleted successfully.");
      return true;
    } catch (err) {
      console.warn("Error deleting playlist:", err);
      showErrorToast("Error deleting playlist.");
      return false;
    }
  }

  async updatePlaylistSort(playlists) {
    const currentPlaylists = this.rawPlaylists.slice();
    try {
      const playlistUpdates = playlists?.map(({ media, ...playlist }) => playlist);
      this.rawPlaylists = playlistUpdates;
      await request.put(`/playlists/sort`, { body: { playlists: playlistUpdates } });
      showSuccessToast("Playlists sorted successfully.");
    } catch (err) {
      this.rawPlaylists = currentPlaylists;
      console.warn("Error updating playlist sort:", err);
      showErrorToast("Error sorting playlists.");
    }
  }

  clear() {
    this.rawPlaylists = [];
  }
}

export default new PlaylistsStore();
