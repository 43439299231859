// import React, { useState } from "react";
// import { observer } from "mobx-react";
// import numeral from "numeral";
// import { FiDollarSign, FiType } from "react-icons/fi";
// import { getDataURLFromFile } from "../../../../utils";
// import MaterialsStore from "../../../../stores/MaterialsStore";
// import { Spinner, Input, Button, ImageFileSelect } from "../..";
// import "./EditProductModal.scss";

// function EditProductModal({ product, close }) {
//   const { id: productId, courseId, media: currentMedia, name: currentName, price: currentPrice } = product || {};
//   const [updating, setUpdating] = useState(false);

//   const [file, setFile] = useState();
//   const [filePreviewURL, setFilePreviewURL] = useState(currentMedia);
//   const updateFile = async ({ target }) => {
//     const selectedFile = target?.files?.[0];
//     if (selectedFile) {
//       const previewURL = await getDataURLFromFile(selectedFile);
//       setFilePreviewURL(previewURL);
//       setFile(selectedFile);
//     }
//   };

//   const [name, setName] = useState(currentName);
//   const updateName = ({ target }) => setName(target?.value);

//   const [price, setPrice] = useState(numeral(currentPrice / 100).format("0,0.00"));
//   const updatePrice = ({ target }) => setPrice(target?.value);
//   const removeCommasFromPrice = () => setPrice(price?.replace(/,/g, ""));
//   const formatPrice = () => {
//     const sanitizedPrice = price
//       ?.replace(/[^0-9.]/g, "")
//       ?.split(".")
//       ?.slice(0, 2)
//       ?.filter(Boolean)
//       ?.map((v, i) => (i === 1 ? v?.slice(0, 2) : v))
//       ?.join(".");
//     const formattedPrice = price ? numeral(sanitizedPrice).format("0.00") : price;
//     setPrice(formattedPrice);
//   };

//   const fieldsFilledOut = !!name && price != null && price !== "";
//   const somethingChanged = !!file || name !== currentName || price !== currentPrice;
//   const priceIsValid = price?.match(/[0-9]+.?/) && !isNaN(price);
//   const updateEnabled = fieldsFilledOut && somethingChanged && priceIsValid;
//   const updateProduct = async () => {
//     if (updateEnabled) {
//       setUpdating(true);
//       const priceInCents = Math.round(Number(price) * 100);
//       await MaterialsStore.updateProduct(productId, courseId, { file, name, price: priceInCents });
//       setUpdating(false);
//       close();
//     }
//   };

//   const cancelButtonStyles = updating ? { opacity: 0.1, pointerEvents: "none" } : {};

//   const actionButtonStyles = updateEnabled
//     ? updating
//       ? { opacity: 0.3, pointerEvents: "none" }
//       : {}
//     : { opacity: 0.3, pointerEvents: "none" };
//   const actionButtonContent = updating ? <Spinner /> : "Update Product";

//   return (
//     <div className="edit-product-modal">
//       <div className="content">
//         <div className="title">Update Product</div>
//         <div className="main-content-wrapper">
//           <div className="col">
//             <div className="section-container">
//               <div className="section-title">Image</div>
//               <div className="artwork-select-container">
//                 <ImageFileSelect {...{ filePreviewURL, updateFile }} />
//               </div>
//             </div>
//           </div>
//           <div className="col right">
//             <div className="section-container">
//               <div className="section-title">Name</div>
//               <Input icon={FiType} placeholder="Product Name" value={name} onChange={updateName} />
//             </div>
//             <div className="section-container">
//               <div className="section-title">Price</div>
//               <Input
//                 icon={FiDollarSign}
//                 placeholder="Product Price"
//                 value={price}
//                 onChange={updatePrice}
//                 onFocus={removeCommasFromPrice}
//                 onBlur={formatPrice}
//                 pattern="[0-9.]*"
//                 inputMode="decimal"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="bottom-buttons">
//         <Button action={close} style={cancelButtonStyles}>
//           Cancel
//         </Button>
//         <div className="left-buttons">
//           <Button type="primary" action={updateProduct} style={actionButtonStyles}>
//             {actionButtonContent}
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default observer(EditProductModal);

import React, { useState } from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { FiDollarSign, FiType, FiPercent } from "react-icons/fi";
import PromoCodesStore, { promoCodeTypeOptions, statusOptions } from "src/stores/PromoCodesStore";
import { Spinner, Input, Button, TextArea, Select } from "../..";
import "./EditPromoCodesModal.scss";

const MAX_DESCRIPTION_LENGTH = 400;

function EditPromoCodesModal({ promoCode, close }) {
  const {
    id: promoCodeId,
    code: currentCode,
    description: currentDescription,
    type: currentPromoCodeType,
    status: currentStatus,
    value: currentValue
  } = promoCode || {};
  const [updating, setUpdating] = useState(false);

  const [code, setCode] = useState(currentCode);
  const updateCode = ({ target }) => setCode(target?.value);

  const [description, setDescription] = useState(currentDescription);
  const updateDescription = ({ target }) => setDescription(target?.value?.slice(0, MAX_DESCRIPTION_LENGTH));

  const [promoCodeType, setPromoCodetype] = useState(currentPromoCodeType);
  const [status, setStatus] = useState(currentStatus);

  const [value, setValue] = useState(String(currentValue));
  const updateValue = ({ target }) => setValue(target?.value);
  const removeCommasFromPrice = () => setValue(value?.replace(/,/g, ""));
  const formatDollarValue = () => {
    const sanitizedValue = value
      ?.replace(/[^0-9.]/g, "")
      ?.split(".")
      ?.slice(0, 2)
      ?.filter(Boolean)
      ?.map((v, i) => (i === 1 ? v?.slice(0, 2) : v))
      ?.join(".");
    const formattedValue = value ? numeral(sanitizedValue).format("0.00") : value;

    setValue(formattedValue);
  };

  const fieldsFilledOut = !!code && !!status && !!promoCodeType && value != null && value !== "";
  const valueIsValid = (value || "")?.match(/[0-9]+.?/) && !isNaN(value);
  const isLegitPercentage = promoCodeType === "Percent Off" ? 0 < value && value <= 100 : true;
  const somethingChanged =
    code !== currentCode ||
    description !== currentDescription ||
    currentPromoCodeType !== promoCodeType ||
    status !== currentStatus ||
    value !== String(currentValue);

  const updateEnabled = fieldsFilledOut && valueIsValid && isLegitPercentage && somethingChanged;
  const updatePromoCode = async () => {
    if (updateEnabled) {
      setUpdating(true);

      let finalValue;
      if (promoCodeType === "Percent Off") {
        if (0 <= value && value <= 1) finalValue = Number(value);
        if (1 < value && value <= 100) finalValue = Number(value / 100);
      } else if (promoCodeType === "Dollar Off") {
        finalValue = Math.round(Number(value) * 100);
      }

      await PromoCodesStore.updatePromoCode(promoCodeId, {
        code,
        type: promoCodeType,
        value: finalValue,
        description,
        status
      });

      setUpdating(false);
      close();
    }
  };

  const cancelButtonStyles = updating ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = updateEnabled
    ? updating
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = updating ? <Spinner /> : "Update Promo Code";

  const valueInput =
    promoCodeType === "Percent Off" ? (
      <Input icon={FiPercent} value={value} onChange={updateValue} placeholder="enter as .25 or 25" />
    ) : (
      <Input
        icon={FiDollarSign}
        placeholder="enter promo value"
        value={value}
        onChange={updateValue}
        onFocus={removeCommasFromPrice}
        onBlur={formatDollarValue}
        pattern="[0-9.]*"
        inputMode="decimal"
      />
    );

  return (
    <div className="edit-promo-codes-modal">
      <div className="content">
        <div className="title">Add Promo Code</div>
        <div className="main-content-wrapper">
          <div className="col">
            <div className="section-container">
              <div className="section-title">Code</div>
              <Input icon={FiType} placeholder="Enter promo code" value={code} onChange={updateCode} />
              <div className="information-text">(Promo code will be treated with case insensitivity)</div>
            </div>
            <div className="section-container">
              <div className="section-title">Description</div>
              <TextArea placeholder="Add description here..." value={description} onChange={updateDescription} />
            </div>
          </div>
          <div className="col right">
            <div className="section-container">
              <div className="section-title">Type</div>
              <Select
                placeholder="Which type of promo code is this?"
                options={promoCodeTypeOptions}
                value={promoCodeType}
                onChange={setPromoCodetype}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Status</div>
              <Select
                placeholder="Is this promo code status?"
                options={statusOptions}
                value={status}
                onChange={setStatus}
              />
            </div>
            <div className="section-container">
              <div className="section-title">Price</div>
              {valueInput}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={updatePromoCode} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditPromoCodesModal);
