import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Link, navigate } from "@reach/router";
import { FiChevronRight } from "react-icons/fi";
import uniqBy from "lodash.uniqby";
import usePerson from "../../../hooks/usePerson";
import useTabs from "../../../hooks/useTabs";
import AppStateStore from "../../../stores/AppStateStore";
import PeopleStore from "../../../stores/PeopleStore";
import { Spinner } from "../../-common";
import AccountTab from "./AccountTab";
import ActivityTab from "./ActivityTab";
import FilesTab from "./FilesTab";
import ClassesTab from "./ClassesTab";
import RegistrationsTab from "./RegistrationsTab";
import ConnectionsTab from "./ConnectionsTab";
import "./PersonOverview.scss";

const commonSections = [
  { title: "Account", component: AccountTab },
  { title: "Activity", component: ActivityTab }
];
const teacherSections = [
  { title: "Uploads & Certs", component: FilesTab },
  { title: "Classes", component: ClassesTab }
];
const parentSections = [
  { title: "Enrollments", component: RegistrationsTab },
  { title: "Relationships", component: ConnectionsTab }
];
const studentSections = [
  { title: "Enrollments", component: RegistrationsTab },
  { title: "Relationships", component: ConnectionsTab }
];

const ZeroState = ({ firstName, lastName }) => {
  const userNameTitle =
    firstName && lastName ? (
      <>
        <FiChevronRight className="arrow-icon" />{" "}
        <span>
          {firstName?.toProperCase()} {lastName?.toProperCase()}
        </span>
      </>
    ) : null;

  return (
    <div className="person-overview">
      <div className="title-row">
        <div className="title">
          <Link to="/people">People</Link>
          {userNameTitle}
        </div>
      </div>
      <div className="loading-container">
        <Spinner alt />
      </div>
    </div>
  );
};

const Tab = ({ index, title, select, activeTabIndex }) => (
  <div className={`tab${index === activeTabIndex ? " active" : ""}`} onClick={select}>
    {title}
  </div>
);

function PersonOverview({ lpmId }) {
  const { firstName: tempFirstName, lastName: tempLastName } = AppStateStore?.pendingPersonForDetailsScreen || {};
  const { person: user } = usePerson(lpmId);
  const { parent, student, teacher } = user || {};

  const personDetailsLoaded = !!user?.id;
  useEffect(() => {
    if (personDetailsLoaded) {
      AppStateStore?.clearPendingPersonForDetailsScreen();
      PeopleStore?.addToRecentlyViewed({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email?.[0]?.email,
        isParent: user?.parent,
        isStudent: user?.student,
        isTeacher: user?.teacher,
        infusionsoftId: user?.infusionsoftId,
        userId: user?.id,
        viewedAt: new Date().valueOf()
      });
    }
  }, [personDetailsLoaded, user]);

  const possibleSections = commonSections
    .concat(teacher ? teacherSections : [])
    .concat(parent ? parentSections : [])
    .concat(student ? studentSections : [])
    .filter(Boolean);
  const sections = uniqBy(possibleSections, ({ title }) => title);

  const { activeTabIndex, tabKeys } = useTabs(sections);
  const selectTab = index => () => navigate(`/people/${lpmId}?tab=${encodeURIComponent(tabKeys[index])}`);

  const firstName = user?.firstName || tempFirstName;
  const lastName = user?.lastName || tempLastName;

  if (!personDetailsLoaded) return <ZeroState {...{ firstName, lastName }} />;

  const teacherChip = teacher ? (
    <div className="chip teacher" key="t">
      Teacher
    </div>
  ) : null;
  const parentChip = parent ? (
    <div className="chip parent" key="p">
      Parent
    </div>
  ) : null;
  const studentChip = student ? (
    <div className="chip student" key="s">
      Student
    </div>
  ) : null;
  const chips = [teacherChip, parentChip, studentChip].filter(Boolean);

  const tabs = sections?.map(({ title }, index) => (
    <Tab {...{ index, title, select: selectTab(index), activeTabIndex }} key={title} />
  ));
  const ActiveSection = sections?.[activeTabIndex]?.component || (() => null);

  const tabBarStyles = {
    gridTemplateColumns: `repeat(${sections.length}, 1fr)`
  };

  return (
    <div className="person-overview">
      <div className="title-row">
        <div className="title">
          <Link to="/people">People</Link> <FiChevronRight className="arrow-icon" />{" "}
          <span>
            {firstName?.toProperCase()} {lastName?.toProperCase()}
          </span>
        </div>
      </div>
      <div className="chips-container">{chips}</div>
      <div className="tabs-container">
        <div className="tab-bar" style={tabBarStyles}>
          {tabs}
        </div>
        <div className="active-tab-section">
          <ActiveSection {...{ user }} />
        </div>
      </div>
    </div>
  );
}

export default observer(PersonOverview);
