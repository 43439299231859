import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import CurriculumStore from "../../../../stores/CurriculumStore";
import { Spinner, Input, Button } from "../../../-common";
import "./AddCourseModal.scss";

function AddCourseModal({ curriculum, close }) {
  const [adding, setAdding] = useState(false);

  const [name, setName] = useState("");
  const updateName = ({ target }) => setName(target?.value);

  const addEnabled = !!name;
  const addCourse = async () => {
    if (addEnabled) {
      setAdding(true);
      const newCourse = await CurriculumStore.addCourse({ curriculum, name });
      setAdding(false);
      if (newCourse?.id) {
        close();
        navigate(`/curriculum/${curriculum.id}/courses/${newCourse.id}`);
      }
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Add Course";

  return (
    <div className="add-course-modal">
      <div className="content">
        <div className="title">Add New Course</div>
        <div className="section-container">
          <div className="section-title">Title</div>
          <Input placeholder="Title for new course" value={name} onChange={updateName} />
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addCourse} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddCourseModal);
