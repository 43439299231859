import { makeAutoObservable } from "mobx";
import { Auth } from "aws-amplify";
import { v4 as uuidV4 } from "uuid";
import { request } from "../utils";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
    this.checkForAuthenticatedUser();
  }

  authDetermined = false;
  authenticated = false;
  user = {};
  groups = [];
  localCredentials = {};

  get sub() {
    return this.user?.attributes?.sub;
  }

  loading = false;

  async checkForAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const { "cognito:groups": groups } = session?.accessToken?.payload || {};

      if (!groups?.includes("admin")) {
        this.signOut();
        this.authenticated = false;
        this.authDetermined = true;
        return;
      }

      if (user) {
        this.authenticated = true;
        this.user = user;
        this.groups = groups;
      } else {
        this.authenticated = false;
        this.user = {};
      }
    } catch (err) {
      // This also means user isn't authenticated
      this.authenticated = false;
    }

    this.authDetermined = true;

    // this.authenticated = true;
    // this.authDetermined = true;
  }

  async signIn(username, password) {
    this.loading = true;
    try {
      await Auth.signIn(username || "-", password || "-");
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const { "cognito:groups": groups } = session?.accessToken?.payload || {};
      if (!groups?.includes("admin")) {
        this.signOut();
        this.loading = false;
        return { success: false };
      }

      this.user = user;
      this.groups = groups;
      this.authenticated = true;
      this.loading = false;
      return { success: true };
    } catch (err) {
      console.warn(err);
      this.authenticated = false;
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async signUp({ firstName, lastName, email, phone, address1, address2, city, state, zip, password }) {
    this.loading = true;

    let success, error, lpmId;
    try {
      const username = uuidV4();
      await Auth.signUp({ username, password, attributes: { email, phone_number: phone } });
      const { id } = await request.post("/accounts", {
        body: {
          username,
          firstName,
          lastName,
          email,
          phone,
          address1,
          address2,
          city,
          state,
          zip
        }
      });
      success = true;
      lpmId = id;
      this.localCredentials = { lpmId, username, email, password };
    } catch (err) {
      console.warn(err);
      success = false;
      if (err.message === "PreSignUp failed with error A user with the same email address exists.") {
        error = "User account with the same email already exists. Please log in or reset password instead.";
      } else {
        error = err.message;
      }
    }

    this.loading = false;
    return { success, error, lpmId };
  }

  async resendVerificationCode() {
    let success, error;

    this.loading = true;
    try {
      await Auth.resendSignUp(this.localCredentials?.username);
      success = true;
    } catch (err) {
      error = err.message;
      success = false;
    }

    this.loading = false;
    return { success, error };
  }

  async verifyAccount(code) {
    this.loading = true;
    try {
      const { username } = this.localCredentials || {};
      await Auth.confirmSignUp(username, code, { forceAliasCreation: true });
      return { success: true };
    } catch (err) {
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async sendResetPasswordCode(email) {
    let success, error;

    this.loading = true;
    try {
      await Auth.forgotPassword(email);
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async resetPassword(username, code, newPassword) {
    this.loading = true;
    let success, error;

    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async signOut() {
    try {
      await Auth.signOut();
      this.user = {};
      this.authenticated = false;
    } catch (err) {
      console.warn(err);
    }
  }
}

export default new AuthStore();
