import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { Link, useLocation } from "@reach/router";
import {
  FiSettings,
  FiUsers,
  FiBook,
  FiTablet,
  FiPackage,
  FiPieChart,
  FiGift,
  FiMusic,
  FiDollarSign
} from "react-icons/fi";
import logoBanner from "../../assets/logo-banner.png";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import UserStore from "../../stores/UserStore";
import AuthStore from "../../stores/AuthStore";
import "./Sidebar.scss";

const SidebarLink = ({ currentPath, path, icon: Icon, title, color }) => {
  const active = !!currentPath.match(new RegExp("^" + path));
  const linkColorStyles = {
    color: active ? "var(--white)" : color,
    backgroundColor: active ? color : null
  };
  const iconColorStyles = { stroke: active ? "var(--white)" : color };
  return (
    <Link className="sidebar-link-wrapper" to={path}>
      <div className="sidebar-link" style={linkColorStyles}>
        <Icon className="link-icon" style={iconColorStyles} />
        <span>{title}</span>
      </div>
    </Link>
  );
};

const SettingsMenuContent = observer(() => {
  const { firstName, lastName } = UserStore?.user || {};

  const signOut = () => AuthStore?.signOut();

  return (
    <div className="settings-menu-content">
      <div className="settings-menu-section">
        <div className="settings-menu-header">
          {firstName} {lastName}
        </div>
        <div className="settings-menu-item action" onClick={signOut}>
          Sign Out
        </div>
      </div>
    </div>
  );
});

function Sidebar() {
  const { pathname } = useLocation();

  const [settingsMenuActive, setSettingsMenuActive] = useState(false);
  const openSettingsMenu = () => setSettingsMenuActive(true);
  const closeSettingsMenu = () => setSettingsMenuActive(false);
  const settingsButtonRef = useRef();
  useOnClickOutside(settingsButtonRef, closeSettingsMenu);

  const settingsMenuContent = settingsMenuActive ? <SettingsMenuContent /> : null;

  return (
    <div className="sidebar">
      <div className="logo-section">
        <img src={logoBanner} alt="LPM Logo" className="logo-banner" />
      </div>
      <div className="links-section">
        <SidebarLink currentPath={pathname} path="/people" title="People" icon={FiUsers} color="var(--red)" />
        <SidebarLink
          currentPath={pathname}
          path="/enrollment"
          title="Enrollment"
          icon={FiMusic}
          color="var(--orange)"
        />
        <SidebarLink currentPath={pathname} path="/curriculum" title="Curriculum" icon={FiBook} color="var(--yellow)" />
        <SidebarLink currentPath={pathname} path="/products" title="Products" icon={FiGift} color="var(--green)" />
        <SidebarLink
          currentPath={pathname}
          path="/promo"
          title="Promo Codes"
          icon={FiDollarSign}
          color="var(--light-green)"
        />
        <SidebarLink currentPath={pathname} path="/mobile" title="Mobile" icon={FiTablet} color="var(--blue)" />
        <SidebarLink currentPath={pathname} path="/shipping" title="Shipping" icon={FiPackage} color="var(--purple)" />
        <SidebarLink
          currentPath={pathname}
          path="/analytics"
          title="Reports & Analytics"
          icon={FiPieChart}
          color="var(--medium-gray)"
        />
      </div>
      <div className="flexer" />
      <div
        ref={settingsButtonRef}
        className={`settings-button${settingsMenuActive ? " active" : ""}`}
        onClick={openSettingsMenu}
      >
        <FiSettings className="icon" />
        {settingsMenuContent}
      </div>
    </div>
  );
}

export default observer(Sidebar);
