import { makeAutoObservable, autorun } from "mobx";
import { ENV } from "../config";
import { request } from "../utils";
import { showErrorToast, showSuccessToast } from "../services/ToastService";
import AuthStore from "./AuthStore";

export const audienceOptions = ["Everyone", "Only Teachers", "Only Parents"];
export const statusOptions = ["Active", "Inactive"];

class BannersStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (AuthStore?.authenticated) this.fetchBanners();
      else this.clear();
    });
  }

  loading = false;

  rawBanners = [];
  get banners() {
    return this.rawBanners
      ?.map(b => ({ ...b, media: `https://${ENV}-lpm-assets.b-cdn.net/banners/${b?.id}?m=${b?.modified}` }))
      ?.sort((a, b) => a?.order - b?.order);
  }

  get totalBanners() {
    return this.banners?.length || 0;
  }

  get activeBanners() {
    return this.banners?.filter(b => b?.status === "Active")?.length || 0;
  }

  get inactiveBanners() {
    return this.banners?.filter(b => b?.status === "Inactive")?.length || 0;
  }

  async fetchBanners() {
    this.loading = true;
    try {
      const banners = await request.get("/banners");
      this.rawBanners = banners;
      this.loading = false;
      return banners;
    } catch (err) {
      console.warn("Error fetching banners:", err);
    }
    this.loading = false;
  }

  async addBanner({ file, url, audience, status }) {
    try {
      const order = this.banners?.length || 0;
      const newBanner = await request.post("/banners", { body: { url, audience, status, order } });
      const fileData = await request.get(`/files/banner?bannerId=${newBanner.id}`, {
        headers: { "file-type": file.type }
      });
      const { uploadURL: bannerFileUploadURL } = fileData || {};
      const uploadResponse = await fetch(bannerFileUploadURL, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type, "x-amz-acl": "public-read" }
      });
      if (!uploadResponse.ok) {
        await request.delete(`/banners/${newBanner.id}`);
        throw new Error("Upload failed.");
      }
      this.rawBanners = this.rawBanners.concat(newBanner);
      showSuccessToast("Banner added successfully.");
      return newBanner;
    } catch (err) {
      console.warn("Error creating banner:", err);
      showErrorToast("Error adding banner.");
    }
  }

  async updateBanner(bannerId, { file, url, audience, status, order }, { optimisticUI = false } = {}) {
    const currentBanners = this.rawBanners.slice();

    if (optimisticUI) {
      this.rawBanners = this.rawBanners?.map(b => {
        if (b?.id === bannerId) {
          return {
            ...b,
            ...(url ? { url } : {}),
            ...(audience ? { audience } : {}),
            ...(status ? { status } : {}),
            ...(order ? { order } : {})
          };
        }
        return b;
      });
    }

    try {
      if (file) {
        const fileData = await request.get(`/files/banner?bannerId=${bannerId}`, {
          headers: { "file-type": file.type }
        });
        const { uploadURL: bannerFileUploadURL } = fileData || {};
        const uploadResponse = await fetch(bannerFileUploadURL, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type, "x-amz-acl": "public-read" }
        });
        if (!uploadResponse.ok) throw new Error("Updating banner image failed.");
      }

      const updatedBanner = await request.put(`/banners/${bannerId}`, { body: { url, audience, status, order } });
      this.rawBanners = this.rawBanners?.map(b => {
        if (b?.id === updatedBanner?.id) return updatedBanner;
        return b;
      });

      showSuccessToast("Banner updated successfully.");
      return updatedBanner;
    } catch (err) {
      this.rawBanners = currentBanners;
      console.warn("Error updating banner:", err);
      showErrorToast("Error updating banner.");
    }
  }

  async updateBannerSort(banners) {
    const currentBanners = this.rawBanners.slice();
    try {
      const bannerUpdates = banners?.map(({ media, ...banner }) => banner);
      this.rawBanners = bannerUpdates;
      await request.put(`/banners/sort`, { body: { banners: bannerUpdates } });
      showSuccessToast("Banners sorted successfully.");
    } catch (err) {
      this.rawBanners = currentBanners;
      console.warn("Error updating banner sort:", err);
      showErrorToast("Error sorting banners.");
    }
  }

  async deleteBanner(bannerId) {
    const currentBanners = this.rawBanners.slice();
    this.rawBanners = this.rawBanners?.filter(b => b?.id !== bannerId);
    try {
      await request.delete(`/banners/${bannerId}`);
    } catch (err) {
      this.rawBanners = currentBanners;
      console.warn("Error deleting banner:", err);
    }
  }

  clear() {
    this.rawBranners = [];
  }
}

export default new BannersStore();
