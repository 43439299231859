export default function getDurationFromAudioFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => {
      const audioElement = document.createElement("audio");
      audioElement.src = e.target.result;
      audioElement.addEventListener("loadedmetadata", () => {
        const duration = audioElement.duration * 1000;
        audioElement.remove();
        resolve(duration);
      });
    };
    reader.readAsDataURL(file);
  });
}
