import React from "react";
import numeral from "numeral";
import "./SummaryNumber.scss";

function SummaryNumber({ value, currency, text }) {
  const valueLabel = text
    ? value
    : numeral(value)
        .format(currency ? "$0,0.00" : "0,0.0")
        ?.replace(/\.0+$/, "");
  return (
    <div className="summary-number">
      <div className="summary-number-value">{valueLabel}</div>
    </div>
  );
}

export default SummaryNumber;
