import React, { useState } from "react";
import {
  ResponsiveContainer,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Scatter,
  Cell,
  Tooltip,
  Legend,
  Text
} from "recharts";
import { TOTAL_COLORS } from "../constants";
import { formatTickAsCurrency, formatTickAsPercentage, abbreviateTick, tooltipFormatter } from "../formatters";
import "./BubbleChart.scss";

const tickFormatters = { currency: formatTickAsCurrency, percentage: formatTickAsPercentage };

const XAxisLabel = label => {
  return ({ viewBox }) => {
    const { x, y, width, height } = viewBox || {};
    return (
      <Text x={x + width / 2} y={y + height + 10} textAnchor="middle" fill="var(--gray)">
        {label}
      </Text>
    );
  };
};

const YAxisLabel = label => {
  return ({ viewBox }) => {
    const { x, y, height } = viewBox || {};
    return (
      <Text x={x + 10} y={y + height / 2} angle={-90} textAnchor="middle" fill="var(--gray)">
        {label}
      </Text>
    );
  };
};

const BubbleTooltip = ({ active, payload, colorsByService, ...fieldProps }) => {
  const { xAxisLabel, xAxisType, yAxisLabel, yAxisType, zAxisLabel, zAxisType } = fieldProps || {};
  if (active && payload && payload.length) {
    const label = payload?.[0]?.payload?.label;

    const values = payload?.map(d => {
      let seriesType;
      if (d?.name === xAxisLabel) seriesType = xAxisType;
      else if (d?.name === yAxisLabel) seriesType = yAxisType;
      else if (d?.name === zAxisLabel) seriesType = zAxisType;
      const formatter = tooltipFormatter({
        percentage: seriesType === "percentage",
        currency: seriesType === "currency"
      });

      return (
        <li
          className="recharts-tooltip-item"
          style={{
            marginLeft: 6,
            fontWeight: "500",
            display: "block",
            paddingTop: 4,
            paddingBottom: 4,
            color: "var(--gray)"
          }}
        >
          <span className="recharts-tooltip-item-name">{d?.name}</span>
          <span className="recharts-tooltip-item-separator"> : </span>
          <span className="recharts-tooltip-item-value">{formatter(d?.value)}</span>
          <span className="recharts-tooltip-item-unit"></span>
        </li>
      );
    });
    return (
      <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-top">
        <div
          className="recharts-default-tooltip"
          style={{
            margin: 0,
            padding: 10,
            backgroundColor: "rgb(255, 255, 255)",
            border: "none",
            whiteSpace: "nowrap",
            boxShadow: "var(--shadow)"
          }}
        >
          <p
            className="recharts-tooltip-label"
            style={{
              margin: 0,
              marginBottom: 4,
              color: colorsByService[label],
              fontSize: 14,
              fontWeight: "500",
              textTransform: "uppercase"
            }}
          >
            {label}
          </p>
          <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
            {values}
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

function BubbleChart({
  data,
  xAxisField,
  xAxisLabel,
  xAxisType,
  yAxisField,
  yAxisLabel,
  yAxisType,
  zAxisField,
  zAxisLabel,
  zAxisType,
  legendPosition = "bottom",
  hideLegend,
  width = "100%",
  height = "100%",
  onDrill = () => null
}) {
  const dataSeries = Array.from(new Set(data?.map(({ name, ...series }) => Object.keys(series))?.flat()));

  const [focusedBubbleName, setFocusedBubbleName] = useState(null);
  const updateFocusedBubbleName = state => setFocusedBubbleName(state.service);
  const unsetFocusedBubbleName = () => setFocusedBubbleName(null);

  const [hoveredLegendEntry, setHoveredLegendEntry] = useState(null);
  const updateHoveredLegendEntry = ({ value }) => setHoveredLegendEntry(value);
  const unsetHoveredLegendEntry = () => setHoveredLegendEntry(null);

  if (!dataSeries?.length) return <div className="bar-chart no-data">(no data)</div>;

  let legendProps = {};
  if (legendPosition === "right") {
    legendProps = { layout: "vertical", verticalAlign: "middle", align: "right", wrapperStyle: { paddingLeft: 16 } };
  } else if (legendPosition === "bottom") {
    legendProps = { wrapperStyle: { paddingTop: 16 } };
  }

  const legend =
    dataSeries?.length > 1 && !hideLegend ? (
      <Legend {...legendProps} onMouseEnter={updateHoveredLegendEntry} onMouseLeave={unsetHoveredLegendEntry} />
    ) : null;

  const colorsByService = Object.fromEntries(
    data?.map((d, i) => {
      const colorIndex = (i % TOTAL_COLORS) + 1;
      const color = `var(--chart-${colorIndex})`;
      return [d?.service, color];
    })
  );
  const pointerAndOpacityStyles = {};
  const bubbles = data?.map(d => {
    const color = colorsByService[d?.service];
    const isFocused = focusedBubbleName === d?.service;
    const anyFocused = focusedBubbleName != null;
    const legendEntryHovered = hoveredLegendEntry === d?.service;
    const anyLegendEntryHovered = hoveredLegendEntry != null;
    const opacity = (!isFocused && anyFocused) || (!legendEntryHovered && anyLegendEntryHovered) ? 0.2 : 1;
    return (
      <Scatter
        name={d?.service}
        data={[{ ...d, label: d?.service }]}
        fill={color}
        onMouseMove={updateFocusedBubbleName}
        onMouseLeave={unsetFocusedBubbleName}
        key={d?.service}
      >
        <Cell opacity={opacity} style={pointerAndOpacityStyles} />
      </Scatter>
    );
  });

  const tooltipFieldProps = { xAxisLabel, xAxisType, yAxisLabel, yAxisType, zAxisLabel, zAxisType };

  return (
    <ResponsiveContainer className="bubble-chart" {...{ width, height }} debounce={1}>
      <ScatterChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 20
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="var(--light-gray)" />
        <XAxis
          dataKey={xAxisField}
          type="number"
          tickLine={{ stroke: "var(--light-gray)" }}
          axisLine={{ stroke: "var(--light-gray)" }}
          tickFormatter={tickFormatters[xAxisType] || abbreviateTick}
          label={XAxisLabel(xAxisLabel)}
          domain={xAxisType === "percentage" ? [0, 1] : null}
        />
        <YAxis
          dataKey={yAxisField}
          type="number"
          tickLine={{ stroke: "var(--light-gray)" }}
          axisLine={{ stroke: "var(--light-gray)" }}
          tickFormatter={tickFormatters[yAxisType] || abbreviateTick}
          label={YAxisLabel(yAxisLabel)}
        />
        <ZAxis
          type="number"
          dataKey={zAxisField}
          range={[100, 5000]}
          tickFormatter={tickFormatters[zAxisType] || abbreviateTick}
        />
        <Tooltip
          content={<BubbleTooltip {...{ colorsByService, ...tooltipFieldProps }} />}
          cursor={{ fill: "none" }}
          isAnimationActive={false}
          labelStyle={{
            marginBottom: 4,
            color: "var(--gray)",
            fontSize: 14,
            fontWeight: "500",
            textTransform: "uppercase"
          }}
          itemStyle={{ marginLeft: 6, fontWeight: "500" }}
          contentStyle={{ border: "none", boxShadow: "var(--shadow)" }}
        />
        {legend}
        {bubbles}
      </ScatterChart>
    </ResponsiveContainer>
  );
}

export default BubbleChart;
