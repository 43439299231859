import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import CurriculumStore from "../stores/CurriculumStore";

export default function useCourse(courseId, { curriculumId } = {}) {
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState();
  useEffect(() => {
    const matchingCourse = CurriculumStore?.coursesById[courseId];
    if (matchingCourse) {
      setCourse(toJS(matchingCourse));
      setLoading(false);
    } else {
      if (courseId) {
        setCourse();
        (async () => {
          setLoading(true);
          await CurriculumStore.fetchCourses();
          const course = CurriculumStore?.coursesById[courseId];
          if (!course) navigate(`/curriculum/${curriculumId}`);
          setLoading(false);
        })();
      }
    }
  }, [courseId, curriculumId]);
  return { course: course || {}, loading };
}
