import { makeAutoObservable, autorun } from "mobx";
import { format, parseISO } from "date-fns";
import { request } from "../utils";
import { showErrorToast, showSuccessToast } from "../services/ToastService";
import AuthStore from "./AuthStore";
import PeopleStore from "./PeopleStore";
import ClassesStore from "./ClassesStore";
import CurriculumStore from "./CurriculumStore";
import MaterialsStore from "./MaterialsStore";

class RegistrationsStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) this.fetchRecentRegistrations();
      else this.clear();
    });
  }

  loading = false;

  rawRecentRegistrations = [];

  get recentRegistrations() {
    const groupedToParents = Object.values(
      this.rawRecentRegistrations?.reduce((acc, next) => {
        const { class: classItem, parent, registrationDate } = next || {};
        const classId = classItem?.id || classItem?.classId;
        const registrationId = `${classId}-${parent?.id}-${registrationDate}`;

        const { student, ...registration } = next || {};
        acc[registrationId] = { ...registration, students: (acc[registrationId]?.students || []).concat(student) };
        return acc;
      }, {})
    );
    return groupedToParents?.map(r => {
      return {
        ...r,
        class: {
          ...r?.class,
          course: CurriculumStore?.coursesById?.[r?.class?.courseId]
        }
      };
    });
  }

  get registrationsToday() {
    const today = format(new Date(), "yyyy-MM-dd");
    const registrationsToday = this.rawRecentRegistrations?.filter(
      r => format(parseISO(r?.registrationDate), "yyyy-MM-dd") === today
    )?.length;
    return registrationsToday;
  }

  get registrationsByUserId() {
    const currentDateString = new Date().toISOString().slice(0, 10);
    const teacherClassEntries = PeopleStore?.people?.map(({ id: userId, registrations }) => [
      userId,
      registrations
        ?.map(r => ({
          ...r,
          class: {
            ...r?.class,
            ...ClassesStore?.classesById?.[r?.class?.classId],
            course: CurriculumStore?.coursesById?.[r?.class?.courseId]
          }
        }))
        ?.map(r => ({
          ...r,
          current: !!r?.class?.isActive,
          past: !r?.class?.isActive && r?.class?.endDate < currentDateString,
          future: !r?.class?.isActive && r?.class?.startDate > currentDateString
        }))
    ]);
    return Object.fromEntries(teacherClassEntries);
  }

  async fetchRecentRegistrations() {
    this.loading = true;
    try {
      const recentRegistrations = await request.get(`/registrations/recent`);
      this.rawRecentRegistrations = recentRegistrations;
    } catch (err) {
      console.warn(err);
    }
    this.loading = false;
  }

  async reassignStudents(reassignParams) {
    const {
      oldTeacherId,
      oldClassId,
      newTeacherId,
      newClassId,
      parentId,
      studentIds,
      itemsToTransfer,
      isCourseChange
    } = reassignParams || {};
    try {
      await request.put(`/registrations/reassign`, {
        body: {
          oldTeacherId,
          oldClassId,
          newTeacherId,
          newClassId,
          parentId,
          studentIds,
          itemsToTransfer,
          isCourseChange
        }
      });
      showSuccessToast("Successfully reassigned students.");
      await Promise.all([
        ClassesStore.fetchStudentsForClassById(oldClassId),
        ClassesStore.fetchStudentsForClassById(newClassId),
        PeopleStore.fetchPersonById(oldTeacherId),
        PeopleStore.fetchPersonById(newTeacherId)
      ]);
    } catch (err) {
      console.warn("Error reassigning students:", err);
      showErrorToast("Error reassigning students.");
    }
  }

  async dropStudents({ teacherId, classId, parentId, studentIds, itemsToRemove }) {
    try {
      await request.put(`/registrations/drop`, { body: { teacherId, classId, parentId, studentIds, itemsToRemove } });
      showSuccessToast("Successfully dropped students.");
      await Promise.all([
        ClassesStore.fetchStudentsForClassById(classId),
        PeopleStore.fetchPersonById(teacherId),
        ClassesStore.fetchDroppedStudentsForClassById(classId)
      ]);
    } catch (err) {
      console.warn("Error dropping students:", err);
      showErrorToast("Error dropping students.");
    }
  }

  async undropStudents({ teacherId, classId, parentId, studentIds }) {
    try {
      await request.put(`/registrations/undrop`, { body: { teacherId, classId, parentId, studentIds } });
      showSuccessToast("Successfully undropped students.");
      await Promise.all([
        ClassesStore.fetchStudentsForClassById(classId),
        PeopleStore.fetchPersonById(teacherId),
        ClassesStore.fetchDroppedStudentsForClassById(classId)
      ]);
    } catch (err) {
      console.warn("Error undropping students:", err);
      showErrorToast("Error undropping students.");
    }
  }

  async addManualRegistration(params) {
    const {
      selectedTeacher,
      selectedClass,
      selectedParent,
      selectedChildren,
      studentBundles,
      extras,
      card,
      noProductsToShip
    } = params || {};
    try {
      const parent = { id: selectedParent?.id, infusionsoftId: selectedParent?.infusionsoftId };
      const students = selectedChildren?.map(student => ({ id: student?.id, infusionsoftId: student?.infusionsoftId }));
      const teacher = { id: selectedTeacher?.id, infusionsoftId: selectedTeacher?.infusionsoftId };
      const classObject = { id: selectedClass?.id || selectedClass?.classId, courseId: selectedClass?.courseId };

      const simplifiedProducts =
        Object.entries(extras)
          ?.filter(([id]) => !MaterialsStore?.shippingProductIds?.includes(id))
          ?.map(([id, quantity]) => ({ id, quantity })) || [];

      const simplifiedProductsFromBundles =
        Object.values(studentBundles)
          ?.map(b =>
            b?.productList
              ?.filter(id => !MaterialsStore?.shippingProductIds?.includes(id))
              ?.map(id => ({ id, quantity: 1 }))
          )
          ?.flat() || [];

      const shippingItemForCourse = MaterialsStore?.shippingItemsByCourseId?.[classObject?.courseId];
      const simplifiedShippingProduct = { id: shippingItemForCourse?.id, quantity: 1 };

      const combinedProductQuantitiesById = simplifiedProducts
        ?.concat(simplifiedProductsFromBundles)
        ?.concat(simplifiedShippingProduct)
        ?.filter(Boolean)
        ?.reduce((acc, next) => {
          acc[next?.id] = (acc[next?.id] || 0) + next?.quantity;
          return acc;
        }, {});
      const products = Object.entries(combinedProductQuantitiesById)?.map(([id, quantity]) => ({ id, quantity }));
      await request.post(`/registrations`, {
        body: {
          registrations: [{ teacher, class: classObject, students, parent, products, noProductsToShip }],
          card: { id: card?.id }
        }
      });
      showSuccessToast("Successfully created enrollment.");
      return { success: true };
    } catch (err) {
      let error;
      const { message } = err?.response?.data || {};
      if (message === "Error processing payment") {
        showErrorToast("Looks like there was an issue processing the card.");
        error =
          "Looks like there was an issue processing the card. Check your inputs and try again, or use another card.";
      } else {
        showErrorToast("Error creating enrollment.");
        error = "Looks like something went wrong. Reach out to T&T and we'll get things sorted.";
      }

      return { success: false, error };
    }
  }

  clear() {
    this.rawRecentRegistrations = [];
  }
}

export default new RegistrationsStore();
