import React, { useState } from "react";
import { observer } from "mobx-react";
import { Router, Redirect } from "@reach/router";
import { MAX_ALBUMS_IN_STACKS, ALBUM_ARTWORK_SIZE, ALBUM_ARTWORK_SPACING } from "../../constants";
import CurriculumStore from "../../stores/CurriculumStore";
import { Button } from "../-common";
import CurriculumDetails from "./CurriculumDetails";
import CourseDetails from "./CourseDetails";
import AlbumDetails from "./AlbumDetails";
import TrackDetails from "./TrackDetails";
import PlaylistDetails from "./PlaylistDetails";
import AddCurriculumModal from "./AddCurriculumModal";
import CurriculumRow from "./CurriculumRow";
import "./Curriculum.scss";

const Curriculum = observer(() => {
  const { curricula } = CurriculumStore || {};

  const [addCurriculumModalOpen, setAddCurriculumModalOpen] = useState(false);
  const openAddCurriculumModal = () => setAddCurriculumModalOpen(true);
  const closeAddCurriculumModal = () => setAddCurriculumModalOpen(false);

  const totalCurricula = curricula?.length || 0;
  const totalCourses = curricula?.reduce((acc, next) => acc + (next?.stats?.courses || 0), 0);
  const totalAlbums = curricula?.reduce((acc, next) => acc + (next?.stats?.albums || 0), 0);
  const totalTracks = curricula?.reduce((acc, next) => acc + (next?.stats?.tracks || 0), 0);

  const maxAlbums = Math.max(...curricula?.map(c => c?.stats?.albums), 0);
  const maxAlbumArtworkStacks = Math.floor(maxAlbums / MAX_ALBUMS_IN_STACKS);
  const artworkCellWidth = ALBUM_ARTWORK_SIZE + (maxAlbumArtworkStacks - 1) * 2 * ALBUM_ARTWORK_SPACING;
  const artworkCellHeight = ALBUM_ARTWORK_SIZE + ((maxAlbumArtworkStacks - 1) * ALBUM_ARTWORK_SPACING) / 2;

  const curriculumRows = curricula?.map(curriculum => (
    <CurriculumRow
      {...{ ...curriculum, cellWidth: artworkCellWidth, cellHeight: artworkCellHeight }}
      key={curriculum?.id}
    />
  ));
  const curriculumTableContent = curricula?.length ? (
    <div className="table-wrapper curriculum-table">
      <table>
        <thead>
          <tr>
            <th>Albums</th>
            <th>Title</th>
            <th>Stats</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{curriculumRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No curricula yet!</div>
      <Button action={openAddCurriculumModal} type="primary">
        Add Curriculum
      </Button>
    </div>
  );

  return (
    <div className="curriculum">
      <div className="title-row">
        <div className="title">Curriculum</div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Curricula</div>
            <div className="info-value">{totalCurricula}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Courses</div>
            <div className="info-value">{totalCourses}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Albums</div>
            <div className="info-value">{totalAlbums}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Tracks</div>
            <div className="info-value">{totalTracks}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action" onClick={openAddCurriculumModal}>
              <span>Add Curriculum</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={null}>
              <span>Reorder</span>
            </div>
          </div>
        </div>
      </div>
      <div className="header">Curricula</div>
      {curriculumTableContent}
      <AddCurriculumModal {...{ isOpen: addCurriculumModalOpen, close: closeAddCurriculumModal }} />
    </div>
  );
});

export default function CurriculumNav() {
  return (
    <Router>
      <Curriculum path="/" />
      <CurriculumDetails path=":curriculumId" />
      <CourseDetails path=":curriculumId/courses/:courseId" />
      <AlbumDetails path=":curriculumId/courses/:courseId/albums/:albumId" />
      <TrackDetails path=":curriculumId/courses/:courseId/albums/:albumId/tracks/:trackId" />
      <PlaylistDetails path=":curriculumId/courses/:courseId/albums/:albumId/playlists/:playlistId" />
      <Redirect from="*" to="/" noThrow />
    </Router>
  );
}
