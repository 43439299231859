import { autorun, makeAutoObservable } from "mobx";
import { format, lastDayOfMonth } from "date-fns";
import { downloadURLContents, request } from "../utils";
import AuthStore from "./AuthStore";

const classCSVHeaderRow = [
  "Teacher LPM ID",
  "Teacher Name",
  "Class ID",
  "Course Name",
  "Class Name",
  "Start Date",
  "End Date",
  "Students Enrolled"
];

const shipmentCSVHeaderRow = ["Shipment Date", "Total Unique Teachers", "Total Items"];

const sanitizeCSVField = field => (typeof field === "string" ? field?.replace(/"/, '""')?.replace(/\\n/, " ") : field);

class AnalyticsStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) {
        this.fetchOverallData();
        this.fetchCurriculumData();
        this.fetchTeacherData();
        this.fetchCurrentAndUpcomingData();
      } else {
        this.clear();
      }
    });
  }

  loadingOverall = false;
  overall = {};

  loadingCurriculum = false;
  curriculum = {};

  loadingTeacher = false;
  teacher = {};

  loadingCurrentAndUpcoming = false;
  currentAndUpcoming = {};

  async queryData(query) {
    try {
      return await request.post("/analytics/query", { body: { query } });
    } catch (err) {
      console.warn(err);
    }
  }

  async fetchOverallData() {
    try {
      this.loadingOverall = true;
      const overallData = await request.get("/analytics/overall");
      this.overall = overallData;
      this.loadingOverall = false;
    } catch (err) {
      console.warn(err);
      this.loadingOverall = false;
    }
  }

  async fetchCurriculumData() {
    try {
      this.loadingCurriculum = true;
      const curriculumData = await request.get("/analytics/curriculum");
      this.curriculum = curriculumData;
      this.loadingCurriculum = false;
    } catch (err) {
      console.warn(err);
      this.loadingCurriculum = false;
    }
  }

  async fetchTeacherData() {
    try {
      this.loadingTeacher = true;
      const teacherData = await request.get("/analytics/teachers");
      this.teacher = teacherData;
      this.loadingTeacher = false;
    } catch (err) {
      console.warn(err);
      this.loadingTeacher = false;
    }
  }

  async fetchCurrentAndUpcomingData() {
    try {
      this.loadingCurrentAndUpcoming = true;
      const upcomingAndCurrentData = await request.get("/analytics/current");
      this.currentAndUpcoming = upcomingAndCurrentData;
      this.loadingCurrentAndUpcoming = false;
    } catch (err) {
      console.warn(err);
      this.loadingCurrentAndUpcoming = false;
    }
  }

  async downloadClassesStartingInMonth(month) {
    try {
      const startDate = month?.toISOString()?.slice(0, 10);
      const endDate = lastDayOfMonth(month)?.toISOString()?.slice(0, 10);
      const classes = await request.get(`/analytics/classes/range?startDate=${startDate}&endDate=${endDate}`);
      const classRows = classes
        ?.sort((a, b) => (a?.startDate < b?.startDate ? -1 : 1))
        ?.map(
          ({ teacherLPMId, teacherName, classId, courseName, className, startDate, endDate, studentEnrollments }) => {
            return {
              "Teacher LPM ID": teacherLPMId,
              "Teacher Name": teacherName,
              "Class ID": classId,
              "Course Name": courseName,
              "Class Name": className,
              "Start Date": startDate,
              "End Date": endDate,
              "Students Enrolled": studentEnrollments
            };
          }
        );
      const rosterRows = classRows
        ?.map(row => '"' + classCSVHeaderRow?.map(header => sanitizeCSVField(row?.[header]))?.join('","') + '"')
        ?.join("\n");
      const rosterCSV = '"' + classCSVHeaderRow?.join('","') + '"\n' + rosterRows;
      const href = URL.createObjectURL(new Blob([rosterCSV]));
      downloadURLContents(href, `Classes starting in ${format(month, "MMMM yyyy")}`, ".csv");
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  async downloadShipmentsStartingInMonth(month) {
    try {
      const startDate = month?.toISOString()?.slice(0, 10);
      const endDate = lastDayOfMonth(month)?.toISOString()?.slice(0, 10);
      const shipments = await request.get(`/analytics/shipments/range?startDate=${startDate}&endDate=${endDate}`);

      const shipmentRows = shipments
        ?.sort((a, b) => (a?.shipmentDate < b?.shipmentDate ? -1 : 1))
        ?.map(({ shipmentDate, totalUniqueTeachers, totalItems }) => {
          return {
            "Shipment Date": shipmentDate,
            "Total Unique Teachers": totalUniqueTeachers,
            "Total Items": totalItems
          };
        });

      const shipmentRowsWithHeader = shipmentRows
        ?.map(row => '"' + shipmentCSVHeaderRow?.map(header => sanitizeCSVField(row?.[header]))?.join('","') + '"')
        ?.join("\n");
      const shipmentCSV = '"' + shipmentCSVHeaderRow?.join('","') + '"\n' + shipmentRowsWithHeader;
      const href = URL.createObjectURL(new Blob([shipmentCSV]));
      downloadURLContents(href, `Shipments for ${format(month, "MMMM yyyy")}`, ".csv");
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  clear() {
    this.loadingOverall = false;
    this.overall = {};
    this.curriculum = {};
    this.teacher = {};
    this.currentAndUpcoming = {};
  }
}

export default new AnalyticsStore();
