import React from "react";
import { observer } from "mobx-react";
import MaterialsStore from "../../../../../../../stores/MaterialsStore";
import "./ReviewScreen.scss";

const getItemCountsWithProductIds = items => {
  const countsByProductId = items.reduce((acc, next) => {
    acc[next?.productId] = { productId: next?.productId, quantity: (acc[next?.productId]?.quantity || 0) + 1 };
    return acc;
  }, {});
  return Object.values(countsByProductId);
};

const ReviewScreen = observer(({ currentMaterials, materials }) => {
  const orderId = materials?.[0]?.orderId;

  const currentMaterialIds = currentMaterials?.map(m => m?.id);
  const updatedMaterialIds = materials?.map(m => m?.id);

  const removedMaterials = currentMaterials?.filter(m => !updatedMaterialIds?.includes(m?.id));
  const groupedRemovedMaterials = getItemCountsWithProductIds(removedMaterials);

  const addedMaterials = materials?.filter(m => !currentMaterialIds?.includes(m?.id));
  const groupedAddedMaterials = getItemCountsWithProductIds(addedMaterials);

  const groupedUpdatedMaterials = getItemCountsWithProductIds(materials);

  const goToInfusionsoftOrder = () => {
    if (orderId) {
      window.open(`https://playmusic.infusionsoft.com/Job/manageJob.jsp?view=edit&ID=${orderId}`, "_blank");
    }
  };

  const infusionsoftOrderLink = orderId ? (
    <div className="go-to-infusionsoft" onClick={goToInfusionsoftOrder}>
      View InfusionSoft Order
    </div>
  ) : null;

  const removedMaterialRows = groupedRemovedMaterials.map(({ productId, quantity }) => (
    <div className="removed-material-row" key={productId}>
      - {MaterialsStore?.productsById?.[productId]?.name} x {quantity}
    </div>
  ));

  const addedMaterialRows = groupedAddedMaterials.map(({ productId, quantity }) => (
    <div className="added-material-row" key={productId}>
      + {MaterialsStore?.productsById?.[productId]?.name} x {quantity}
    </div>
  ));

  const updatedMaterialRows = groupedUpdatedMaterials.map(({ productId, quantity }) => (
    <div className="result-material-row" key={productId}>
      {MaterialsStore?.productsById?.[productId]?.name} x {quantity}
    </div>
  ));

  return (
    <div className="content review-screen">
      <div className="title">
        <span>Review Changes to Materials</span>
        {infusionsoftOrderLink}
      </div>
      <div className="section-container">
        <div className="section-title">Changes:</div>
        <div className="added-materials">{addedMaterialRows}</div>
        <div className="removed-materials">{removedMaterialRows}</div>
      </div>
      <div className="section-container">
        <div className="section-title">Result:</div>
        <div className="updated-materials">{updatedMaterialRows}</div>
      </div>
    </div>
  );
});

export default ReviewScreen;
