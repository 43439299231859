import React, { useRef, useState, useEffect } from "react";
import "./ImageFileSelect.scss";

const ImageFileSelect = ({ filePreviewURL, updateFile }) => {
  const fileInputRef = useRef();

  const [mediaError, setMediaError] = useState(false);
  const markMediaError = () => setMediaError(true);
  useEffect(() => {
    setMediaError(false);
  }, [filePreviewURL]);

  const showFileSelector = () => fileInputRef?.current?.click();

  if (filePreviewURL && !mediaError) {
    return (
      <div className="artwork-file-select">
        <img
          className="file-upload-preview"
          src={filePreviewURL}
          alt="Preview for newly uploaded album artwork"
          onClick={showFileSelector}
          onError={markMediaError}
        />
        <input ref={fileInputRef} type="file" className="hidden-file-input" onChange={updateFile} />
      </div>
    );
  }

  return (
    <div className="artwork-file-select">
      <div className="file-upload-zone" onClick={showFileSelector}>
        <div className="file-upload-zone-inner">
          <div className="file-upload-zone-label">Drop file here or click to upload</div>
        </div>
      </div>
      <input ref={fileInputRef} type="file" className="hidden-file-input" onChange={updateFile} />
    </div>
  );
};

export default ImageFileSelect;
