import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Link, navigate } from "@reach/router";
import { FiChevronRight } from "react-icons/fi";
import { formatMilliseconds } from "../../../utils";
import useCurriculum from "../../../hooks/useCurriculum";
import useCourse from "../../../hooks/useCourse";
import useAlbum from "../../../hooks/useAlbum";
import useTabs from "../../../hooks/useTabs";
import useModal from "../../../hooks/useModal";
import PlaylistsStore from "../../../stores/PlaylistsStore";
import { Spinner } from "../../-common";
import InfoTab from "./InfoTab";
import TracksTab from "./TracksTab";
import PlaylistsTab from "./PlaylistsTab";
import ActivityTab from "./ActivityTab";
import UpdateArtworkModalBody from "./UpdateArtworkModal";
import AddTrackModalBody from "./AddTrackModal";
import AddPlaylistModalBody from "./AddPlaylistModal";
import DeleteModal from "./ConfirmDeleteAlbumModal";
import "./AlbumDetails.scss";

const sections = [
  { title: "Info", component: InfoTab },
  { title: "Tracks", component: TracksTab },
  { title: "Playlists", component: PlaylistsTab },
  { title: "Activity", component: ActivityTab }
];

const ZeroState = ({ curriculum, course }) => {
  const { title: curriculumTitle, id: curriculumId } = curriculum || {};
  const { name: courseName, id: courseId } = course || {};
  const curriculumLink =
    curriculumTitle && curriculumId ? (
      <>
        <FiChevronRight className="arrow-icon" />
        <Link to={`/curriculum/${curriculumId}`}>{curriculumTitle}</Link>
      </>
    ) : null;
  const courseLink =
    curriculumLink && courseName && courseId ? (
      <>
        <FiChevronRight className="arrow-icon" />
        <Link to={`/curriculum/${curriculumId}/courses/${courseId}`}>{courseName}</Link>
      </>
    ) : null;
  return (
    <div className="album-details">
      <div className="title-row">
        <div className="title">
          <Link to="/curriculum">Curriculum</Link>
          {curriculumLink}
          {courseLink}
        </div>
      </div>
      <div className="loading-container">
        <Spinner alt />
      </div>
    </div>
  );
};

const Tab = ({ index, title, select, activeTabIndex }) => (
  <div className={`tab${index === activeTabIndex ? " active" : ""}`} onClick={select}>
    {title}
  </div>
);

function AlbumDetails({ curriculumId, courseId, albumId }) {
  const { playlistsByAlbumId } = PlaylistsStore || {};
  const { loading: loadingCurriculum, curriculum } = useCurriculum(curriculumId);
  const { loading: loadingCourse, course } = useCourse(courseId, { curriculumId });
  const { loading: loadingAlbum, album } = useAlbum(albumId, { curriculumId, courseId });

  const { title: curriculumTitle } = curriculum || {};
  const { name: courseName } = course || {};
  const { title, tracks, stats } = album || {};

  const [playlists, setPlaylists] = useState([]);
  useEffect(() => {
    const currentPlaylists = playlistsByAlbumId?.[albumId] || [];
    setPlaylists(currentPlaylists || []);
  }, [playlistsByAlbumId, albumId]);

  const [sortingPlaylists, setSortingPlaylists] = useState(false);
  const sortPlaylists = () => setSortingPlaylists(true);
  const savePlaylistSort = async () => {
    const playlistUpdates = playlists?.map((b, i) => ({ ...b, order: i }));
    await PlaylistsStore.updatePlaylistSort(playlistUpdates);
    setSortingPlaylists(false);
  };
  const cancelPlaylistSort = () => {
    setPlaylists(playlistsByAlbumId?.[albumId] || []);
    setSortingPlaylists(false);
  };

  const { activeTabIndex, tabKeys } = useTabs(sections);
  const selectTab = index => () => {
    navigate(`/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}?tab=${tabKeys[index]}`);
  };

  const { Modal: UpdateArtworkModal, openModal: openUpdateArtworkModal } = useModal(
    UpdateArtworkModalBody,
    { curriculum, course, album },
    { portalElementId: "update-album-artwork-modal" }
  );

  const { Modal: AddTrackModal, openModal: openAddTrackModal } = useModal(
    AddTrackModalBody,
    { curriculum, course, album },
    { portalElementId: "add-track-modal", big: true }
  );

  const { Modal: AddPlaylistModal, openModal: openAddPlaylistModal } = useModal(
    AddPlaylistModalBody,
    { curriculum, course, album },
    { portalElementId: "add-playlist-modal" }
  );

  const { Modal: ConfirmDeleteModal, openModal: openConfirmDeleteModal } = useModal(
    DeleteModal,
    { curriculum, course, album },
    { portalElementId: "confirm-delete-album-modal" }
  );

  const loading = loadingCurriculum || loadingCourse || loadingAlbum;
  if (loading) return <ZeroState {...{ curriculum, course }} />;

  const { totalTracks = 0 } = stats || {};
  const totalRunTime = tracks?.reduce((acc, next) => acc + next?.duration, 0);
  const totalPlaylists = playlists?.length;

  const tabBarStyles = { gridTemplateColumns: `repeat(${sections.length}, 1fr)` };
  const tabs = sections?.map(({ title }, index) => (
    <Tab {...{ index, title, select: selectTab(index), activeTabIndex }} key={title} />
  ));

  const activeSection = sections?.[activeTabIndex];
  const ActiveSection = activeSection?.component || (() => null);

  let tabActions;
  if (activeSection?.title === "Info") {
    tabActions = (
      <>
        <div className="action-row">
          <div className="action" onClick={openUpdateArtworkModal}>
            <span>Update Artwork</span>
          </div>
          <div className="action">
            <span>Edit Details</span>
          </div>
        </div>
        <div className="action-row">
          <div className="action" onClick={openConfirmDeleteModal}>
            <span>Delete Album</span>
          </div>
        </div>
      </>
    );
  } else if (activeSection?.title === "Tracks") {
    tabActions = (
      <>
        <div className="action-row">
          <div className="action" onClick={openAddTrackModal}>
            <span>Add Track</span>
          </div>
        </div>
      </>
    );
  } else if (activeSection?.title === "Playlists") {
    tabActions = sortingPlaylists ? (
      <>
        <div className="action-row">
          <div className="action" onClick={savePlaylistSort}>
            <span>Save Order</span>
          </div>
          <div className="action" onClick={cancelPlaylistSort}>
            <span>Cancel</span>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="action-row">
          <div className="action" onClick={openAddPlaylistModal}>
            <span>Add Playlist</span>
          </div>
          <div className="action" onClick={sortPlaylists}>
            <span>Reorder Playlists</span>
          </div>
        </div>
      </>
    );
  }

  const tracksTabProps = { addTrack: openAddTrackModal };
  const playlistsTabProps = { playlists, setPlaylists, sorting: sortingPlaylists, addPlaylist: openAddPlaylistModal };
  const activeSectionProps = { curriculum, course, album, tracks, ...tracksTabProps, ...playlistsTabProps };

  return (
    <div className="album-details">
      <div className="title-row">
        <div className="title">
          <Link to="/curriculum">Curriculum</Link> <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}`}>{curriculumTitle}</Link> <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}/courses/${courseId}`}>{courseName}</Link>{" "}
          <FiChevronRight className="arrow-icon" />
          <span>{title}</span>
        </div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Run Time</div>
            <div className="info-value">{formatMilliseconds(totalRunTime)}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Tracks</div>
            <div className="info-value">{totalTracks}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Playlists</div>
            <div className="info-value">{totalPlaylists}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          {tabActions}
        </div>
      </div>
      <div className="tabs-container">
        <div className="tab-bar" style={tabBarStyles}>
          {tabs}
        </div>
        <div className="active-tab-section">
          <ActiveSection {...activeSectionProps} />
        </div>
      </div>
      <UpdateArtworkModal />
      <AddTrackModal />
      <AddPlaylistModal />
      <ConfirmDeleteModal />
    </div>
  );
}

export default observer(AlbumDetails);
