import React, { useState } from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { FiBox } from "react-icons/fi";
import useModal from "../../../../hooks/useModal";
import MaterialsStore from "../../../../stores/MaterialsStore";
import { Button, Spinner } from "../../../-common";
import EditBundleModalBody from "./EditBundleModal";
import ConfirmDeleteBundleModalBody from "./ConfirmDeleteBundleModal";
import "./BundlesTab.scss";

const BundleRow = ({ id, courseId, name, price, media, productList, products }) => {
  const [mediaError, setMediaError] = useState(false);
  const markMediaError = () => setMediaError(true);

  const { Modal: EditBundleModal, openModal: openEditBundleModal } = useModal(
    EditBundleModalBody,
    { bundle: { id, courseId, name, media, productList } },
    { portalElementId: "edit-banner-modal" }
  );

  const { Modal: ConfirmDeleteBundleModal, openModal: openConfirmDeleteBundleModal } = useModal(
    ConfirmDeleteBundleModalBody,
    { bundle: { id, courseId, name, media, productList } },
    { portalElementId: "confirm-delete-album-modal" }
  );

  const productArtwork = mediaError ? (
    <div className="product-artwork placeholder">
      <FiBox size={36} />
    </div>
  ) : (
    <img className="product-artwork" src={media} alt={`Artwork for ${name}`} onError={markMediaError} />
  );

  const productLines = products?.length ? (
    products?.map(({ name }) => <div className="product">{name}</div>)
  ) : (
    <div className="aux">(no products)</div>
  );

  const priceLabel = numeral(price / 100).format("$0,00.00");

  return (
    <tr>
      <td>
        <div className="cell-contents artwork">
          <div>{productArtwork}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">{name}</div>
      </td>
      <td>
        <div className="cell-contents">{productLines}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div>{priceLabel}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={openEditBundleModal}>
            Edit Details
          </div>
          <div className="action" onClick={openConfirmDeleteBundleModal}>
            Remove
          </div>
        </div>
      </td>
      <EditBundleModal />
      <ConfirmDeleteBundleModal />
    </tr>
  );
};

const BundlesTab = ({ curriculum, course, openAddMaterialModal }) => {
  const { id: curriculumId } = curriculum || {};
  const { id: courseId } = course || {};

  const bundles = MaterialsStore?.bundlesByCourseId?.[courseId];

  const bundleRows = bundles?.map(bundle => <BundleRow {...{ ...bundle, curriculumId, courseId }} key={bundle?.id} />);

  const content = bundleRows?.length ? (
    <div className="table-wrapper bundles-table">
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Products</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{bundleRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No bundles for this course yet!</div>
      <Button action={openAddMaterialModal} type="primary">
        Add Bundle
      </Button>
    </div>
  );

  if (MaterialsStore?.loading) {
    return (
      <div className="bundles-tab bundles-tab-loading">
        <Spinner alt />
      </div>
    );
  }

  return <div className="bundles-tab">{content}</div>;
};

export default observer(BundlesTab);
