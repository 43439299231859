import { makeAutoObservable, autorun } from "mobx";
import { request } from "../utils";
import { showErrorToast, showSuccessToast } from "../services/ToastService";
import AuthStore from "./AuthStore";

export const promoCodeTypeOptions = ["Percent Off", "Dollar Off"];
export const statusOptions = ["Active", "Inactive"];

class PromoCodesStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (AuthStore?.authenticated) {
        this.fetchPromoCodes();
      } else {
        this.clear();
      }
    });
  }

  loading = false;
  rawPromoCodes = [];

  get promoCodes() {
    return this.rawPromoCodes;
  }

  async fetchPromoCodes() {
    this.loading = true;
    try {
      const promoCodes = await request.get(`/promo`);
      this.rawPromoCodes = promoCodes;
      this.loading = false;
      return this.rawPromoCodes;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async addPromoCode({ code, type, value, description, status }) {
    try {
      const newPromoCode = await request.post("/promo", { body: { code, type, value, description, status } });
      this.rawPromoCodes = this.rawPromoCodes.concat(newPromoCode);
      showSuccessToast("Promo code added successfully.");
      return newPromoCode;
    } catch (err) {
      console.warn("Error creating promo code:", err);
      showErrorToast("Error adding promo code.");
    }
  }

  async updatePromoCode(promoCodeId, { code, type, value, description, status }) {
    const currentPromoCodes = this.rawPromoCodes.slice();

    try {
      const updatedPromoCode = await request.put(`/promo/${promoCodeId}`, {
        body: { updates: { code, type, value, description, status } }
      });
      this.rawPromoCodes = this.rawPromoCodes?.map(p => {
        if (p?.id === updatedPromoCode?.id) return updatedPromoCode;
        return p;
      });

      showSuccessToast("Promo code updated successfully.");
      return updatedPromoCode;
    } catch (err) {
      this.rawPromoCodes = currentPromoCodes;
      console.warn("Error updating promo code:", err);
      showErrorToast("Error updating promo code.");
    }
  }

  async deletePromoCode(promoCodeId) {
    try {
      await request.delete(`/promo/${promoCodeId}`);
      this.rawPromoCodes = this.rawPromoCodes.filter(pc => pc?.id !== promoCodeId);
      showSuccessToast("Promo code deleted successfully.");
      return true;
    } catch (err) {
      console.warn("Error deleting promo code:", err);
      showErrorToast("Error deleting promo code.");
      return false;
    }
  }

  clear() {
    this.rawPromoCodes = [];
  }
}

export default new PromoCodesStore();
