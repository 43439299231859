import React from "react";
import { observer } from "mobx-react";
import { FiCheck } from "react-icons/fi";
// import { evaluateUnstableData } from "../../../../../utils";
import useModal from "../../../../../hooks/useModal";
import MaterialsStore from "../../../../../stores/MaterialsStore";
// import PeopleStore from "../../../../../stores/PeopleStore";
// import ClassesStore from "../../../../../stores/ClassesStore";
// import { Spinner } from "../../../../-common";
import DropStudentsModalBody from "../../../../-common/DropStudentsModal";
import EditMaterialsModalBody from "./EditMaterialsModal";
import ReassignStudentsModalBody from "./ReassignStudentsModal";
// import OverwriteInfusionsoftIdModalBody from "./OverwriteInfusionsoftIdModal";
import ParentCell from "./ParentCell";
import StudentItem from "./StudentItem";
import "./RosterRow.scss";

const RosterRow = observer(
  ({ teacher, class: classItem, parent, materials, students, classId, courseId, forDroppedStudents }) => {
    // const [loading, setLoading] = useState(false);
    const studentItems = students?.map(student => <StudentItem student={student} key={student?.id} />);

    // const unstableData = evaluateUnstableData({ parent, students });

    const { Modal: EditMaterialsModal, openModal: openEditMaterialsModal } = useModal(
      EditMaterialsModalBody,
      { teacher, class: classItem, parent, materials, courseId },
      { portalElementId: "edit-materials-modal" }
    );

    const { Modal: DropStudentsModal, openModal: openDropStudentsModal } = useModal(
      DropStudentsModalBody,
      { teacher, class: classItem, parent, students, materials, forDroppedStudents },
      { portalElementId: "drop-students-modal" }
    );

    const { Modal: ReassignStudentsModal, openModal: openReassignStudentsModal } = useModal(
      ReassignStudentsModalBody,
      { teacher, class: classItem, parent, students, materials },
      { portalElementId: "reassign-students-modal" }
    );

    // const { Modal: OverwriteInfusionsoftIdModal, openModal: openOverwriteInfusionsoftIdModal } = useModal(
    //   OverwriteInfusionsoftIdModalBody,
    //   { students, errors: unstableData, classId },
    //   { portalElementId: "overwrite-infusionsoft-id-modal" }
    // );

    // const fixData = async () => {
    //   const needLPMIdInput = unstableData.find(d => d.useCase === "missingParentConnection");
    //   if (needLPMIdInput) {
    //     openOverwriteInfusionsoftIdModal(true);
    //   } else {
    //     setLoading(true);
    //     await PeopleStore?.fixUserData(unstableData);
    //     await await ClassesStore.fetchStudentsForClassById(classId);
    //     setLoading(false);
    //   }
    // };

    const curatedMaterials = materials?.reduce((acc, next) => {
      acc[next?.productId] = {
        quantity: (acc?.[next?.productId]?.quantity || 0) + 1,
        fulfilled:
          acc?.[next?.productId]?.fulfilled != null
            ? acc?.[next?.productId]?.fulfilled && ["processed", "pending return"]?.includes(next?.status)
            : ["processed", "pending return"]?.includes(next?.status)
      };
      return acc;
    }, {});
    const materialInfoPairs = Object.entries(curatedMaterials)
      ?.map(([productId, info]) => [MaterialsStore?.productsById?.[productId]?.name, info])
      ?.filter(([productName]) => !productName?.toLowerCase()?.includes("shipping"))
      ?.filter(([productName]) => !productName?.toLowerCase()?.includes("fee"))
      ?.filter(([productName]) => !productName?.toLowerCase()?.includes("tax"));

    const materialsList = materialInfoPairs?.map(([productName, { quantity, fulfilled }]) => {
      const fulfilledCheckmark = fulfilled ? <FiCheck className="fulfilled-checkmark" /> : null;
      return (
        <div className="product-container" key={productName}>
          {fulfilledCheckmark}
          <div className="product-name">{productName}</div>
          <div>x</div>
          <div className="product-quantity">{quantity}</div>
        </div>
      );
    });

    // const fixDataActionComponent = unstableData?.length ? (
    //   loading ? (
    //     <Spinner action={true} />
    //   ) : (
    //     <div className="action" onClick={() => fixData()}>
    //       Attempt Data Fix
    //     </div>
    //   )
    // ) : null;

    const dropStudentActionLabel = `${forDroppedStudents ? "Undrop" : "Drop"} Student${
      students?.length > 1 ? "(s)" : ""
    }`;
    const reassignStudentActionLabel = `Reassign Student${students?.length > 1 ? "(s)" : ""}`;

    return (
      <tr className="roster-row">
        <td>
          <ParentCell {...parent} />
        </td>
        <td>{materialsList}</td>
        <td>
          <div className="cell-contents students">{studentItems}</div>
        </td>
        <td>
          <div className="cell-contents">
            <a href={`mailto:${parent?.email}`} className="action">
              Email Parent
            </a>
            {!forDroppedStudents && (
              <div className="action" onClick={openEditMaterialsModal}>
                Edit Materials
              </div>
            )}
            <div className="action" onClick={openDropStudentsModal}>
              {dropStudentActionLabel}
            </div>
            <div className="action" onClick={openReassignStudentsModal}>
              {reassignStudentActionLabel}
            </div>
            {/* {fixDataActionComponent} */}
          </div>
        </td>
        <EditMaterialsModal />
        <DropStudentsModal />
        <ReassignStudentsModal />
        {/* <OverwriteInfusionsoftIdModal /> */}
      </tr>
    );
  }
);

export default RosterRow;
