import React, { useState } from "react";
import { observer } from "mobx-react";
import PeopleStore from "../../../../../stores/PeopleStore";
import { Button, PersonSearchInput, RadioGroup, Spinner } from "../../../../-common";
import "./MergeAccountModal.scss";

const MERGE_OPTIONS = [
  "Merge this account into the selected account. The selected account will remain and this account will no longer exist.",
  "Merge the selected account into this account. This account will remain and the selected account will no longer exist."
];

function MergeAccountModal({ user, close }) {
  const { firstName, lastName, id, infusionsoftId } = user || {};

  const [selectedMergeOption, setSelectedMergeOption] = useState();
  const isCurrentToTarget = selectedMergeOption === MERGE_OPTIONS[0];
  const [otherAccount, setOtherAccount] = useState();

  const [loading, setLoading] = useState(false);

  const mergeAccountsEnabled = selectedMergeOption && !!otherAccount;
  const mergeAccounts = async () => {
    setLoading(true);
    await PeopleStore?.mergeUsers({ isCurrentToTarget, current: user, target: otherAccount });
    await new Promise(r => setTimeout(r, 1500));
    setLoading(false);
    close();
  };

  const currentAccountIdLabel = `${id} / ${infusionsoftId}`;
  const currentAccount = `${firstName} ${lastName} (${currentAccountIdLabel})`;
  const targetAccountIdLabel = `${otherAccount?.userId} / ${otherAccount?.infusionsoftId}`;
  const targetAccount = `${otherAccount?.firstName} ${otherAccount?.lastName} (${targetAccountIdLabel})`;
  const summary = mergeAccountsEnabled ? (
    <div className="input-wrapper">
      <div className="input-title">Summary</div>
      <div>{isCurrentToTarget ? currentAccount : targetAccount}</div>
      <div className="summary-divider-text">will be merged into</div>
      <div>{isCurrentToTarget ? targetAccount : currentAccount}</div>
      <div className="summary-divider-text">and account ID</div>
      <div>{isCurrentToTarget ? currentAccountIdLabel : targetAccountIdLabel}</div>
      <div className="summary-divider-text">will no longer exist.</div>
    </div>
  ) : null;

  const actionButtonStyles = loading ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = loading ? <Spinner /> : "Submit";

  return (
    <div className="merge-account-modal">
      <div className="content">
        <div className="title">Merge Accounts</div>
        <div className="message">
          You're about to merge this account with the selected account. This action is permanent, so be sure that this
          is what you want to do.
        </div>
        <div className="input-wrapper">
          <div className="input-title">Method</div>
          <RadioGroup options={MERGE_OPTIONS} value={selectedMergeOption} onSelect={setSelectedMergeOption} />
        </div>
        <div className="input-wrapper">
          <div className="input-title">Other Account</div>
          <PersonSearchInput hasValue={true} value={otherAccount} onSelect={setOtherAccount} />
        </div>
        {summary}
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className="send-request-button"
            action={mergeAccounts}
            disabled={!mergeAccountsEnabled}
            style={actionButtonStyles}
          >
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(MergeAccountModal);
