import React from "react";
import { Overall, SB, LPM, Presto, Online, Teachers } from "./collections";
import "./EnrollmentsTab.scss";

export default function EnrollmentsTab() {
  return (
    <div className="analytics-enrollments-tab">
      <div className="collections-container">
        <Overall />
        <SB />
        <LPM />
        <Presto />
        <Online />
        <Teachers />
      </div>
    </div>
  );
}
