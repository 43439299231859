import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import RegistrationsStore from "../../../stores/RegistrationsStore";
import MaterialsStore from "../../../stores/MaterialsStore";
import { Spinner, Button, PieTimer, MultiSelect } from "../../-common";
import "./DropStudentsModal.scss";

const getStudentOptionLabel = ({ value }) => {
  if (value?.id === "Select All") return "Select All";
  return `${value?.firstName} ${value?.lastName || ""}`;
};
const getMaterialOptionLabel = ({ value }) => {
  if (value?.productId === "Select All") return "Select All";
  const product = MaterialsStore?.productsById?.[value?.productId];
  return product?.name;
};

function DropStudentsModal({ teacher, class: classItem, parent, students, materials, close, forDroppedStudents }) {
  const { firstName: teacherFirstName, lastName: teacherLastName } = teacher || {};
  const { name: className } = classItem || {};

  const [dropEnabled, setDropEnabled] = useState(false);
  const enableDrop = () => setDropEnabled(true);

  const [dropping, setDropping] = useState(false);

  const [studentsToDrop, setStudentsToDrop] = useState([]);
  const updateStudentsToDrop = updatedStudents => {
    if (updatedStudents?.find(us => us?.id === "Select All")) {
      setStudentsToDrop(students);
    } else {
      setStudentsToDrop(updatedStudents);
    }
  };
  useEffect(() => {
    if (students?.length === 1) setStudentsToDrop(students);
  }, [students]);

  const [materialsToRemove, setMaterialsToRemove] = useState([]);
  const updateMaterialsToRemove = updatedMaterials => {
    if (updatedMaterials?.find(um => um?.productId === "Select All")) {
      setMaterialsToRemove(materials);
    } else {
      setMaterialsToRemove(updatedMaterials);
    }
  };

  const dropStudent = async () => {
    setDropping(true);
    await RegistrationsStore.dropStudents({
      teacherId: teacher?.id,
      classId: classItem?.id || classItem?.classId,
      parentId: parent?.id,
      studentIds: studentsToDrop?.map(s => s?.id),
      itemsToRemove: materialsToRemove?.map(({ userId, orderId, id }) => ({ userId, orderId, id }))
    });
    setDropping(false);
    close();
  };

  const unDropStudent = async () => {
    setDropping(true);
    await RegistrationsStore.undropStudents({
      teacherId: teacher?.id,
      classId: classItem?.id || classItem?.classId,
      parentId: parent?.id,
      studentIds: studentsToDrop?.map(s => s?.id),
      itemsToAddBack: materialsToRemove?.map(({ userId, orderId, id }) => ({ userId, orderId, id }))
    });
    setDropping(false);
    close();
  };

  let studentOptions = [];
  if (students?.length) studentOptions = students;
  if (studentsToDrop?.length !== students?.length) {
    studentOptions = [{ id: "Select All" }]?.concat(studentOptions);
  }

  let materialsOptions = [];
  if (materials?.length) materialsOptions = materials;
  if (materialsToRemove?.length !== materials?.length) {
    materialsOptions = [{ productId: "Select All" }]?.concat(materialsOptions);
  }

  const studentsSection =
    students?.length !== 1 ? (
      <MultiSelect
        placeholder={`Select Students to ${forDroppedStudents ? "Undrop" : "Drop"}`}
        options={studentOptions}
        value={studentsToDrop}
        onChange={updateStudentsToDrop}
        formatOptionLabel={getStudentOptionLabel}
        isDisabled={students?.length === 1}
      />
    ) : (
      <div className="student-name">
        {students?.[0]?.firstName} {students?.[0]?.lastName}
      </div>
    );

  const materialsSection = materials?.length ? (
    <div className="section materials-to-remove">
      <div className="section-header">MATERIALS TO REMOVE</div>
      <MultiSelect
        placeholder="Select Materials to Remove"
        options={materialsOptions}
        value={materialsToRemove}
        onChange={updateMaterialsToRemove}
        formatOptionLabel={getMaterialOptionLabel}
        maxMenuHeight={160}
      />
    </div>
  ) : null;

  const cancelButtonStyles = dropping ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = dropping ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = dropping ? <Spinner /> : `${forDroppedStudents ? "Undrop" : "Drop"} Student`;
  const actionButton = dropEnabled ? (
    <Button type="negative" action={forDroppedStudents ? unDropStudent : dropStudent} style={actionButtonStyles}>
      {actionButtonContent}
    </Button>
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--red)" width={24} height={24} duration={3000} onComplete={enableDrop} />
    </div>
  );

  return (
    <div className="drop-students-modal">
      <div className="content">
        <div className="title">{forDroppedStudents ? "Undrop Students" : "Drop Students"}</div>
        <div className="section class-info">
          <div className="section-header">CLASS INFORMATION</div>
          <div>
            Teacher: {teacherFirstName} {teacherLastName}
          </div>
          <div>Class: {className}</div>
        </div>
        <div className="section students-to-drop">
          <div className="section-header">
            STUDENT{students?.length !== 1 ? "S" : ""} TO {forDroppedStudents ? "UNDROP" : "DROP"}
          </div>
          {studentsSection}
        </div>
        {forDroppedStudents ? null : materialsSection}
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">{actionButton}</div>
      </div>
    </div>
  );
}

export default observer(DropStudentsModal);
