import { format, parseISO } from "date-fns";
import numeral from "numeral";

export const formatDateTickAtMonth = t => {
  try {
    return format(parseISO(t), "MMM ''yy");
  } catch {
    return t;
  }
};

export const formatDateTickAtDay = t => {
  try {
    return format(parseISO(t), "MMM do");
  } catch {
    return t;
  }
};

export const formatTickAsCurrency = t => {
  return numeral(t)
    .format("($0.00a)")
    .replace(/\.?0+([a-z])?$/g, (_, a) => a || "");
};

export const formatTickAsPercentage = t => {
  return numeral(t)
    .format("(0.0%)")
    .replace(/\.?0+([a-z%])?$/g, (_, a) => a || "");
};

export const abbreviateTick = t => {
  return numeral(t)
    .format("0.00a")
    .replace(/\.?0+([a-z])?$/g, (_, a) => a || "");
};

export const tooltipFormatter = ({
  categorical,
  currency,
  percentage,
  dateGrain,
  lines,
  barsAreCurrency,
  linesAreCurrency
}) => {
  return (value, name, props) => {
    if (name === "name") {
      const dateFormatter = dateGrain === "day" ? formatDateTickAtDay : formatDateTickAtMonth;
      return categorical ? value : dateFormatter(value);
    } else if (name === "value") {
      return [
        currency
          ? numeral(value).format("($0,0.00)")
          : numeral(value)
              .format("(0,0.00)")
              .replace(/\.?0+([a-z])?$/g, (_, a) => a || ""),
        "Total"
      ];
    } else if (lines) {
      if (lines?.includes(props?.dataKey)) {
        if (linesAreCurrency) {
          return numeral(value).format("($0,0.00)");
        } else {
          return numeral(value)
            .format("(0,0.00)")
            .replace(/\.?0+([a-z])?$/g, (_, a) => a || "");
        }
      } else {
        if (barsAreCurrency) {
          return numeral(value).format("($0,0.00)");
        } else {
          return numeral(value)
            .format("(0,0.00)")
            .replace(/\.?0+([a-z])?$/g, (_, a) => a || "");
        }
      }
    } else if (currency) {
      return numeral(value).format("($0,0.00)");
    } else if (percentage) {
      return numeral(value).format("(0.0%)");
    } else {
      return numeral(value)
        .format("(0,0.00)")
        .replace(/\.?0+([a-z])?$/g, (_, a) => a || "");
    }
  };
};
