import React from "react";
import { observer } from "mobx-react";
import { Router, Redirect, LocationProvider } from "@reach/router";
import { toast } from "react-toastify";
import { FiX } from "react-icons/fi";
import AuthStore from "../stores/AuthStore";
import UserStore from "../stores/UserStore";
import { Spinner } from "../components/-common";
import Sidebar from "../components/Sidebar";
import AuthRoutes from "../components/@Auth";
import People from "../components/@People";
import Enrollment from "../components/@Enrollment";
import Curriculum from "../components/@Curriculum";
import Products from "../components/@Products";
import Mobile from "../components/@Mobile";
import Shipping from "../components/@Shipping";
import Analytics from "../components/@Analytics";
import PromoCodes from "src/components/@PromoCodes";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const ToastClose = ({ closeToast }) => (
  <div className="toast-close">
    <FiX size={20} onClick={closeToast} />
  </div>
);

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 5000,
  closeButton: <ToastClose />,
  className: "lpm-toast"
});

function Main() {
  return (
    <div className="main-content">
      <Sidebar />
      <Router>
        <People path="/people/*" />
        <Enrollment path="/enrollment/*" />
        <Curriculum path="/curriculum/*" />
        <Products path="/products/*" />
        <PromoCodes path="/promo/*" />
        <Mobile path="/mobile" />
        <Shipping path="/shipping/*" />
        <Analytics path="/analytics" />
        <Redirect from="*" to="/people" noThrow />
      </Router>
    </div>
  );
}

const App = observer(() => {
  if (!AuthStore.authDetermined || (AuthStore.authenticated && !UserStore?.user?.id && UserStore.loading)) {
    return (
      <div className="root loading">
        <Spinner alt={true} />
      </div>
    );
  }

  if (AuthStore.authenticated) return <Main />;
  return <AuthRoutes />;
});

function ProvidersWrappedApp() {
  return (
    <LocationProvider>
      <App />
    </LocationProvider>
  );
}

export default ProvidersWrappedApp;
