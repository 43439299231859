import React, { useState } from "react";
import { observer } from "mobx-react";
import { STATES } from "../../../../../constants";
import PeopleStore from "../../../../../stores/PeopleStore";
import { Button, Input, Select, Spinner } from "../../../../-common";
import "./UpdateStudioLocationModal.scss";

const locationIsValid = l =>
  !!l?.name &&
  !!l?.line1 &&
  !!l.locality &&
  !!l.region &&
  !!l?.zipCode?.match(/^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/);

const locationHasChanged = (l1, l2) => {
  return (
    l1?.name !== l2?.name ||
    l1?.line1 !== l2?.line1 ||
    l1?.locality !== l2?.locality ||
    l1?.region !== l2?.region ||
    l1?.zipCode !== l2?.zipCode
  );
};

function UpdateStudioLocationModal({ user, location, close }) {
  const { id: userId, firstName } = user || {};
  const [updatedLocation, setUpdatedLocation] = useState({ ...location });
  const updateLocationField = fieldName => {
    return ({ target }) => setUpdatedLocation(prev => ({ ...prev, [fieldName]: target.value }));
  };
  const updateSimpleLocationField = fieldName => {
    return value => setUpdatedLocation(prev => ({ ...prev, [fieldName]: value }));
  };

  const [loading, setLoading] = useState(false);

  const updateLocationEnabled = locationHasChanged(updatedLocation, location) && locationIsValid(updatedLocation);
  const updateLocation = async () => {
    if (updateLocationEnabled) {
      setLoading(true);
      await PeopleStore.updateStudioLocationForTeacher({
        userId,
        currentStudio: { ...location },
        updatedStudio: { ...updatedLocation }
      });
      setLoading(false);
      close();
    }
  };

  const actionButtonStyles = loading ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = loading ? <Spinner /> : "Submit";

  return (
    <div className="update-studio-location-modal">
      <div className="content">
        <div className="title">
          Update {firstName}'s{location?.name ? ' "' + location.name + '"' : "Studio"} Location
        </div>
        <div className="message">
          This will change the location here and on every class that's taught at this studio.
        </div>
        <div className="section-container">
          <div className="section-title">Session Location</div>
          <Input value={updatedLocation?.name} onChange={updateLocationField("name")} placeholder="Location Name" />
        </div>
        <div className="section-container">
          <Input value={updatedLocation?.line1} onChange={updateLocationField("line1")} placeholder={"Address"} />
        </div>
        <div className="section-container double">
          <div>
            <Input value={updatedLocation?.locality} onChange={updateLocationField("locality")} placeholder={"City"} />
          </div>
          <div>
            <Select
              options={STATES}
              value={updatedLocation?.region}
              onChange={updateSimpleLocationField("region")}
              placeholder={"State"}
            />
          </div>
          <div>
            <Input value={updatedLocation?.zipCode} onChange={updateLocationField("zipCode")} placeholder={"Zip"} />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className="send-request-button"
            action={updateLocation}
            disabled={!updateLocationEnabled}
            style={actionButtonStyles}
          >
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(UpdateStudioLocationModal);
