import { makeAutoObservable, autorun } from "mobx";
import groupBy from "lodash.groupby";
import uniqBy from "lodash.uniqby";
import { ENV } from "../config";
import { request } from "../utils";
import { showErrorToast, showSuccessToast } from "../services/ToastService";
import AuthStore from "./AuthStore";
import CurriculumStore from "./CurriculumStore";

export const filterOutShippingProducts = p => !p?.name?.toLowerCase()?.includes("shipping");
const filterToOnlyShippingProducts = p => p?.name?.toLowerCase()?.includes("shipping");

export const filterOutLateFeeProducts = p => !p?.name?.toLowerCase()?.includes("fee - processing fee");
const filterToOnlyLateFeeProducts = p => p?.name?.toLowerCase()?.includes("fee - processing fee");

export const filterOutTaxProducts = p => !p?.name?.toLowerCase()?.includes("tax");
// const filterToOnlyTaxProducts = p => p?.name?.toLowerCase()?.includes("tax");

const augmentProduct = p => ({
  ...p,
  media: `https://${ENV}-lpm-assets.b-cdn.net/products/${p?.id}?m=${p?.modified}`,
  curricula: uniqBy(
    p?.courseIds?.map(courseId => CurriculumStore?.curriculaByCourseId?.[courseId]),
    "id"
  )
});

const augmentBundle = b => ({
  ...b,
  media: `https://${ENV}-lpm-assets.b-cdn.net/products/${b?.id}?m=${b?.modified}`,
  productList: b?.productList || []
});

class MaterialsStore {
  constructor() {
    makeAutoObservable(this);
    autorun(() => {
      if (AuthStore?.authenticated) {
        this.fetchProducts();
        this.fetchBundles();
      } else {
        this.clear();
      }
    });
  }

  loading = false;

  rawProducts = [];
  rawBundles = [];

  get products() {
    return this.rawProducts
      ?.filter(filterOutShippingProducts)
      ?.filter(filterOutLateFeeProducts)
      ?.filter(filterOutTaxProducts)
      ?.map(augmentProduct);
  }

  get productsById() {
    return Object.fromEntries(this.rawProducts.map(rp => [rp?.id, rp]));
  }

  get productsByCourseId() {
    const allCourseIds = Array.from(new Set(this.products?.map(p => p?.courseIds)?.flat()));
    const courseProductsEntries = allCourseIds?.map(courseId => [
      courseId,
      this.products?.filter(p => p?.courseIds?.includes(courseId))
    ]);
    return Object.fromEntries(courseProductsEntries);
  }

  get allProducts() {
    return this.rawProducts?.map(augmentProduct);
  }

  get allProductsByCourseId() {
    const allCourseIds = Array.from(new Set(this.allProducts?.map(p => p?.courseIds)?.flat()));
    const courseProductsEntries = allCourseIds?.map(courseId => [
      courseId,
      this.allProducts
        ?.filter(p => p?.courseIds?.includes(courseId))
        ?.sort((a, b) => (a?.courseSortOrder[courseId] < b?.courseSortOrder[courseId] ? -1 : 1))
        ?.map(({ courseSortOrder, courseIds, ...p }) => ({ ...p, courseId }))
    ]);
    return Object.fromEntries(courseProductsEntries);
  }

  get bundles() {
    return this.rawBundles
      ?.map(augmentBundle)
      ?.map(b => ({
        ...b,
        products: b?.productList?.map(pid => this.products?.find(p => p?.id === pid))?.filter(Boolean)
      }))
      ?.map(b => ({
        ...b,
        price: b?.products?.reduce((acc, next) => acc + next?.price, 0)
      }));
  }

  get bundlesByCourseId() {
    return groupBy(this.bundles, "courseId");
  }

  get shippingItemsByCourseId() {
    const shippingCostsByCourseEntries = Object.entries(this.allProductsByCourseId)?.map(([courseId, materials]) => {
      const shippingForCourse = materials?.filter(filterToOnlyShippingProducts)?.[0];
      return [courseId, shippingForCourse];
    });
    return Object.fromEntries(shippingCostsByCourseEntries);
  }

  get shippingProductIds() {
    return this.allProducts?.filter(filterToOnlyShippingProducts)?.map(p => p?.id);
  }

  get lateFeeItemsByCourseId() {
    const lateFeeCostsByCourseEntries = Object.entries(this.allProductsByCourseId)?.map(([courseId, materials]) => {
      const lateFeeForCourse = materials?.filter(filterToOnlyLateFeeProducts)?.[0];
      return [courseId, lateFeeForCourse];
    });
    return Object.fromEntries(lateFeeCostsByCourseEntries);
  }

  get lateFeeProductIds() {
    return this.allProducts?.filter(filterToOnlyLateFeeProducts)?.map(p => p?.id);
  }

  async fetchProducts() {
    this.loading = true;
    try {
      const products = await request.get(`/materials/products`);
      this.rawProducts = products;
      this.loading = false;
      return products
        ?.filter(filterOutShippingProducts)
        ?.filter(filterOutLateFeeProducts)
        ?.filter(filterOutTaxProducts)
        ?.map(augmentProduct);
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async addProduct({ file, name, price }) {
    try {
      const newProduct = await request.post("/materials/products", { body: { name, price } });
      const fileData = await request.get(`/files/product?productId=${newProduct.id}`, {
        headers: { "file-type": file.type }
      });
      const { uploadURL: productFileUploadURL } = fileData || {};
      const uploadResponse = await fetch(productFileUploadURL, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type, "x-amz-acl": "public-read" }
      });
      if (!uploadResponse.ok) {
        await request.delete(`/materials/products/${newProduct.id}`);
        throw new Error("Upload failed.");
      }
      this.rawProducts = this.rawProducts.concat(newProduct);
      showSuccessToast("Product added successfully.");
      return newProduct;
    } catch (err) {
      console.warn("Error creating product:", err);
      showErrorToast("Error adding product.");
    }
  }

  async updateProduct(productId, courseId, { file, name, price }) {
    const currentProducts = this.rawProducts.slice();

    try {
      if (file) {
        const fileData = await request.get(`/files/product?productId=${productId}`, {
          headers: { "file-type": file.type }
        });
        const { uploadURL: productFileUploadURL } = fileData || {};
        const uploadResponse = await fetch(productFileUploadURL, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type, "x-amz-acl": "public-read" }
        });
        if (!uploadResponse.ok) throw new Error("Updating product image failed.");
      }

      const updatedProduct = await request.put(`/materials/products/${productId}?courseId=${courseId}`, {
        body: { updates: { name, price } }
      });
      this.rawProducts = this.rawProducts?.map(p => {
        if (p?.id === updatedProduct?.id) return updatedProduct;
        return p;
      });

      showSuccessToast("Product updated successfully.");
      return updatedProduct;
    } catch (err) {
      this.rawProducts = currentProducts;
      console.warn("Error updating product:", err);
      showErrorToast("Error updating product.");
    }
  }

  async deleteProduct(productId) {
    try {
      await request.delete(`/materials/products/${productId}`);
      this.rawProducts = this.rawProducts.filter(b => b?.id !== productId);
      showSuccessToast("Product deleted successfully.");
      return true;
    } catch (err) {
      console.warn("Error deleting product:", err);
      showErrorToast("Error deleting product.");
      return false;
    }
  }

  async addCourseProducts(courseId, productsToAdd) {
    try {
      const materials = productsToAdd?.map(p => ({
        courseId,
        productId: p?.id,
        name: p?.name,
        price: p?.price,
        longDescription: p?.longDescription,
        shortDescription: p?.shortDescription
      }));
      await request.post(`/courses/${courseId}/materials`, { body: { materials } });
      await this.fetchProducts();
      showSuccessToast(`Successfully added products to course.`);
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error adding products to course.`);
    }
  }

  async updateCourseProductsSort({ courseId, products }) {
    const currentProducts = this.rawProducts.slice();
    try {
      const productUpdates = products?.map(({ media, active, id, curricula, ...product }) => ({
        ...product,
        productId: id
      }));
      await request.put(`/courses/${courseId}/materials/sort`, { body: { products: productUpdates } });
      await this.fetchProducts();
      showSuccessToast("Products sorted successfully.");
    } catch (err) {
      this.rawProducts = currentProducts;
      console.warn("Error updating product sort:", err);
      showErrorToast("Error sorting products.");
    }
  }

  async deleteCourseProduct(courseId, productId) {
    try {
      await request.delete(`/courses/${courseId}/materials/${productId}`);
      await this.fetchProducts();
      showSuccessToast(`Successfully removed product from course.`);
    } catch (err) {
      console.warn(err);
      showErrorToast(`Error removing product from course.`);
    }
  }

  async fetchBundles() {
    this.loading = true;
    try {
      const bundles = await request.get(`/materials/bundles`);
      this.rawBundles = bundles;
      this.loading = false;
      return bundles?.map(augmentBundle);
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async addBundle(courseId, { file, name, productList }) {
    try {
      const newBundle = await request.post(`/materials/bundles?courseId=${courseId}`, { body: { name, productList } });

      if (file) {
        const fileData = await request.get(`/files/product?productId=${newBundle.id}`, {
          headers: { "file-type": file.type }
        });
        const { uploadURL: bundleFileUploadURL } = fileData || {};
        const uploadResponse = await fetch(bundleFileUploadURL, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type, "x-amz-acl": "public-read" }
        });
        if (!uploadResponse.ok) {
          await request.delete(`/materials/bundles/${newBundle.id}?courseId=${courseId}`);
          throw new Error("Upload failed.");
        }
      }

      this.rawBundles = this.rawBundles.concat(newBundle);
      showSuccessToast("Bundle added successfully.");
      return newBundle;
    } catch (err) {
      console.warn("Error creating bundle:", err);
      showErrorToast("Error adding bundle.");
    }
  }

  async updateBundle(bundleId, courseId, { file, name, productList }) {
    const currentBundles = this.rawBundles.slice();

    try {
      if (file) {
        const fileData = await request.get(`/files/product?productId=${bundleId}`, {
          headers: { "file-type": file.type }
        });
        const { uploadURL: bundleFileUploadURL } = fileData || {};
        const uploadResponse = await fetch(bundleFileUploadURL, {
          method: "PUT",
          body: file,
          headers: { "Content-Type": file.type, "x-amz-acl": "public-read" }
        });
        if (!uploadResponse.ok) throw new Error("Updating bundle image failed.");
      }

      const updatedBundle = await request.put(`/materials/bundles/${bundleId}?courseId=${courseId}`, {
        body: { updates: { name, productList } }
      });
      this.rawBundles = this.rawBundles?.map(p => {
        if (p?.id === updatedBundle?.id) return updatedBundle;
        return p;
      });

      showSuccessToast("Bundle updated successfully.");
      return updatedBundle;
    } catch (err) {
      this.rawBundles = currentBundles;
      console.warn("Error updating bundle:", err);
      showErrorToast("Error updating bundle.");
    }
  }

  async deleteBundle(courseId, bundleId) {
    try {
      await request.delete(`/materials/bundles/${bundleId}?courseId=${courseId}`);
      this.rawBundles = this.rawBundles.filter(b => b?.id !== bundleId);
      showSuccessToast("Bundle deleted successfully.");
      return true;
    } catch (err) {
      console.warn("Error deleting bundle:", err);
      showErrorToast("Error deleting bundle.");
      return false;
    }
  }

  async syncProductsFromInfusionsoft() {
    try {
      await request.post(`/materials/products/infusionsoft/sync`);
      showSuccessToast("Products synced from Infusionsoft successfully.");
      return true;
    } catch (err) {
      console.warn("Error syncing products", err);
      showErrorToast("Error syncing products");
      return false;
    }
  }

  clear() {
    this.rawProducts = [];
  }
}

export default new MaterialsStore();
