import React from "react";
import { Router, Redirect } from "@reach/router";
import Search from "./Search";
import AddAccount from "./AddAccount";
import PersonOverview from "./PersonOverview";
import ClassOverview from "./ClassOverview";

export default function People() {
  return (
    <Router>
      <Search path="/" />
      <AddAccount path="/new" />
      <PersonOverview path=":lpmId" />
      <ClassOverview path=":lpmId/classes/:classId" />
      <Redirect from="*" to="/" noThrow />
    </Router>
  );
}
