import React, { useState } from "react";
import { observer } from "mobx-react";
import { Router, Redirect } from "@reach/router";
import { FiSearch } from "react-icons/fi";
import useModal from "../../hooks/useModal";
import MaterialsStore from "../../stores/MaterialsStore";
import { Input, Select, ProductRow, Button } from "../-common";
import AddProductModalBody from "./AddProductModal";
import "./Products.scss";
import CurriculumStore from "../../stores/CurriculumStore";

const sortOptions = ["Name - A to Z", "Name - Z to A", "Price - Lowest to Highest", "Price - Highest to Lowest"];

const sortProducts = selectedSort => (a, b) => {
  if (selectedSort === "Name - A to Z") return a?.name > b?.name ? 1 : -1;
  else if (selectedSort === "Name - Z to A") return a?.name > b?.name ? -1 : 1;
  else if (selectedSort === "Price - Lowest to Highest") return a?.price > b?.price ? 1 : -1;
  else if (selectedSort === "Price - Highest to Lowest") return a?.price > b?.price ? -1 : 1;
};

const filterProducts = selectedFilter => product => {
  if (selectedFilter === "All") return true;
  else return product?.curricula?.map(c => c?.title)?.includes(selectedFilter);
};

const Products = observer(() => {
  const { products } = MaterialsStore || {};

  const filterOptions = ["All"]?.concat(CurriculumStore?.curricula?.map(c => c?.title));

  const [syncInProgress, setSyncInProgress] = useState(false);
  const syncProductsFromInfusionsoft = async () => {
    setSyncInProgress(true);
    await MaterialsStore.syncProductsFromInfusionsoft();
    setSyncInProgress(false);
  };

  const { Modal: AddProductModal, openModal: openAddProductModal } = useModal(
    AddProductModalBody,
    {},
    { portalElementId: "add-product-modal" }
  );

  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target.value);

  const totalProducts = products?.length || 0;

  const applicableProducts = products
    ?.filter(filterProducts(selectedFilter))
    ?.filter(p => {
      if (!searchText) return true;
      const textToMatch = searchText?.toLowerCase();
      return p?.name?.toLowerCase()?.includes(textToMatch); // TODO: || p?.longDescription?.includes(textToMatch);
    })
    ?.sort(sortProducts(selectedSort));
  const productRows = applicableProducts?.map(product => <ProductRow {...product} key={product?.id} />);
  const productsTable = applicableProducts?.length ? (
    <div className="table-wrapper products-table">
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{productRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no products)</div>
    </div>
  );
  const productsTableContent = products?.length ? (
    <>
      <div className="sort-search-filter-row">
        <div className="sort-products">
          <span className="sort-products-label">Sort:</span>
          <Select options={sortOptions} value={selectedSort} onChange={setSelectedSort} />
        </div>
        <div className="filter-products">
          <span className="filter-products-label">Filter:</span>
          <Select options={filterOptions} value={selectedFilter} onChange={setSelectedFilter} />
        </div>
        <div className="search-products">
          <Input icon={FiSearch} placeholder="Search products..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      {productsTable}
    </>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No products yet!</div>
      <Button action={openAddProductModal} type="primary">
        Add Product
      </Button>
    </div>
  );

  const syncAction = syncInProgress ? null : (
    <div className="action" onClick={syncProductsFromInfusionsoft}>
      <span>Sync Products From Infusionsoft</span>
    </div>
  );

  return (
    <div className="products">
      <div className="title-row">
        <div className="title">Products</div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Total Products</div>
            <div className="info-value">{totalProducts}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action" onClick={openAddProductModal}>
              <span>Add Product</span>
            </div>
            {syncAction}
          </div>
        </div>
      </div>
      <div className="header">Products</div>
      {productsTableContent}
      <AddProductModal />
    </div>
  );
});

export default function ProductsNav() {
  return (
    <Router>
      <Products path="/" />
      <Redirect from="*" to="/" noThrow />
    </Router>
  );
}
