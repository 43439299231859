import React from "react";
import { FiMoreVertical } from "react-icons/fi";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import useModal from "../../../hooks/useModal";
import EditPromoCodeModalBody from "./EditPromoCodesModal";
import ConfirmDeletePromoCodeModalBody from "./ConfirmDeletePromoCodeModal";
import "./PromoCodeRow.scss";

const PromoCodeRow = ({ id, code, description, type, status, value, sorting }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    disabled: !sorting
  });
  const sortStyles = { transform: CSS.Transform.toString(transform), transition };

  const { Modal: EditPromoCodeModal, openModal: openEditPromoCodeModal } = useModal(
    EditPromoCodeModalBody,
    {
      promoCode: { id, code, description, type, status, value }
    },
    { portalElementId: "edit-promo-code-modal" }
  );

  const { Modal: ConfirmDeletePromoCodeModal, openModal: openConfirmDeletePromoCodeModal } = useModal(
    ConfirmDeletePromoCodeModalBody,
    { promoCode: { id, code } },
    { portalElementId: "confirm-delete-promo-code-modal" }
  );

  const actions = sorting ? (
    <div {...attributes} {...listeners} className="drag-handle">
      <FiMoreVertical size={36} color="var(--orange)" />
    </div>
  ) : (
    <>
      <div className="action" onClick={openEditPromoCodeModal}>
        Edit Details
      </div>
      <div className="action" onClick={openConfirmDeletePromoCodeModal}>
        Remove
      </div>
    </>
  );

  return (
    <tr ref={setNodeRef} style={sortStyles} className="promo-code-row">
      <td>
        <div className="cell-contents">{code}</div>
      </td>
      <td>
        <div className="cell-contents">{description}</div>
      </td>
      <td>
        <div className="cell-contents">{status}</div>
      </td>
      <td>
        <div className="cell-contents">{type}</div>
      </td>
      <td>
        <div className="cell-contents">{value}</div>
      </td>
      <td>
        <div className="cell-contents actions">{actions}</div>
      </td>
      <EditPromoCodeModal />
      <ConfirmDeletePromoCodeModal />
    </tr>
  );
};

export default PromoCodeRow;
