const secondMS = 1000;
const minuteMS = secondMS * 60;
const hourMS = minuteMS * 60;

function formatMilliseconds(ms) {
  if (typeof ms !== "number") return ms;
  if (ms === 0) return 0;

  const hours = Math.floor(ms / hourMS);
  const minutes = Math.floor((ms - hours * hourMS) / minuteMS);
  const seconds = String(Math.floor((ms - hours * hourMS - minutes * minuteMS) / secondMS)).padStart(2, "0");
  const millis = String((ms - hours * hourMS - minutes * minuteMS - seconds * secondMS) / 100)?.[0] || "0";

  return (hours ? `${hours}:` : "") + `${minutes}:${seconds}.${millis}`;
}

export default formatMilliseconds;
