import React, { useState } from "react";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import { FiType } from "react-icons/fi";
import PlaylistsStore from "../../../../stores/PlaylistsStore";
import { Spinner, Input, Button, MultiSelect } from "../../../-common";
import "./EditPlaylistModal.scss";

const getTrackLabel = ({ value }) => value?.order + " - " + value?.title;

function EditPlaylistModal({ album, playlist, close }) {
  const { id: albumId, tracks: availableTracks } = album || {};
  const { id: playlistId, name: currentName, tracks: currentFullTracks, order } = playlist || {};
  const currentTracks = currentFullTracks?.map(t => t?.id);

  const [adding, setAdding] = useState(false);

  const [name, setName] = useState(currentName);
  const updateName = ({ target }) => setName(target?.value);

  const [tracks, setTracks] = useState(currentTracks);
  const updateTracks = tracks => setTracks(tracks?.map(t => t?.id));
  const selectedTracks = tracks?.map(tid => availableTracks?.find(t => t?.id === tid))?.filter(Boolean);

  const fieldsValid = !!name && tracks?.length > 0;
  const somethingChanged = name !== currentName || !isEqual(tracks, currentTracks);
  const addEnabled = fieldsValid && somethingChanged;
  const addPlaylist = async () => {
    if (addEnabled) {
      setAdding(true);
      await PlaylistsStore.updatePlaylist(albumId, playlistId, { name, tracks, order });
      setAdding(false);
      close();
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Update Playlist";

  return (
    <div className="edit-playlist-modal">
      <div className="content">
        <div className="title">Update Playlist</div>
        <div className="main-content-wrapper">
          <div className="section-container">
            <div className="section-title">Name</div>
            <Input icon={FiType} placeholder="Playlist Name" value={name} onChange={updateName} />
          </div>
          <div className="section-container">
            <div className="section-title">Tracks</div>
            <MultiSelect
              placeholder="Select Tracks"
              options={availableTracks}
              value={selectedTracks}
              onChange={updateTracks}
              formatOptionLabel={getTrackLabel}
            />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addPlaylist} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EditPlaylistModal);
