import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import CurriculumStore from "../../../../stores/CurriculumStore";
import { Spinner, Button, PieTimer } from "../../../-common";
import "./ConfirmDeleteCurriculumModal.scss";

function ConfirmDeleteCurriculumModal({ curriculum, close }) {
  const { id, title } = curriculum || {};

  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const enableDelete = () => setDeleteEnabled(true);

  const [deleting, setDeleting] = useState(false);

  const deleteCurriculum = async () => {
    setDeleting(true);
    await CurriculumStore.deleteCurriculum(id);
    close();
    navigate("/curriculum");
  };

  const cancelButtonStyles = deleting ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = deleting ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = deleting ? <Spinner /> : "Delete Curriculum";
  const actionButton = deleteEnabled ? (
    <Button type="negative" action={deleteCurriculum} style={actionButtonStyles}>
      {actionButtonContent}
    </Button>
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--red)" width={24} height={24} duration={3000} onComplete={enableDelete} />
    </div>
  );

  return (
    <div className="confirm-delete-curriculum-modal">
      <div className="content">
        <div className="title">Are you sure you want to delete {title}?</div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">{actionButton}</div>
      </div>
    </div>
  );
}

export default observer(ConfirmDeleteCurriculumModal);
