import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { getDataURLFromFile } from "../../../../utils";
import usePortal from "../../../../hooks/usePortal";
import CurriculumStore from "../../../../stores/CurriculumStore";
import FullScreenModal from "../../../-common/FullScreenModal";
import Spinner from "../../../-common/Spinner";
import { AudioFileSelect, Button } from "../../../-common";
import "./UpdateRecordingModal.scss";

function UpdateRecordingModal({ album, track, close }) {
  const { title } = track || {};

  const [uploading, setUploading] = useState(false);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const uploadEnabled = !!file;
  const upload = async () => {
    if (uploadEnabled) {
      setUploading(true);
      const success = await CurriculumStore?.updateTrackRecording(album?.id, track?.id, file);
      setUploading(false);
      if (success) close();
    }
  };

  const cancelButtonStyles = uploading ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = uploadEnabled
    ? uploading
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = uploading ? <Spinner /> : "Update Recording";

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="update-recording-modal">
        <div className="content">
          <div className="title">Update Recording for {title}</div>
          <div className="section-container columns">
            <div className="col">
              <AudioFileSelect {...{ filePreviewURL, updateFile }} />
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button action={close} style={cancelButtonStyles}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" action={upload} style={actionButtonStyles}>
              {actionButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const ObservingModal = observer(UpdateRecordingModal);

const UpdateRecordingModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("update-recording-modal-container");
  const contents = isOpen ? <ObservingModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default UpdateRecordingModalContainer;
