import React from "react";
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

const DatePicker = ({ value, onChange, placeholder, ...rest }) => {
  return (
    <div className="lpm-datepicker">
      <Picker selected={value} onChange={onChange} placeholderText={placeholder} {...rest} />
    </div>
  );
};

export default DatePicker;
