import React from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { ENV } from "../../../../config";
import "./EnrollmentTable.scss";

function TotalCell({ top, bottom, format }) {
  const content =
    format === "percent" ? (
      <>
        <div className="course-breakdown-line" key={top}>
          Current: {numeral(top || 0).format("0.0%")}
        </div>
        <div className="course-breakdown-line" key={bottom}>
          Upcoming: {numeral(bottom || 0).format("0.0%")}
        </div>
      </>
    ) : (
      <>
        <div className="course-breakdown-line" key={top}>
          Current: {numeral(top || 0).format("0,0")}
        </div>
        <div className="course-breakdown-line" key={bottom}>
          Upcoming: {numeral(bottom || 0).format("0,0")}
        </div>
      </>
    );

  return content;
}

function CourseLine({ courseId, courseName, current, upcoming }) {
  const courseIcon = `https://${ENV}-lpm-assets.b-cdn.net/icons/${courseId}`;

  const {
    totalClasses: cTotalClasses,
    totalRegistrations: cTotalRegistrations,
    totalClassCapacity: cTotalClassCapacity
  } = current || {};
  const {
    totalClasses: uTotalClasses,
    totalRegistrations: uTotalRegistrations,
    totalClassCapacity: uTotalClassCapacity
  } = upcoming || {};

  const currentCapacityPercentage = cTotalRegistrations / cTotalClassCapacity;
  const upcomingCapacityPercentage = uTotalRegistrations / uTotalClassCapacity;

  return (
    <tr className="course-row">
      <td>
        <div className="cell-contents">
          <div className="course-icon-container">
            <img className="course-icon" src={courseIcon} alt={courseName} />
          </div>
        </div>
      </td>
      <td>
        <div className="cell-contents flex-center justify-center course-breakdown">
          <TotalCell top={cTotalClasses} bottom={uTotalClasses} />
        </div>
      </td>
      <td>
        <div className="cell-contents flex-center justify-center course-breakdown">
          <TotalCell top={cTotalRegistrations} bottom={uTotalRegistrations} />
        </div>
      </td>
      <td>
        <div className="cell-contents flex-center justify-center course-breakdown">
          <TotalCell top={cTotalClassCapacity} bottom={uTotalClassCapacity} />
        </div>
      </td>
      <td>
        <div className="cell-contents flex-center justify-center course-breakdown">
          <TotalCell top={currentCapacityPercentage} bottom={upcomingCapacityPercentage} format={"percent"} />
        </div>
      </td>
    </tr>
  );
}

function EnrollmentTable({ data, color }) {
  const courseRows = data
    ?.slice()
    ?.sort((a, b) => (a.courseOrder > b.courseOrder ? 1 : -1))
    .map(course => <CourseLine {...course} key={course?.id} />);
  const content = data?.length ? (
    <div className="table-wrapper enrollment-table">
      <table>
        <thead>
          <tr>
            <th style={{ color }} className="left">
              Course
            </th>
            <th style={{ color }}>Total Classes</th>
            <th style={{ color }}>Total Student Registrations</th>
            <th style={{ color }}>Total Class Capacity</th>
            <th style={{ color }}>Capacity Filled %</th>
          </tr>
        </thead>
        <tbody>{courseRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no enrollments found)</div>
    </div>
  );

  return content;
}

export default observer(EnrollmentTable);
