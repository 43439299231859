import React from "react";
import { navigate } from "@reach/router";
import { ALBUM_ARTWORK_SIZE, ALBUM_ARTWORK_SPACING } from "../../../../constants";
import "./CourseRow.scss";

const CourseRow = ({ curriculumId, id: courseId, name, albums }) => {
  const goToCourseDetails = () => navigate(`/curriculum/${curriculumId}/courses/${courseId}`);

  const size = ALBUM_ARTWORK_SIZE - (albums?.length - 1) * ALBUM_ARTWORK_SPACING;
  const artworkURLs = albums?.map(({ title, artwork } = {}) => ({ title, artwork }));
  const artworkStack = artworkURLs?.map(({ title, artwork } = {}, i) => (
    <img
      className="album-artwork"
      src={artwork}
      style={{ height: size, width: size, top: ALBUM_ARTWORK_SPACING * i, left: ALBUM_ARTWORK_SPACING * i }}
      alt={`Artwork for ${title} album`}
      key={title}
    />
  ));

  const artworkContents = artworkStack?.length ? artworkStack : <div className="empty-artwork">No Album Artwork</div>;

  const totalAlbums = albums?.length || 0;
  const totalTracks = albums?.map(a => a?.stats?.totalTracks)?.reduce((acc, next) => acc + next, 0) || 0;

  return (
    <tr>
      <td>
        <div className="cell-contents artwork">{artworkContents}</div>
      </td>
      <td>
        <div className="cell-contents">{name}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div>
            {totalAlbums} album{totalAlbums !== 1 ? "s" : ""}
          </div>
          <div>
            {totalTracks} track{totalTracks !== 1 ? "s" : ""}
          </div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={goToCourseDetails}>
            View Details
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CourseRow;
