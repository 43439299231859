import { useState } from "react";
import { observer } from "mobx-react";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { STATES } from "../../../../constants";
import { Input, Spinner, Button, Select, SecureInput } from "../../../../components/-common";
import AuthStore from "../../../../stores/AuthStore";
import "./CreateAccount.scss";

const CreateAccount = ({ setAccountCreationSuccessful, setNewLPMId }) => {
  const [firstName, setFirstName] = useState("");
  const updateFirstName = ({ target }) => setFirstName(target.value);

  const [lastName, setLastName] = useState("");
  const updateLastName = ({ target }) => setLastName(target.value);

  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const [phone, setPhone] = useState("");
  const updatePhone = ({ target }) => {
    if (!isNaN(Number(phone.slice(-1)))) {
      const phoneFormatter = new AsYouType("US");
      const phone = phoneFormatter.input(target.value);
      setPhone(phone);
    } else {
      setPhone(target.value);
    }
  };

  const [address1, setAddress1] = useState("");
  const updateAddress1 = ({ target }) => setAddress1(target.value);

  const [address2, setAddress2] = useState("");
  const updateAddress2 = ({ target }) => setAddress2(target.value);

  const [city, setCity] = useState("");
  const updateCity = ({ target }) => setCity(target.value);

  const [state, setState] = useState();

  const [zip, setZip] = useState("");
  const updateZip = ({ target }) => setZip(target.value);

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [confirmPassword, setConfirmPassword] = useState("");
  const updateConfirmPassword = ({ target }) => setConfirmPassword(target.value);

  const [errorMessage, setErrorMessage] = useState();

  let phoneIsValid = false;
  try {
    phoneIsValid = parsePhoneNumber(phone, "US").isValid();
  } catch {}
  const signUpEnabled =
    !!firstName &&
    !!lastName &&
    !!email &&
    phoneIsValid &&
    !!address1 &&
    !!city &&
    !!state &&
    !!zip &&
    !!password &&
    password === confirmPassword;

  const attemptSignUp = async () => {
    if (!signUpEnabled) return;
    setErrorMessage("");

    const formattedPhone = parsePhoneNumber(phone, "US").format("E.164");
    const { success, error, lpmId } = await AuthStore.signUp({
      firstName,
      lastName,
      email,
      phone: formattedPhone,
      address1,
      address2,
      city,
      state,
      zip,
      password
    });
    if (success) {
      if (lpmId) setNewLPMId(lpmId);
      setAccountCreationSuccessful(true);
    } else {
      setErrorMessage(error);
    }
  };

  const buttonContents = AuthStore.loading ? <Spinner /> : "Sign Up";
  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};

  const errorComponent = errorMessage ? (
    <div className="error-container">
      <div className="error-text">{errorMessage}</div>
    </div>
  ) : null;

  return (
    <div className="sign-up-content">
      <div className="sign-up-square">
        <div className="sign-up-inputs">
          <div className="inputs-row-title">Name</div>
          <div className="inputs-row">
            <Input type="text" placeholder="Jane" value={firstName} onChange={updateFirstName} />
            <Input type="text" placeholder="Doe" value={lastName} onChange={updateLastName} />
          </div>
          <div className="inputs-row">
            <div className="inputs-row-section">
              <div className="inputs-row-title">Email Address</div>
              <Input type="text" placeholder="janedoe1@gmail.com" value={email} onChange={updateEmail} />
            </div>
            <div className="inputs-row-section">
              <div className="inputs-row-title">Phone Number</div>
              <Input type="text" placeholder="(555) 123-4567" value={phone} onChange={updatePhone} />
            </div>
          </div>
          <div className="inputs-row-title">Address</div>
          <div className="inputs-row group">
            <Input type="text" placeholder="Address Line 1" value={address1} onChange={updateAddress1} />
          </div>
          <div className="inputs-row group">
            <Input type="text" placeholder="Address Line 2" value={address2} onChange={updateAddress2} />
          </div>
          <div className="inputs-row">
            <Input type="text" placeholder="City" value={city} onChange={updateCity} />
            <Select placeholder="State" options={STATES} value={state} onChange={setState} />
            <Input type="text" placeholder="Zip" value={zip} onChange={updateZip} />
          </div>
          <div className="inputs-row-title">Create a Password</div>
          <div className="inputs-row-subtitle">Your password should have at least six characters.</div>
          <div className="inputs-group">
            <SecureInput type="password" placeholder="$ecr3tP4ssw0rd" value={password} onChange={updatePassword} />
            <SecureInput
              type="password"
              placeholder="Enter one more time to confirm"
              value={confirmPassword}
              onChange={updateConfirmPassword}
            />
            {errorComponent}
          </div>
        </div>
        <Button
          type="cta"
          className="log-in-button"
          style={buttonStyles}
          action={attemptSignUp}
          disabled={!signUpEnabled}
        >
          {buttonContents}
        </Button>
      </div>
    </div>
  );
};

export default observer(CreateAccount);
