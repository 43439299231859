import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Link, navigate } from "@reach/router";
import { FiChevronRight } from "react-icons/fi";
import { formatMilliseconds } from "../../../utils";
import useModal from "../../../hooks/useModal";
import useCurriculum from "../../../hooks/useCurriculum";
import useCourse from "../../../hooks/useCourse";
import useAlbum from "../../../hooks/useAlbum";
import useTabs from "../../../hooks/useTabs";
import PlaylistsStore from "../../../stores/PlaylistsStore";
import { Spinner, ConfirmDeletePlaylistModal as ConfirmDeletePlaylistModalBody } from "../../-common";
import InfoTab from "./InfoTab";
import TracksTab from "./TracksTab";
import ActivityTab from "./ActivityTab";
import EditPlaylistModalBody from "./EditPlaylistModal";
import EditPlaylistTracksModalBody from "./EditPlaylistTracksModal";
import "./PlaylistDetails.scss";

const sections = [
  { title: "Info", component: InfoTab },
  { title: "Tracks", component: TracksTab },
  { title: "Activity", component: ActivityTab }
];

const ZeroState = ({ curriculum, course }) => {
  const { title: curriculumTitle, id: curriculumId } = curriculum || {};
  const { name: courseName, id: courseId } = course || {};
  const curriculumLink =
    curriculumTitle && curriculumId ? (
      <>
        <FiChevronRight className="arrow-icon" />
        <Link to={`/curriculum/${curriculumId}`}>{curriculumTitle}</Link>
      </>
    ) : null;
  const courseLink =
    curriculumLink && courseName && courseId ? (
      <>
        <FiChevronRight className="arrow-icon" />
        <Link to={`/curriculum/${curriculumId}/courses/${courseId}`}>{courseName}</Link>
      </>
    ) : null;
  return (
    <div className="album-details">
      <div className="title-row">
        <div className="title">
          <Link to="/curriculum">Curriculum</Link>
          {curriculumLink}
          {courseLink}
        </div>
      </div>
      <div className="loading-container">
        <Spinner alt />
      </div>
    </div>
  );
};

const Tab = ({ index, title, select, activeTabIndex }) => (
  <div className={`tab${index === activeTabIndex ? " active" : ""}`} onClick={select}>
    {title}
  </div>
);

function PlaylistDetails({ curriculumId, courseId, albumId, playlistId }) {
  const { loading: loadingCurriculum, curriculum } = useCurriculum(curriculumId);
  const { loading: loadingCourse, course } = useCourse(courseId, { curriculumId });
  const { loading: loadingAlbum, album } = useAlbum(albumId, { curriculumId, courseId });

  const playlist = PlaylistsStore?.playlists?.find(p => p?.id === playlistId) || {};
  const { name, order } = playlist || {};

  const currentTracks = playlist?.tracks || []; // eslint-disable-line

  const [tracks, setTracks] = useState(currentTracks || []);
  useEffect(() => {
    setTracks(currentTracks || []);
  }, [currentTracks]);

  const [sortingTracks, setSortingTracks] = useState(false);
  const sortTracks = () => setSortingTracks(true);
  const saveTrackSort = async () => {
    const trackUpdates = tracks?.map(t => t?.id);
    await PlaylistsStore.updatePlaylist(albumId, playlistId, { name, tracks: trackUpdates, order });
    setSortingTracks(false);
  };
  const cancelTrackSort = () => {
    setTracks(currentTracks);
    setSortingTracks(false);
  };

  const { Modal: EditPlaylistModal, openModal: openEditPlaylistModal } = useModal(
    EditPlaylistModalBody,
    { playlist, album },
    { portalElementId: "edit-playlist-modal" }
  );

  const { Modal: EditPlaylistTracksModal, openModal: openEditPlaylistTracksModal } = useModal(
    EditPlaylistTracksModalBody,
    { playlist, album },
    { portalElementId: "edit-playlist-tracks-modal" }
  );

  const { Modal: ConfirmDeletePlaylistModal, openModal: openConfirmDeletePlaylistModal } = useModal(
    ConfirmDeletePlaylistModalBody,
    { albumId, playlist },
    { portalElementId: "confirm-delete-playlist-modal" }
  );

  const { activeTabIndex, tabKeys } = useTabs(sections);
  const selectTab = index => () => {
    navigate(
      `/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}/playlists/${playlistId}?tab=${tabKeys[index]}`
    );
  };

  const loading = loadingCurriculum || loadingCourse || loadingAlbum;
  if (loading) return <ZeroState {...{ curriculum, course, album }} />;

  const { title: curriculumTitle } = curriculum || {};
  const { name: courseName } = course || {};
  const { title: albumTitle } = album || {};
  const { name: playlistName } = playlist || {};

  const totalTracks = tracks?.length;
  const totalRunTime = tracks?.reduce((acc, next) => acc + next?.duration, 0);

  const tabBarStyles = { gridTemplateColumns: `repeat(${sections.length}, 1fr)` };
  const tabs = sections?.map(({ title }, index) => (
    <Tab {...{ index, title, select: selectTab(index), activeTabIndex }} key={title} />
  ));

  const activeSection = sections?.[activeTabIndex];
  const ActiveSection = activeSection?.component || (() => null);

  let tabActions;
  if (activeSection?.title === "Info") {
    tabActions = (
      <>
        <div className="action-row">
          <div className="action" onClick={openEditPlaylistModal}>
            <span>Edit Details</span>
          </div>
        </div>
        <div className="action-row">
          <div className="action" onClick={openConfirmDeletePlaylistModal}>
            <span>Delete {playlistName}</span>
          </div>
        </div>
      </>
    );
  } else if (activeSection?.title === "Tracks") {
    tabActions = sortingTracks ? (
      <>
        <div className="action-row">
          <div className="action" onClick={saveTrackSort}>
            <span>Save Order</span>
          </div>
          <div className="action" onClick={cancelTrackSort}>
            <span>Cancel</span>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="action-row">
          <div className="action" onClick={openEditPlaylistTracksModal}>
            <span>Add / Remove Tracks</span>
          </div>
        </div>
        <div className="action-row">
          <div className="action" onClick={sortTracks}>
            <span>Reorder Tracks</span>
          </div>
        </div>
      </>
    );
  }

  const tracksTabProps = { tracks, setTracks, sorting: sortingTracks };
  const activeSectionProps = {
    curriculum,
    course,
    album,
    playlist,
    ...(activeSection?.title === "Tracks" ? tracksTabProps : {})
  };

  return (
    <div className="album-details">
      <div className="title-row">
        <div className="title">
          <Link to="/curriculum">Curriculum</Link> <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}`}>{curriculumTitle}</Link> <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}/courses/${courseId}`}>{courseName}</Link>{" "}
          <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}?tab=playlists`}>
            {albumTitle}
          </Link>{" "}
          <FiChevronRight className="arrow-icon" />
          <span>{playlistName}</span>
        </div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Run Time</div>
            <div className="info-value">{formatMilliseconds(totalRunTime)}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Tracks</div>
            <div className="info-value">{totalTracks}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          {tabActions}
        </div>
      </div>
      <div className="tabs-container">
        <div className="tab-bar" style={tabBarStyles}>
          {tabs}
        </div>
        <div className="active-tab-section">
          <ActiveSection {...activeSectionProps} />
        </div>
      </div>
      <EditPlaylistModal />
      <EditPlaylistTracksModal />
      <ConfirmDeletePlaylistModal />
    </div>
  );
}

export default observer(PlaylistDetails);
