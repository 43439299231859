import { makeAutoObservable } from "mobx";
import { request } from "../utils";
import { showErrorToast, showSuccessToast } from "../services/ToastService";
import ClassesStore from "./ClassesStore";

class OrdersStore {
  constructor() {
    makeAutoObservable(this);
  }

  async updateMaterials({ orderItemAndOrderIdsToRemove, productAndOrderIdsToAdd, params }) {
    try {
      await request.put(`/orders/items`, {
        body: { orderItemAndOrderIdsToRemove, productAndOrderIdsToAdd, params }
      });
      showSuccessToast("Successfully updated materials.");
      await ClassesStore.fetchStudentsForClassById(params?.classId);
    } catch (err) {
      console.warn("Error updating materials:", err);
      showErrorToast("Error updating materials.");
    }
  }
}

export default new OrdersStore();
