import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import CurriculumStore from "../stores/CurriculumStore";

export default function useCurriculum(curriculumId) {
  const [loading, setLoading] = useState(true);
  const [curriculum, setCurriculum] = useState();
  useEffect(() => {
    const matchingCurriculum = CurriculumStore?.curriculaById[curriculumId];
    if (matchingCurriculum) {
      setCurriculum(toJS(matchingCurriculum));
      setLoading(false);
    } else {
      if (curriculumId) {
        setCurriculum();
        (async () => {
          setLoading(true);
          await CurriculumStore.init();
          const curriculum = CurriculumStore?.curriculaById[curriculumId];
          if (!curriculum) navigate("/curriculum");
          setLoading(false);
        })();
      }
    }
  }, [curriculumId]);
  return { curriculum: curriculum || {}, loading };
}
